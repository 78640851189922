export const mobile_tech = [
 { title: "Android Studio", category: "Framework", icon: "assets/icons/img/mobile-dev/android.png", status: "" },
 { title: "IOS", category: "Framework", icon: "assets/icons/img/mobile-dev/ios.png", status: "" },
 { title: "Swift", category: "Programming Language", icon: "assets/icons/img/mobile-dev/swift.png", status: "" },
 { title: "Objective-C", category: "Programming Language", icon: "assets/icons/img/mobile-dev/objective-c.png", status: "" },
 { title: "C#", category: "Programming Language", icon: "assets/icons/img/mobile-dev/c-sharp.png", status: "" },
 { title: "Java", category: "Programming Language", icon: "assets/icons/img/mobile-dev/java.png", status: "" },
 { title: "Kotlin", category: "Programming Language", icon: "assets/icons/img/mobile-dev/kotlin.png", status: "" },
 { title: "Python", category: "Programming Language", icon: "assets/icons/img/mobile-dev/python.png", status: "" },
 { title: "Ruby", category: "Programming Language", icon: "assets/icons/img/mobile-dev/ruby.png", status: "" },
 { title: "Dart", category: "Programming Language", icon: "assets/icons/img/mobile-dev/dart.png", status: "" },
 { title: "React Native", category: "Framework", icon: "assets/icons/img/mobile-dev/react-native.png", status: "" },
 { title: "SL4A", category: "Framework", icon: "assets/icons/img/mobile-dev/sl4a.png", status: "" },
 { title: "Flutter", category: "Framework", icon: "assets/icons/img/mobile-dev/flutter.png", status: "" },
 { title: "Meteor", category: "Framework", icon: "assets/icons/img/mobile-dev/meteor.png", status: "" },
 { title: "Aurelia", category: "Framework", icon: "assets/icons/img/mobile-dev/aurelia.png", status: "" },
 { title: "Cardova", category: "Framework", icon: "assets/icons/img/mobile-dev/cardova.png", status: "" },
 { title: "Ionic", category: "Framework", icon: "assets/icons/img/mobile-dev/ionic.png", status: "" },
 { title: "NativeScript", category: "Framework", icon: "assets/icons/img/mobile-dev/nativescript.png", status: "" },
 { title: "Mobile Angular UI", category: "Framework", icon: "assets/icons/img/mobile-dev/mobile-angular-ui.png", status: "" },
 { title: "jQuery Mobile", category: "Framework", icon: "assets/icons/img/mobile-dev/jquery-mobile.png", status: "" },
 { title: "Codename One", category: "Framework", icon: "assets/icons/img/mobile-dev/codename-one.png", status: "" },
 { title: "RubyMotion", category: "Framework", icon: "assets/icons/img/mobile-dev/rubymotion.png", status: "" },
 { title: "Unity", category: "Framework", icon: "assets/icons/img/mobile-dev/unity.png", status: "" },
 { title: "Xamarin", category: "Platform / Tool", icon: "assets/icons/img/mobile-dev/xamarin.png", status: "" },
 { title: "XML", category: "Programming Language", icon: "assets/icons/img/mobile-dev/xml.png", status: "" },
];
