import { createSlice } from '@reduxjs/toolkit';

const informationSlice = createSlice({
  name: 'information',
  initialState: {
    // Your initial state here
    roleBasedJob: {},
  },
  reducers: {
    setRoleBasedJob: (state, action) => {
      state.roleBasedJob = action.payload;
    },
  },
}); 

export const { setRoleBasedJob,setRoleSkills } = informationSlice.actions;
export const getRoleBasedJob = (state) => state.information.roleBasedJob;

export default informationSlice.reducer;
