import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import validator from 'validator'

const EditProfileDetails = ({ isRight, toggleRightCanvas, data, onSubmit }) => {

    const [editedData, setEditedData] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        setEditedData({
            candidate: { ...data.candidate },
            resume: { ...data.resume }
        });
    }, [data.candidate])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation checks
        let errors = { ...validationErrors };
        switch (name) {
            case 'full_name':
                errors[name] = validator.isEmpty(value) ? 'Full Name is required' : '';
                break;
            case 'email_id':
                errors[name] = validator.isEmpty(value) ? 'Email Id is required' : '';
                break;
            case 'contact_no':
                errors[name] = validator.isEmpty(value) ? 'Phone is required' : '';
                break;
            case 'date_of_birth':
                errors[name] = validator.isEmpty(value) ? 'Date of Birth is required' : '';
                break;
            case 'linkedin_url':
                errors[name] = validator.isEmpty(value) ? 'LinkedIn is required' : '';
                break;
            default:
                break;
        }

        setValidationErrors(errors);
        // Check if the name includes a dot, indicating it's a nested property
        if (name.includes('.')) {
            const [objectName, propertyName] = name.split('.');
            setEditedData((prevData) => ({
                ...prevData,
                [objectName]: {
                    ...prevData[objectName],
                    [propertyName]: value,
                },
            }));
        } else {
            // If no dot is present, update the candidate object
            setEditedData((prevData) => ({
                ...prevData,
                candidate: {
                    ...prevData.candidate,
                    [name]: value,
                },
            }));
        }
    };

    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        const today = new Date();
        // If you want to format the date as YYYY-MM-DD
        const todayFormatted = today.toISOString().split('T')[0];
        const birthDate = new Date(editedData?.candidate?.date_of_birth);
        const age = today.getFullYear() - birthDate.getFullYear();
        // Validation checks
        let errors = {};
        let summary = editedData?.resume?.summary === null || editedData?.resume?.summary === undefined ? '' : editedData?.resume?.summary;
        let linkedin = editedData?.candidate?.linkedin_url === null || editedData?.candidate?.linkedin_url === undefined ? '' : editedData?.candidate?.linkedin_url;
        let dob = editedData?.candidate?.date_of_birth === null || editedData?.candidate?.date_of_birth === undefined ? '' : editedData?.candidate?.date_of_birth;
    

        if (validator.isEmpty(editedData?.candidate?.full_name)) {
            errors['full_name'] = 'Full Name is required';
        }else if (/\d/.test(editedData?.candidate?.full_name)) {
            // Check if the name contains numeric characters
            errors['full_name'] = 'Full Name cannot contain numeric characters';
        } else if (/[^a-zA-Z.\s]/.test(editedData?.candidate?.full_name)) {
            // Check if the name contains special symbols other than '.'
            errors['full_name'] = 'Full Name cannot contain special symbols';
        }
        if (editedData?.candidate?.full_name.length > 30) {
            errors['full_name'] = 'Full Name is too large';
        }
        if (validator.isEmpty(editedData?.candidate?.email_id)) {
            errors['email_id'] = 'Email is required';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(editedData?.candidate?.email_id)) {
            errors['email_id'] = 'Invalid email format';
        }
        if (!validator.isLength(editedData?.candidate?.email_id, { min: 0, max: 50 })) {

            errors['email_id'] = 'Email is Too large';
        } else {
            if (!validator.isEmail(editedData?.candidate?.email_id)) {
                errors['email_id'] = 'Please enter valid email';
            }
        }
        if (validator.isEmpty(editedData?.candidate?.contact_no)) {
            errors['contact_no'] = 'Phone is required';
        }else if (!validator.isMobilePhone(editedData?.candidate?.contact_no)) {
            errors['contact_no'] = 'Please enter valid phone';
        }  else if (editedData?.candidate?.contact_no.includes('+') && editedData?.candidate?.contact_no.length !== 13) {
            errors['contact_no'] = 'Please enter valid phone';
        } else if (!editedData?.candidate?.contact_no.includes('+') && editedData?.candidate?.contact_no.length !== 10) {
            errors['contact_no'] = 'Please enter valid phone';
        }
        if (validator.isEmpty(linkedin)) {
            errors['linkedin_url'] = 'LinkedIn Id is required';
        }
        if (!validator.isLength(linkedin, { min: 0, max: 60 })) {
            errors['linkedin_url'] = 'LinkedIn ID is Too large';
        }
        if (!linkedin.includes('www.linkedin.com')) {
            errors['linkedin_url'] = 'Please enter vaild LinkedIn ID';
        }
        if (!validator.isLength(editedData?.resume?.summary, { min: 0, max: 1000 })) {
            errors['summary'] = 'Summary is Too large';
        }

        
        if (validator.isEmpty(dob)) {
            errors['date_of_birth'] = 'Date of birth is required';
        } else if (!validator.isDate(dateyyyymmdd_tohtml(dob.toString()))) {
            errors['date_of_birth'] = 'Please enter valid date of birth';
        } else if (age < 15) {
            errors['date_of_birth'] = 'Candidate must be at least 15 years old';
        } else if (dob) {
            if (validator.isAfter(dob.toString(), { comparisonDate: todayFormatted })) {
                errors['date_of_birth'] = 'Date of Birth can not be greater than today date';
            }
        }
        if (validator.isEmpty(summary)) {
            errors['summary'] = 'Summary is required';
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {
            // If there are no validation errors, proceed with the submission
            const apiEndpoint = ep.assessment + "/v1/candidate/" + data.candidate.guid;
            const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;
            const requestBody = {
                candidate: {
                    guid: editedData.candidate.guid,
                    full_name: editedData.candidate.full_name,
                    email_id: editedData.candidate.email_id,
                    contact_no: editedData.candidate.contact_no,
                    linkedin_url: editedData.candidate.linkedin_url,
                    marital_status: editedData.candidate.marital_status,
                    date_of_birth: editedData.candidate.date_of_birth,
                },
            };
            const requestBodyResume = {
                resume: {
                    guid: editedData.resume.guid,
                    summary: editedData.resume.summary,
                },
            };

            api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
                if (error) {
                    // Handle error if needed
                } else {
                    api_post(null, apiEndpoint, requestBody, (error, responseData) => {
                        if (error) {
                            // Handle error if needed
                        } else {
                            onSubmit(editedData);
                            toggleRightCanvas();
                        }
                    });
                }
            });
        }
    };

    return (
        <>
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
                style={{zoom:'80%'}}
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Edit Profile Details
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Full name <span className='text-danger'>*</span></Label>
                                    <Col sm={9}>
                                        <Input type="text" name='full_name' className="form-control" id="horizontal-firstname-input" placeholder="Enter Your Full Name" value={editedData?.candidate?.full_name || ''} onChange={handleInputChange} />
                                        {validationErrors.full_name && <small className="text-danger text-capitalize">{validationErrors.full_name}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-email-input" className="col-sm-3 col-form-label">Email <span className='text-danger'>*</span></Label>
                                    <Col sm={9}>
                                        <Input type="email" name='email_id' className="form-control" id="horizontal-email-input" placeholder="Enter Your Email" value={editedData?.candidate?.email_id || ''} onChange={handleInputChange} />
                                        {validationErrors.email_id && <small className="text-danger text-capitalize">{validationErrors.email_id}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-phone-input" className="col-sm-3 col-form-label">Phone <span className='text-danger'>*</span></Label>
                                    <Col sm={9}>
                                        <Input type="tel" name='contact_no' className="form-control" id="horizontal-phone-input" placeholder="Enter Your Phone No." value={editedData?.candidate?.contact_no || ''} onChange={handleInputChange} />
                                        {validationErrors.contact_no && <small className="text-danger text-capitalize">{validationErrors.contact_no}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Date Of Birth <span className='text-danger'>*</span></Label>
                                    <Col sm={9}>
                                        <Input className="form-control" name='date_of_birth' type="date" id="horizontal-dob-input" value={editedData?.candidate?.date_of_birth ? dateyyyymmdd_tohtml(editedData.candidate.date_of_birth) : ''} onChange={handleInputChange} />
                                        {validationErrors.date_of_birth && <small className="text-danger">{validationErrors.date_of_birth}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-linkedin-input" className="col-sm-3 col-form-label">LinkedIn <span className='text-danger'>*</span></Label>
                                    <Col sm={9}>
                                        <Input type="url" className="form-control" name='linkedin_url' id="horizontal-linkedin-input" placeholder="Enter Your LinkedIn" value={editedData?.candidate?.linkedin_url || ''} onChange={handleInputChange} />
                                        {validationErrors.linkedin_url && <small className="text-danger text-capitalize">{validationErrors.linkedin_url}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-marital-input" className="col-sm-3 col-form-label">Marital Status</Label>
                                    <Col sm={9}>
                                        <select className="form-select" name='marital_status' id="horizontal-marital-input"
                                            value={editedData?.candidate?.marital_status || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value={''}>Select Marital Status</option>
                                            <option>Married</option>
                                            <option>Unmarried</option>
                                        </select>
                                        {validationErrors.marital_status && <small className="text-danger text-capitalize">{validationErrors.marital_status}</small>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label htmlFor="horizontal-summary-input" className="col-sm-3 col-form-label">Summary <span className='text-danger'>*</span></Label>
                                    <Col sm={12}>
                                        <textarea id="horizontal-summary-input" name='resume.summary' className="form-control" rows="4" placeholder="Enter Your Summary" value={editedData?.resume?.summary || ''} onChange={handleInputChange}></textarea>
                                        {validationErrors.summary && <small className="text-danger text-capitalize">{validationErrors.summary}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-center">
                                    <Col sm={9}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EditProfileDetails