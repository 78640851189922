import { createSlice } from '@reduxjs/toolkit';
 
const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    // Your initial state here
    timerData: 0,
    questionData:{
       questionIndex:'',
       questionStatus:'',
       questionType:''
    }
  },
  reducers: {
    setTimerData: (state, action) => {
      state.timerData = action.payload;
    },
    setQuestionIndex: (state, action) => {
      state.questionData = action.payload;
     
    },
  },
});
 
export const { setTimerData,setQuestionIndex } = timerSlice.actions;
export const getTimerData = (state) => state.timer.timerData;
export const getQuestionIndex = (state) => state.timer.questionData;
 
export default timerSlice.reducer;