import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import validator from 'validator'
import { validateStartEndDate } from '../../helpers/date'

const AddWorkExperience = ({ addWork, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditWorkExperience', 'edit work data' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState(data);

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    const [newWorkExp, setNewWorkExp] = useState({
        designation: '',
        company_name: '',
        from_date: '',
        to_date: '',
        project_details: '',
    });
    const handleInputChange = (fieldName, value) => {

        // Validation checks
        let errors = { ...validationErrors };
        switch (fieldName) {
            case 'designation':
                errors[fieldName] = validator.isEmpty(value) ? 'Designation is required' : '';
                break;
            case 'company_name':
                errors[fieldName] = validator.isEmpty(value) ? 'Company Name is required' : '';
                break;
            case 'from_date':
                errors[fieldName] = validator.isEmpty(value) ? 'From Date is required' : '';
                break;
            case 'to_date':
                errors[fieldName] = validator.isEmpty(value) ? 'To Date is required' : '';
                break;
            case 'project_details':
                errors[fieldName] = validator.isEmpty(value) ? 'Project Details is required' : '';
                break;
            default:
                break;
        }

        setValidationErrors(errors);

        setNewWorkExp({
            ...newWorkExp,
            [fieldName]: value,
        });
    };



    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation before submission
        let errors = {};
        for (const key in newWorkExp) {
            if (newWorkExp.hasOwnProperty(key)) {
                const value = newWorkExp[key];
                errors[key] = validator.isEmpty(value) ? 'Please fill this field' : '';

                if (!validateStartEndDate(newWorkExp['from_date'], newWorkExp['to_date'])) {
                    errors['from_date'] = 'Start Date can not be Greater than End Date';
                }
                if (!validator.isDate(dateyyyymmdd_tohtml(newWorkExp['from_date']))) {
                    errors['from_date'] = 'Please enter valid start date';
                }
                if (!validator.isDate(dateyyyymmdd_tohtml(newWorkExp['to_date']))) {
                    errors['to_date'] = 'Please enter valid end date';
                }
                if (!validator.isLength(newWorkExp['project_details'], { min: 0, max: 1000 })) {
                    errors['project_details'] = 'Project details are Too large';
                }
            }
        }

        setValidationErrors(errors);

        if (Object.values(errors).some((error) => error !== '')) {
            // If there are validation errors, do not submit
            return;
        }

        const updatedData = { ...editedData };
        if (!updatedData.resume.work_experiences) {
            // If educational_qualifications array doesn't exist, create an empty array
            updatedData.resume.work_experiences = [];
        }

        const updatedWorkExperiences = [...updatedData.resume.work_experiences, newWorkExp];
        updatedData.resume.work_experiences = updatedWorkExperiences;
        setEditedData(updatedData);

        // You can now use formData to send the data to your server or perform any other actions.
        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                work_experiences: editedData.resume.work_experiences,
            },
        };
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'responseData::' + JSON.stringify(responseData));
                onSubmit(editedData);
                toggleRightCanvas();
            }
        });
    };


    return (
        <>
            <Offcanvas
                isOpen={addWork}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Add Work Experience
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                <div>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Designation <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input type="text" name='designation' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" onChange={(e) => handleInputChange('designation', e.target.value)} />
                                            {validationErrors.designation && <small className="text-danger text-capitalize">{validationErrors.designation}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Company Name <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input type="text" name='company_name' className="form-control" id="horizontal-firstname-input" placeholder="Enter Company Name" onChange={(e) => handleInputChange('company_name', e.target.value)} />
                                            {validationErrors.company_name && <small className="text-danger">{validationErrors.company_name}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Start Date <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input className="form-control" name='from_date' type="date" id="horizontal-dob-input" onChange={(e) => handleInputChange('from_date', e.target.value)} />
                                            {validationErrors.from_date && <small className="text-danger">{validationErrors.from_date}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">End Date <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input className="form-control" name='to_date' type="date" id="horizontal-dob-input" onChange={(e) => handleInputChange('to_date', e.target.value)} />
                                            {validationErrors.to_date && <small className="text-danger">{validationErrors.to_date}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Project Details <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <textarea type="text" name='project_details' className="form-control" id="horizontal-firstname-input" placeholder="Enter Project Details" onChange={(e) => handleInputChange('project_details', e.target.value)} />
                                            {validationErrors.project_details && <small className="text-danger">{validationErrors.project_details}</small>}
                                        </Col>
                                    </Row>
                                </div>

                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default AddWorkExperience
