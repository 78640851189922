import { createSlice } from "@reduxjs/toolkit";
 
const userSlice = createSlice({
  name: "user",
  initialState: {
    // Your initial state here
    userData: {},
    editedData:{},
    roleSkills:{}
  },
  reducers: {
    setuserData: (state, action) => {
      state.userData = action.payload;
    },
    setEditedData: (state, action) => {
      state.editedData = action.payload;
    },
    setRoleSkills: (state, action) => {
      state.roleSkills = action.payload;
    },
  },
});

export const { setuserData,setRoleSkills,setEditedData } = userSlice.actions;
export const getuserData = (state) => state.user.userData;
export const geteditedData = (state) => state.user.editedData;
export const getRoleSkills = (state) => state.user.roleSkills;
 
 
export default userSlice.reducer;