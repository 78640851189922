// snackbarSlice.js

import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    snackbarData: {},
    selectedTab: "",
  },
  reducers: {
    setSnackbarData: (state, action) => {
      state.snackbarData = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { setSnackbarData, setSelectedTab } = snackbarSlice.actions;
export const getSnackbarData = (state) => state.snackbar.snackbarData;
export const getSelectedTab = (state) => state.snackbar.selectedTab;

export default snackbarSlice.reducer;
