export const databases = [
 { title: "MongoDB", category: "Database", icon: "assets/icons/img/databases/mongodb.png", status: "" },
 { title: "SQL", category: "Programming Language", icon: "assets/icons/img/databases/sql.png", status: "" },
 { title: "MYSQL", category: "Database", icon: "assets/icons/img/databases/mysql.png", status: "" },
 { title: "Memcached", category: "Database", icon: "assets/icons/img/databases/memcached.png", status: "" },
 { title: "Redis", category: "Database", icon: "assets/icons/img/databases/redis.png", status: "" },
 { title: "Cassandra", category: "Database", icon: "assets/icons/img/databases/cassandra.png", status: "" },
 { title: "Couchbase", category: "Database", icon: "assets/icons/img/databases/couchbase.png", status: "" },
 { title: "DynamoDB", category: "Database", icon: "assets/icons/img/databases/dynamodb.png", status: "" },
 { title: "Neo4j", category: "Database", icon: "assets/icons/img/databases/neo4j.png", status: "" },
 { title: "HBase", category: "Database", icon: "assets/icons/img/databases/hbase.png", status: "" },
 { title: "CouchDB", category: "Database", icon: "assets/icons/img/databases/couchdb.png", status: "" },
 { title: "RavenDB", category: "Database", icon: "assets/icons/img/databases/ravendb.png", status: "" },
 { title: "PostgreSQL", category: "Database", icon: "assets/icons/img/databases/postgresql.png", status: "" },
 { title: "Microsoft SQL Server", category: "Database", icon: "assets/icons/img/databases/microsoft-sql-server.png", status: "" },
 { title: "Oracle Database", category: "Database", icon: "assets/icons/img/databases/oracle-database.png", status: "" },
 { title: "SQLite", category: "Database", icon: "assets/icons/img/databases/sqlite.png", status: "" },
 { title: "IBM Db2", category: "Database", icon: "assets/icons/img/databases/ibm-db2.png", status: "" },
 { title: "Amazon Aurora", category: "Database", icon: "assets/icons/img/databases/amazon-aurora.png", status: "" },
 { title: "Google Cloud SQL", category: "Database", icon: "assets/icons/img/databases/google-cloud-sql.png", status: "" },
 { title: "SAP HANA", category: "Database", icon: "assets/icons/img/databases/sap-hana.png", status: "" },
 { title: "Amazon Redshift", category: "Database", icon: "assets/icons/img/databases/amazon-redshift.png", status: "" },
 { title: "Google BigQuery", category: "Platform / Tool", icon: "assets/icons/img/databases/google-bigquery.png", status: "" },
 { title: "Snowflake", category: "Platform / Tool", icon: "assets/icons/img/databases/snowflake.png", status: "" },
 { title: "MDX", category: "Programming Language", icon: "assets/icons/img/databases/mdx.png", status: "" },
 { title: "DAX", category: "Programming Language", icon: "assets/icons/img/databases/dax.png", status: "" },
 { title: "Apache NiFi", category: "Framework", icon: "assets/icons/img/databases/apache-nifi.png", status: "" },
 { title: "Apache Airflow", category: "Framework", icon: "assets/icons/img/databases/apache-airflow.png", status: "" },
 { title: "Talend", category: "Platform / Tool", icon: "assets/icons/img/databases/talend.png", status: "" },
 { title: "Informatica PowerCenter", category: "Platform / Tool", icon: "assets/icons/img/databases/informatica-powercenter.png", status: "" },
 { title: "SQL Server", category: "Database", icon: "assets/icons/img/databases/sql-server.png", status: "" },
 { title: "erwin Data Modeler", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
];
