import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { primaryShade2, secondaryShade2 } from "../../config/dev";

class Doughnut extends Component {
  getOption = () => {
    // const score = 75; // Your single value score
    // const remaining = 100 - score;
    const { score, outOf } = this.props;

    const percentage = (score / outOf) * 100;
    const remaining = 100 - percentage;

    return {
      toolbox: {
        show: !1,
      },
      tooltip: {
        trigger: "item",
        show: false,
        // formatter: "{a} <br/>{b}: {c}",
      },
      legend: {
        orient: "vertical",
        x: "left",
        // data: ["Laptop", "Tablet", "Mobile", "Others", "Desktop"],
        show: true,
        // textStyle: {
        //   color: ["#858d98"],
        // },
      },
      // color: ["#4D61E3", "#FBF9F1"],
      color: [primaryShade2, "#FBF9F1"],
      series: [
        {
          name: "Score",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: !1,
          label: {
            normal: {
              formatter: `${score} OUT OF ${outOf}`,
              show: true,
              position: "center",
              fontSize: "10",
            },
            emphasis: {
              show: !0,
              textStyle: {
                fontSize: "10",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: !1,
            },
          },
          itemStyle: {
            borderColor: "rgba(219, 219, 219)", // Set the border color
            borderWidth: 1, // Set the border width
            borderStyle: "solid",
          },
          data: [
            { value: percentage },
            { value: remaining },
            // { value: 335, name: "Laptop" },
            // { value: 310, name: "Tablet" },
            // { value: 234, name: "Mobile" },
            // { value: 135, name: "Others" },
            // { value: 1548, name: "Desktop" },
          ],
        },
      ],
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "165px" }} option={this.getOption()} />
      </React.Fragment>
    );
  }
}
export default Doughnut;
