  import React,{useEffect, useState} from 'react'
  import { Row, Col,Card,CardBody,Button, Offcanvas, OffcanvasHeader, OffcanvasBody,Collapse,Alert } from 'reactstrap'
  import { api_get,api_post } from '../../services/service'
  import {dateyyyymmdd_tohtml} from '../../helpers/date'
  import { Link } from 'react-router-dom'
  import { log_debug } from '../../helpers/logger'
  import { ep } from '../../config/dev'
  import Doughnut from '../../common/charts/Doughnut'
  import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
  import moment from 'moment/moment'
  
  const LearnMoreCoding = ({isCoding, toggleLearnMore, data}) => {

    const[codingDetails,setcodingDetails] = useState(null);
    const [collapseStates, setCollapseStates] = useState({});


function fetchData(){
    api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + data, (_coding_err, _codingResponse) => {
      if (!_coding_err) {
        setcodingDetails(_codingResponse.assessmentquestions);
      }
    });
}

const handleReadMore = (e,questionIndex) => {
  e.preventDefault();
  setCollapseStates((prevStates) => ({
    ...prevStates,
    [questionIndex]: !prevStates[questionIndex],
  }));
};

useEffect(() => {
  fetchData();
}, [])
  
    const handleToggleCanvas = (e) => {
      e.preventDefault();
      toggleLearnMore();
  }
  
    return (
      <>
              <Offcanvas
                  isOpen={isCoding}
                  direction="end"
                  toggle={(e)=>{handleToggleCanvas(e)}}
                  className='w-40'
                  style={{zoom:'85%'}}
              >
                  <OffcanvasHeader toggle={toggleLearnMore} className='pb-1'>
                      <h5 className='text-primary font-size-16'>Coding Details <span className='ma-1 text-muted font-size-12'>See Your Technical Skills</span></h5>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                      <Col lg={12} className="ms-lg-auto">
                        { codingDetails?.length>0 ? (
                          <div className="mt-4 mt-lg-0">
                          {codingDetails?.map((code, index) => (
                            // <div key={index}>
                              <div key={index} className="flex-grow-1 mb-2">
                              <div className="border border-2 px-2 py-2">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-15 text-truncate">{code.question}</h5>
                                    <p className="font-size-13 text-muted mb-0">{moment(code.createdAt).format("DD MMM , YYYY")}</p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                  <div className="" style={{ width: "50px", height: "40px" }}>
                                          <CircularProgressbarWithChildren
                                            styles={{
                                              root: {
                                              }, // Adjust these values
                                              path: {
                                                stroke: "#5156be",
                                                strokeLinecap: "round",
                                                transition: "stroke-dashoffset 0.5s ease 0s",
                                                transform: "rotate(0.25turn)",
                                                transformOrigin: "center center",
                                                pathColor: "#74788d",
                                              },
                                              trail: {
                                                stroke: "#d6d6d6",
                                                strokeLinecap: "round",
                                                transform: "rotate(0.25turn)",
                                                transformOrigin: "center center",
                                              },
                                            }}
                                            value={((code.score/code.max_score)*100).toFixed(0)}
                                          >
                                            <div style={{ fontSize: 11 }}>
                                              <strong>{((code.score/code.max_score)*100).toFixed(0)}%</strong>
                                            </div>
                                          </CircularProgressbarWithChildren>
                                        </div>                        
                                  </div>
                                </div>
                                <div className="pt-1">
                                  <ul className="list-inline">
                                    {code.skills.map((skill, index)=>(
                                      <li key={index} className="list-inline-item me-3">
                                        <span className='text-muted'>
                                          <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}{skill}
                                        </span>
                                    </li>
                                    ))}
                                  </ul>
                                  <p className='text-muted'>{code && code.code_result && code.code_result.summary}</p>
                                <Collapse isOpen={collapseStates[index]}>
                                  <h5 className='font-size-14 mb-0'>Evaluation:</h5>
                                  <ul className="list-inline">
                                    {code?.code_result?.evaluation.map((code_res, index)=>(
                                      <li key={index} className="list-inline-item me-3 text-muted"><i className='mdi mdi-circle-medium text-success align-middle'></i> {code_res}</li>
                                    ))}
                                  </ul>
                                  <h5 className='font-size-14 mb-0'>Reasoning:</h5>
                                  <ul className="list-inline">
                                    {code?.code_result?.reasoning.map((code_res, index)=>(
                                      <li key={index} className="list-inline-item me-3 text-muted"><i className='mdi mdi-circle-medium text-success align-middle'></i> {code_res}</li>
                                    ))}
                                  </ul>
                                  </Collapse>
                                  <div>
                                    <Link to="#" onClick={(e) => { handleReadMore(e,index)}} className="text-primary">{collapseStates[index] ? 'Read Less' : 'Read More'} <i className="mdi mdi-arrow-right" /></Link>
                                  </div>
                                </div>
                              </div>
                            </div>         
                            // </div>
                          ))}
                          </div>
                          ) : (
                            <Alert color="secondary" className="alert-label-icon label-arrow rounded-2">
                                No coding questions generated for this assessment.
                                <i className="mdi mdi-alert-circle-outline label-icon"></i>
                            </Alert>
                          )}
                      </Col>
                      </OffcanvasBody>
                      </Offcanvas>
                    </>
    )
  }



export default LearnMoreCoding