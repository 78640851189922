import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { Link } from 'react-router-dom';
// import { useCountdown } from './hooks/useCountdown';
import { useCountdown } from './useCountdown';
import { useDispatch } from 'react-redux';
import { setTimerData } from '../../store/timerSlice';
// import './CustomCss.css'
 
const ShowCounter = ({ minutes, seconds }) => {
  return (
    <div className="show-counter">
      <Link
        to="#"
        // target="_blank"
        // rel="noopener noreferrer"
        className="countdown-link"
      >
        {/* <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p> */}
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </Link>
    </div>
  );
};
 
const CountdownTimer = ({ questionTime }) => {
  const dispatch = useDispatch();
  const [minutes, seconds] = useCountdown(questionTime);
 
   if (minutes + seconds <= 0) {
    dispatch(setTimerData(0));
   }
  //   return <ExpiredNotice />;
  // } else {
    return (
      <ShowCounter
        // days={days}
        // hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };
// };
 
export default CountdownTimer;