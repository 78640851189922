import React, { useState, useEffect } from "react";
import classnames from "classnames";

import {
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    Col,
    Alert,
    Progress,
    UncontrolledTooltip,
} from "reactstrap";
import { isEmpty, map } from "lodash";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getuserData, setEditedData } from "../../store/userSlice";
import { api_get, api_post } from "../../services/service";
import { log_debug } from "../../helpers/logger";
import {
    ep,
    assessmentsession_steps,
    primaryShade1,
    primaryShade2,
} from "../../config/dev";
import { formatDate, dateyyyymmdd_tohtml } from "../../helpers/date";
import EditProfileDetails from "../../components/sliders/EditProfileDetails";
import EditWorkExperience from "../../components/sliders/EditWorkExperience";
import EditEducation from "../../components/sliders/EditEducation";
import EditCertification from "../../components/sliders/EditCertification";
import AddCertification from "../../components/sliders/AddCertification";
import EditPublication from "../../components/sliders/EditPublication";
import AddWorkExperience from "../../components/sliders/AddWorkExperience";
import AddEducation from "../../components/sliders/AddEducation";
import { setSelectedTab } from "../../store/snackbarSlice";
import Toast from '../../common/Toast';

const currentTab = 'Unlock your full potential! Enhance your profile by adding and editing information from your uploaded resume.';

const StepUserProfile = ({ data, toggleCurrentStep }) => {
    const dispatch = useDispatch();

    const [apiResponse, setApiResponse] = useState(null);
    const [isRight, setIsRight] = useState(false);
    const [isWork, setIsWork] = useState(false);
    const [addWork, setAddWork] = useState(false);
    const [isEducation, setIsEducation] = useState(false);
    const [addEducation, setAddEducation] = useState(false);
    const [isCertification, setIscertification] = useState(false);
    const [addCertification, setAddcertification] = useState(false);
    const [isPublication, setIsPublication] = useState(false);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    const [progressValue, setProgressValue] = useState(17);
    const [candidate, setCandidate] = useState(null);
    const [resume, setResume] = useState(null);
    const user_data = useSelector(getuserData);

    const [workExpDeleted, setWorkExpDeleted] = useState(false);
    const [eduDeleted, setEduDeleted] = useState(false);
    const [certiDeleted, setCertiDeleted] = useState(false);

    const [editedExpData, setEditedExpData] = useState({
        resume: {
            work_experiences: [],
        },
    });
    const [editedEduData, setEditedEduData] = useState({
        resume: {
            educational_qualifications: [],
        },
    });
    const [editedCertData, setEditedCertData] = useState({
        resume: {
            certifications: [],
        },
    })

    useEffect(() => {
        dispatch(setSelectedTab(currentTab));
    }, []);

    const fetchData = () => {
        setCandidate(user_data.candidate || null);
        setResume(
            user_data &&
                user_data.candidate &&
                user_data.candidate.resumes &&
                user_data.candidate.resumes.length > 0
                ? user_data.candidate.resumes[user_data.candidate.resumes.length - 1]
                : null
        );
        // setResume((user_data && user_data.candidate.resumes) ?  user_data.candidate.resumes[user_data.candidate.resumes.length - 1] : null);
        // if (data && data.candidate_guid && !candidate) {
        //   api_get(null, ep.assessment + "/v1/candidate/" + data.candidate_guid, (_candidate_err, _candidate_res) => {
        //     if (!_candidate_err) {
        //  
        //       setCandidate(_candidate_res.candidate);
        //       setResume(_candidate_res.candidate.resumes[_candidate_res.candidate.resumes.length - 1]);
        //     }
        //   });
        // }
    };


    const handleEditProfile = (e) => {
        setIsRight(!isRight);
    }
    const handleEditWork = (e) => {
        setIsWork(!isWork);
    }
    const handleAddWork = (e) => {
        setAddWork(!addWork);
    }
    const handleEditEducation = (e) => {
        setIsEducation(!isEducation);
    }
    const handleAddEducation = (e) => {
        setAddEducation(!addEducation);
    }
    const handleEditCertification = (e) => {
        setIscertification(!isCertification);
    }
    const handleAddCertification = (e) => {
        setAddcertification(!addCertification);
    }
    const handleEditPublication = (e) => {
        setIsPublication(!isPublication);
    }

    function on_click_work_exp_delete(workExpIdToDelete) {
        editedExpData.resume.work_experiences = resume.work_experiences.filter(exp => exp._id !== workExpIdToDelete);
        const apiEndpoint = ep.assessment + "/v1/resume/" + resume.guid;
        const requestBody = {
            resume: {
                guid: resume.guid,
                work_experiences: editedExpData.resume.work_experiences,
            },
        };
        api_post(null, apiEndpoint, requestBody, (error, responseData) => {
            if (error) {

            } else {
                setWorkExpDeleted(true);
                setTimeout(() => {
                    setWorkExpDeleted(false);
                }, 3000);
                setResume(responseData.resume);
                log_debug("stepUserProfile", "on_click_work_exp_delete", "API response data :: " + JSON.stringify(responseData));
            }
        });
    }
    function on_click_education_delete(eduIdToDelete) {
        editedEduData.resume.educational_qualifications = resume.educational_qualifications.filter(exp => exp._id !== eduIdToDelete);
        const apiEndpoint = ep.assessment + "/v1/resume/" + resume.guid;
        const requestBody = {
            resume: {
                guid: resume.guid,
                educational_qualifications: editedEduData.resume.educational_qualifications,
            },
        };
        api_post(null, apiEndpoint, requestBody, (error, responseData) => {
            if (error) {

            } else {
                setEduDeleted(true);
                setTimeout(() => {
                    setEduDeleted(false);
                }, 3000);
                setResume(responseData.resume);
                log_debug("stepUserProfile", "on_click_education_delete", "API response data :: " + JSON.stringify(responseData));
            }
        });
    }

    function on_click_certification_delete(certToDelete) {
        editedCertData.resume.certifications = resume.certifications.filter(cert => cert.certificate_name !== certToDelete);
        const apiEndpoint = ep.assessment + "/v1/resume/" + resume.guid;
        const requestBody = {
            resume: {
                guid: resume.guid,
                certifications: editedCertData.resume.certifications,
            },
        };
        api_post(null, apiEndpoint, requestBody, (error, responseData) => {
            if (error) {

            } else {
                setCertiDeleted(true);
                setTimeout(() => {
                    setCertiDeleted(false);
                }, 3000);
                setResume(responseData.resume);
                log_debug("stepUserProfile", "on_click_certification_delete", "API response data :: " + JSON.stringify(responseData));
            }
        });
    }

  const handleEditSubmit = (editedData) => {
    setCandidate(editedData.candidate);
    setResume(editedData.resume);
    dispatch(setEditedData(editedData));
    // setApiResponse(editedData);
  };

    useEffect(() => {
        fetchData();
    }, [user_data.candidate,user_data.summary]);

    const handleStepClick = (e) => {
        e.preventDefault();
        toggleCurrentStep(assessmentsession_steps.stepskillmapping.code, user_data.candidate.guid);
    }

    const toggleTabProgress = (tab) => {
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(17);
                }
                if (tab === 2) {
                    setProgressValue(33);
                }
                if (tab === 3) {
                    setProgressValue(49);
                }
                if (tab === 4) {
                    setProgressValue(65);
                }
                // if (tab === 5) {
                //     setProgressValue(82);
                // }
                // if (tab === 6) {
                //     setProgressValue(100);
                // }
            }
        }
    };

    return (
        <>
            <Card className="mt-2 border border-2">
                <CardBody>
                    {/* <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                        <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" />
                        <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i>
                        <i className="mdi mdi-alert-circle-outline label-icon"></i>Unlock your full potential! Enhance your profile by adding and editing information from your uploaded resume. Showcase your skills and experiences to customize the assessment process and make it uniquely yours. Let's make your journey even more tailored and personalized! 🌟📄🚀
                    </Alert> */}
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                        <Row>
                            <Col lg={1}>
                                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills profile_details_steps">
                                    <Nav vertical className="form-vertical">
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 1 })} onClick={() => { toggleTabProgress(1); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="ProfileDetails">
                                                    <i className="mdi mdi-badge-account-horizontal-outline"></i>
                                                    <UncontrolledTooltip placement="top" target="ProfileDetails">
                                                        Profile Details
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 2 })} onClick={() => { toggleTabProgress(2); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="WorkExperience">
                                                    <i className="mdi mdi-briefcase-account-outline"></i>
                                                    <UncontrolledTooltip placement="top" target="WorkExperience">
                                                        Work Experience
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 3 })} onClick={() => { toggleTabProgress(3); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="Education">
                                                    <i className="bx bx-book-bookmark"></i>
                                                    <UncontrolledTooltip placement="top" target="Education">
                                                        Education
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 4 })} onClick={() => { toggleTabProgress(4); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="Certifications">
                                                    <i className="mdi mdi-certificate-outline"></i>
                                                    <UncontrolledTooltip placement="top" target="Certifications">
                                                        Certifications
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 5 })} onClick={() => { toggleTabProgress(5); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="Publications">
                                                    <i className="fab fa-leanpub"></i>
                                                    <UncontrolledTooltip placement="top" target="Publications">
                                                        Publications
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTabProgress === 6 })} onClick={() => { toggleTabProgress(6); }} >
                                                <div className="step-icon" data-bs-toggle="tooltip" id="PreviousAssessments">
                                                    <i className="mdi mdi-ballot-outline"></i>
                                                    <UncontrolledTooltip placement="top" target="PreviousAssessments">
                                                        Previous Assessments
                                                    </UncontrolledTooltip>
                                                </div>
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                </ul>
                            </Col>
                            <Col lg={11}>
                                {/* <div id="bar" className="mt-4">
                            <Progress color="info" value={progressValue} />
                        </div> */}
                                <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content ms-4 pt-0">
                                    <TabPane tabId={1}>
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5 className="font-size-14 text-primary">Profile Details:</h5>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary p-1 ps-2 pe-2 rounded" onClick={(e) => { handleEditProfile(e) }}
                                            ><span className="badge bg-primary me-1 icon-md">
                                                    <i className="mdi mdi-account-edit"></i>
                                                </span> Edit
                                            </button>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-firstname-input"> Full Name</label>
                                                        {candidate && candidate.full_name && (
                                                            <p className="text-muted mb-0">{candidate.full_name}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-email-input">Email</label>
                                                        {candidate && candidate.email_id && (
                                                            <p className="text-muted mb-0">{candidate.email_id}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-phoneno-input">Phone</label>
                                                        {candidate && candidate.contact_no && (
                                                            <p className="text-muted mb-0">{candidate.contact_no}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-email-input">Date Of Birth</label>
                                                        {candidate && candidate.date_of_birth && (
                                                            <p className="text-muted mb-0">{dateyyyymmdd_tohtml(candidate.date_of_birth)}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-email-input">Marital Status </label>
                                                        {candidate && candidate.marital_status && (
                                                            <p className="text-muted mb-0">{candidate.marital_status}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="progresspill-email-input">LinkedIn</label>
                                                        {candidate && candidate.linkedin_url && (
                                                            <p className="text-muted mb-0">{candidate.linkedin_url}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">Summary</label>
                                                    {resume && resume.summary && (
                                                        <p className="text-muted mb-0">{resume.summary}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </form>

                                        <EditProfileDetails isRight={isRight} toggleRightCanvas={(e) => { handleEditProfile(e) }} data={{ candidate, resume }} onSubmit={handleEditSubmit} />

                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <div className="d-flex justify-content-between mb-4 ">
                                            <h5 className="font-size-14 text-primary">Work Experience:</h5>
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary p-1 ps-2 pe-2 " onClick={(e) => { handleAddWork(e) }}
                                                ><span className="badge bg-primary me-1 icon-md">
                                                        <i className="mdi mdi-account-edit"></i>
                                                    </span> Add
                                                </button>
                                                {resume?.work_experiences?.length > 0 && (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary p-1 ps-2 pe-2 " onClick={(e) => { handleEditWork(e) }}
                                                ><span className="badge bg-secondary me-1 icon-md">
                                                        <i className="mdi mdi-account-edit"></i>
                                                    </span> Edit
                                                </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ul className="list-unstyled chat-list">
                                                {resume?.work_experiences?.length > 0 ? (
                                                map(resume?.work_experiences, exp => (
                                                    <li key={exp._id}>
                                                        <Link to="#" onClick={(e) => { e.preventDefault(); }} style={{ cursor: "auto" }}>
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <h5 className="text-truncate font-size-14 mb-2">
                                                                        {exp.designation || ''}
                                                                    </h5>
                                                                    <div className="d-flex mb-1">
                                                                        <h6 className="text-truncate mb-0">
                                                                            {exp.company_name || ''}
                                                                        </h6>
                                                                        <p className="text-truncate font-size-12 ms-1 mb-0">
                                                                            {exp.from_date && exp.to_date ? (
                                                                                <span>
                                                                                    {formatDate(exp.from_date)} to {exp.to_date ? formatDate(exp.to_date) : ''}
                                                                                </span>
                                                                            ) : (
                                                                                ' '
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <p className="mb-0">{exp.project_details}</p>
                                                                </div>
                                                                <div>
                                                                    <button className="btn btn-outline-danger rounded p-1 ps-2 pe-2 font-size-10" onClick={() => on_click_work_exp_delete(exp._id)}><i className="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                ))):
                                            (
                                                        <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                                                            {/* <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" /> */}
                                                            No work experience found. Click the 'Add' button to include your work experience details and highlight your expertise. 📚🎓
                                                            {/* {/* <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i> */}
                                                            <i className="mdi mdi-alert-circle-outline label-icon"></i>
                                                        </Alert>
                                            )}
                                            </ul>
                                        </div>
                                        <EditWorkExperience isWork={isWork} toggleRightCanvas={(e) => { handleEditWork(e) }} data={{ resume }} onSubmit={handleEditSubmit} />
                                        <AddWorkExperience addWork={addWork} toggleRightCanvas={(e) => { handleAddWork(e) }} data={{ resume }} onSubmit={handleEditSubmit} />

                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5 className="font-size-14 text-primary">Education:</h5>
                                            {/* <p className="card-title-desc">Fill all information below</p> */}
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary p-1 ps-2 pe-2 " onClick={(e) => { handleAddEducation(e) }}
                                                ><span className="badge bg-primary me-1 icon-md">
                                                        <i className="mdi mdi-account-edit"></i>
                                                    </span> Add
                                                </button>
                                                {resume?.educational_qualifications?.length > 0 && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary p-1 ps-2 pe-2 " onClick={(e) => { handleEditEducation(e) }}
                                                    ><span className="badge bg-secondary me-1 icon-md">
                                                            <i className="mdi mdi-account-edit"></i>
                                                        </span> Edit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ul className="list-unstyled chat-list">
                                                {resume?.educational_qualifications?.length > 0 ? (
                                                    map(resume?.educational_qualifications, edu => (
                                                        <li key={edu._id}>
                                                            <Link to="#" onClick={(e) => { e.preventDefault(); }} style={{ cursor: "auto" }}>
                                                                <div className="d-flex align-items-start">
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <h5 className="text-truncate font-size-14 mb-2">
                                                                            {edu.degree_name}
                                                                        </h5>
                                                                        <div className="d-flex font-size-12">
                                                                            <h6 className="text-truncate mb-0">
                                                                                {edu.college_or_university_name}
                                                                            </h6>
                                                                            <p className="text-truncate ms-1 mb-0">
                                                                                {edu.from_date && edu.to_date ? (
                                                                                    <span>
                                                                                        {formatDate(edu.from_date)} to {edu.to_date ? formatDate(edu.to_date) : ''}
                                                                                    </span>
                                                                                ) : (
                                                                                    ' '
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button className="btn btn-outline-danger rounded p-1 ps-2 pe-2 font-size-10" onClick={() => on_click_education_delete(edu._id)} ><i className="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))) :
                                                    (
                                                        <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                                                            {/* <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" /> */}
                                                            No education details found. Click the 'Add' button to include your education details and highlight your expertise. 🏆📜
                                                            {/* {/* <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i> */}
                                                            <i className="mdi mdi-alert-circle-outline label-icon"></i>
                                                        </Alert>
                                                        // <p>no certificate</p>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <EditEducation isEducation={isEducation} toggleRightCanvas={(e) => { handleEditEducation(e) }} data={{ resume }} onSubmit={handleEditSubmit} />
                                        <AddEducation addEducation={addEducation} toggleRightCanvas={(e) => { handleAddEducation(e) }} data={{ resume }} onSubmit={handleEditSubmit} />

                                    </TabPane>
                                    <TabPane tabId={4}>
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5 className="font-size-14 text-primary">Certification:</h5>
                                            {/* <p className="card-title-desc">Fill all information below</p> */}
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary p-1 ps-2 pe-2 " onClick={(e) => { handleAddCertification(e) }}
                                                ><span className="badge bg-primary me-1 icon-md">
                                                        <i className="mdi mdi-account-edit"></i>
                                                    </span> Add
                                                </button>
                                                {resume?.certifications?.length > 0 && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary p-1 ps-2 pe-2 " onClick={(e) => { handleEditCertification(e) }}
                                                    ><span className="badge bg-secondary me-1 icon-md">
                                                            <i className="mdi mdi-account-edit"></i>
                                                        </span> Edit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ul className="list-unstyled chat-list">
                                                {resume?.certifications?.length > 0 ? (
                                                    map(resume?.certifications, (cert, index) => (
                                                        <li key={index}>
                                                            <Link to="#" onClick={(e) => { e.preventDefault(); }} style={{ cursor: "auto" }}>
                                                                <div className="d-flex align-items-start">
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <h5 className="text-truncate font-size-14 mb-2">
                                                                            {cert.certificate_name}
                                                                        </h5>
                                                                        <div className="d-flex ">

                                                                            <p className="text-truncate font-size-12 ms-1 mb-0">
                                                                                {cert.certification_date ? (
                                                                                    <span>
                                                                                        {formatDate(cert.certification_date)}
                                                                                    </span>
                                                                                ) : (
                                                                                    ' '
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button className="btn btn-outline-danger rounded p-1 ps-2 pe-2 font-size-10" onClick={() => on_click_certification_delete(cert.certificate_name)} ><i className="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))) :
                                                    (
                                                        <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                                                            {/* <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" /> */}
                                                            No certifications found. Click the 'Add' button to include your certification details and highlight your expertise. 🏆📜
                                                            {/* {/* <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i> */}
                                                            <i className="mdi mdi-alert-circle-outline label-icon"></i>
                                                        </Alert>
                                                        // <p>no certificate</p>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <EditCertification isCertification={isCertification} toggleRightCanvas={(e) => { handleEditCertification(e) }} data={{ resume }} onSubmit={handleEditSubmit} />
                                        <AddCertification addCertification={addCertification} toggleRightCanvas={(e) => { handleAddCertification(e) }} data={{ resume }} onSubmit={handleEditSubmit} />

                                    </TabPane>

                                    {/* <ul className="pager wizard twitter-bs-wizard-pager-link btn-group float-center">
                                        <li className={activeTabProgress === 1 ? "previous disabled" : "previous"}>
                                            <Link
                                                to="#"
                                                className={activeTabProgress === 1 ? "btn btn-secondary disabled" : "btn btn-secondary"}
                                                onClick={() => {
                                                    toggleTabProgress(activeTabProgress - 1);
                                                }}
                                            > <i className="bx bx-chevron-left me-1"></i>
                                            </Link>
                                        </li>

                                        <li className={activeTabProgress === 3 ? "next disabled" : "next"}>
                                            <Link
                                                to="#"
                                                className={activeTabProgress === 4 ? "btn btn-secondary disabled" : "btn btn-secondary"}
                                                onClick={() => {
                                                    toggleTabProgress(activeTabProgress + 1);
                                                }}
                                            > <i className="bx bx-chevron-right ms-1"></i>
                                            </Link>
                                        </li>
                                    </ul> */}

                                    {/* <TabPane tabId={5}>
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5 class="font-size-14 text-primary">Publications:</h5>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary p-1 ps-2 pe-2 rounded" onClick={(e) => { handleEditPublication(e) }}
                                            ><span className="badge bg-primary me-1 icon-md">
                                                    <i className="mdi mdi-account-edit"></i>
                                                </span> Edit
                                            </button>
                                        </div>
                                        <div className="row">
                                            <ul className="list-unstyled chat-list">
                                                {
                                                    resume?.publications ? (
                                                        map(resume?.publications, (pub, index) => (

                                                            <li key={index}>
                                                                <Link to="#">
                                                                    <div className="d-flex align-items-start">
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="text-truncate font-size-14 mb-2">
                                                                                {pub.publication_name}
                                                                            </h5>
                                                                            <div className="d-flex ">

                                                                                <p className="text-truncate font-size-12 ms-1 mb-0">{(pub.publication_date) ? formatDate(pub.publication_date) : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <span className="btn btn-outline-danger rounded p-1 ps-2 pe-2 font-size-10"><i className="fas fa-trash"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        ))
                                                    ) :
                                                        (
                                                            <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                                                                <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" />
                                                                <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i>
                                                                <i className="mdi mdi-alert-circle-outline label-icon"></i>Unlock your full potential! Enhance your profile by adding and editing information from your uploaded resume. Showcase your skills and experiences to customize the assessment process and make it uniquely yours. Let's make your journey even more tailored and personalized! 🌟📄🚀
                                                            </Alert>
                                                        )
                                                }

                                            </ul>
                                        </div>
                                        <EditPublication isPublication={isPublication} toggleRightCanvas={(e) => { handleEditPublication(e) }} data={{ resume }} onSubmit={handleEditSubmit} />

                                    </TabPane>
                                    <TabPane tabId={6}>
                                        <div>
                                            <div className="text-center mb-4">
                                                <h5>Previous Assessments</h5>
                                                <p className="card-title-desc">Fill all information below</p>
                                            </div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="progresspill-namecard-input" className="form-label">Name on Card</label>
                                                            <input type="text" className="form-control" id="progresspill-namecard-input" placeholder="Enter your Name on Card" />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Credit Card Type</label>
                                                            <select className="form-select">
                                                                <option>Select Card Type</option>
                                                                <option defaultValue="AE">American Express</option>
                                                                <option value="VI">Visa</option>
                                                                <option value="MC">MasterCard</option>
                                                                <option value="DI">Discover</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="progresspill-cardno-input" className="form-label">Credit Card Number</label>
                                                            <input type="text" className="form-control" id="progresspill-cardno-input" placeholder="Enter your Credit Card Number" />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="progresspill-card-verification-input" className="form-label">Card Verification Number</label>
                                                            <input type="text" className="form-control" id="progresspill-card-verification-input" placeholder="Enter your Card Verification Number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="progresspill-expiration-input" className="form-label">Expiration Date</label>
                                                            <input type="date" className="form-control" id="progresspill-expiration-input" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </TabPane> */}

                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-2'>
                        <div className=" d-flex justify-content-end mb-2">

                            <button className={activeTabProgress === 1 ? "waves-effect btn btn-secondary btn-sm rounded-0 disabled" : "waves-effect btn btn-secondary btn-sm rounded-0"} onClick={() => { toggleTabProgress(activeTabProgress - 1); }}> <i className="fa fa-chevron-left"></i> </button>
                            <button className={activeTabProgress === 4 ? "waves-effect btn btn-secondary btn-sm rounded-0 disabled" : "waves-effect btn btn-secondary btn-sm rounded-0"} onClick={() => { toggleTabProgress(activeTabProgress + 1); }} > <i className="fa fa-chevron-right"></i> </button>

                        </div>
                        <Link to="#" className="btn btn-primary btn-sm waves-effect waves-light float-end p-2 mt-5" onClick={(e) => handleStepClick(e)}> Go To {assessmentsession_steps.stepskillmapping.title} <i className="fas fa-arrow-right align-middle ms-1"></i></Link>
                    </div>
                </CardBody>
                {/* <CardBody> */}

                {workExpDeleted && (
                    <Toast message="Work Experience deleted successfully" type="success" position="bottom-right" autoClose={false} />
                )
                }
                {eduDeleted && (
                    <Toast message="Education deleted successfully" type="success" position="bottom-right" autoClose={false}
                    />
                )
                }
                {certiDeleted && (
                    <Toast
                        message="Certificate deleted successfully"
                        type="success"
                        position="bottom-right"
                        autoClose={false}
                    />
                )
                }
                {/* </CardBody> */}

            </Card>

            {/* <div className="float-right pull-right">
                <button
                    type="button"
                    className="btn btn-primary btn-rounded waves-effect waves-light pull-right" 
                >
                    Go to {assessmentsession_steps.stepskillmapping.title}
                </button>
                <Link
                    to="#"
                    className="btn btn-primary btn-lg float-end"
                    role="button"
                    aria-disabled="true"
                >
                    Primary link
                </Link>
            </div> */}
        </>
    );
};

export default StepUserProfile;
