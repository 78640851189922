export const devops = [
 { title: "Kubernetes", category: "Framework", icon: "assets/icons/img/dev-ops/kubernetes.png", status: "" },
 { title: "Docker", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/docker.png", status: "" },
 { title: "Ansible", category: "Programming Language", icon: "assets/icons/img/dev-ops/ansible.png", status: "" },
 { title: "Jenkins", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/jenkins.png", status: "" },
 { title: "Git", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/git.png", status: "" },
 { title: "Terraform", category: "Programming Language", icon: "assets/icons/img/dev-ops/terraform.png", status: "" },
 { title: "Docker Hub", category: "SaaS", icon: "assets/icons/img/dev-ops/docker-hub.png", status: "" },
 { title: "Jira", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/jira.png", status: "" },
 { title: "CircleCI", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/circleci.png", status: "" },
 { title: "Travis CI", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/travis-ci.png", status: "" },
 { title: "GitLab CI/CD", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/gitlab-ci-cd.png", status: "" },
 { title: "Azure DevOps", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/azure-devops.png", status: "" },
 { title: "GitHub", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/github.png", status: "" },
 { title: "Bamboo", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/bamboo.png", status: "" },
 { title: "TeamCity", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/teamcity.png", status: "" },
 { title: "GoCD", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/gocd.png", status: "" },
 { title: "Drone", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/drone.png", status: "" },
 { title: "Bitbucket Pipeline", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/bitbucket-pipeline.png", status: "" },
 { title: "Nagios", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/nagios.png", status: "" },
 { title: "Prometheus", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/prometheus.png", status: "" },
 { title: "Grafan", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/grafan.png", status: "" },
 { title: "Chef", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/chef.png", status: "" },
 { title: "Puppet", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/puppet.png", status: "" },
 { title: "Shell Scripting", category: "Programming Language", icon: "assets/icons/img/dev-ops/shell-scripting.png", status: "" },
 { title: "Python", category: "Programming Language", icon: "assets/icons/img/dev-ops/python.png", status: "" },
 { title: "Ruby", category: "Programming Language", icon: "assets/icons/img/dev-ops/ruby.png", status: "" },
 { title: "Go", category: "Programming Language", icon: "assets/icons/img/dev-ops/go.png", status: "" },
 { title: "Rust", category: "Programming Language", icon: "assets/icons/img/dev-ops/rust.png", status: "" },
 { title: "JavaScript", category: "Programming Language", icon: "assets/icons/img/dev-ops/javascript.png", status: "" },
 { title: "Snyk", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/snyk.png", status: "" },
 { title: "SonarQube", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/sonarqube.png", status: "" },
 { title: "Bitbucket ", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/bitbucket-.png", status: "" },
 { title: "Jfrog", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/jfrog.png", status: "" },
 { title: "GitLab", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/gitlab.png", status: "" },
 { title: "Helm", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/helm.png", status: "" },
 { title: "SaltStack", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/saltstack.png", status: "" },
 { title: "Linux", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/linux.png", status: "" },
 { title: "Kubeadm", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/kubeadm.png", status: "" },
 { title: "Nginx", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/nginx.png", status: "" },
 { title: "Maven", category: "Platform / Tool", icon: "assets/icons/img/dev-ops/maven.png", status: "" },
];
