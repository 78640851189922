import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml, validateStartEndDate } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import { map } from "lodash";
import validator from 'validator'


const EditCertification = ({ isCertification, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditWorkExperience', 'edit work data' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState({
        resume: {
            certifications: [],
        },
    });

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    // Handler for input changes in the form
    const handleInputChange = (index, field, value) => {

        // Validation checks
        let updatedErrors = { ...validationErrors };
        let currentErrors = updatedErrors[index] || {};

        switch (field) {
            case 'certificate_name':
                currentErrors[field] = validator.isEmpty(value) ? 'Certificate name is required' : '';
                break;
            case 'certification_date':
                currentErrors[field] = validator.isEmpty(value) ? 'Certificate date is required' : '';
                break;
            default:
                break;
        }

        updatedErrors[index] = currentErrors;
        setValidationErrors(updatedErrors);

        setEditedData((prevData) => {
            const updatedCertification = [...prevData.resume.certifications];

            if (updatedCertification[index]) {
                updatedCertification[index] = {
                    ...updatedCertification[index],
                    [field]: value,
                };
            }

            return {
                ...prevData,
                resume: {
                    ...prevData.resume,
                    certifications: updatedCertification,
                },
            };
        });
    };

    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    // Handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        editedData.resume.certifications.forEach((cert, index) => {
            // Initialize errors for the current work experience
            let experienceErrors = {};

            for (const key in cert) {
                const value = cert[key];

                // Check if the value is an array and convert it to a string
                const stringValue = Array.isArray(value) ? value.join(', ') : value;

                // Set errors for the current field in the current work experience
                if (key === 'certificate_name') {
                    experienceErrors[key] = validator.isEmpty(stringValue) ? 'Certificate name is required' : '';
                }
                if (key === 'certification_date') {
                    experienceErrors[key] = validator.isEmpty(stringValue) ? 'Certificate date is required' : '';

                    if (!validator.isDate(dateyyyymmdd_tohtml(cert['certification_date']))) {
                        experienceErrors[key] = 'Please enter valid certification date';
                    }
                }

                if (key === 'to_date') {
                    experienceErrors[key] = validator.isEmpty(stringValue) ? 'End date is required' : '';

                    if (!validator.isDate(dateyyyymmdd_tohtml(cert['to_date']))) {
                        experienceErrors[key] = 'Please enter valid end date';
                    }
                }
                if (!validateStartEndDate(cert['from_date'], cert['to_date'])) {
                    experienceErrors['from_date'] = 'Start Date can not be greater than End Date';
                }
            }

            // Set errors for the current work experience in the main errors object
            errors[index] = experienceErrors;
        });

        setValidationErrors(errors);

        if (Object.values(errors).some((experienceErrors) => Object.values(experienceErrors).some((error) => error !== ''))) {
            // If there are validation errors, do not submit
            return;
        }

        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;

        //request body with the updated work experiences
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                work_experiences: editedData.resume.work_experiences,
            },
        };
        // API call to update the resume
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'responseData::' + JSON.stringify(responseData));
                // Invoke the onSubmit callback with the updated data
                onSubmit(editedData);
                // Close the Offcanvas
                toggleRightCanvas();
            }
        });
    };

    return (
        <>
            <Offcanvas
                isOpen={isCertification}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
                style={{zoom:'80%'}}
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Edit Certification
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                {map(editedData?.resume.certifications, (cert, index) => (
                                    <div key={index}>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Certification <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input type="text" name='certificate_name' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" value={cert?.certificate_name || ''} onChange={(e) => handleInputChange(index, 'certificate_name', e.target.value)} />
                                                {validationErrors[index]?.certificate_name && <small className="text-danger">{validationErrors[index]?.certificate_name}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Date <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input className="form-control" name='certification_date' type="date" id="horizontal-dob-input" value={cert?.certification_date ? dateyyyymmdd_tohtml(cert.certification_date) : ''} onChange={(e) => handleInputChange(index, 'certification_date', e.target.value)} />
                                                {validationErrors[index]?.certification_date && <small className="text-danger">{validationErrors[index]?.certification_date}</small>}
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                ))}

                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EditCertification