import { configureStore } from "@reduxjs/toolkit";

import assessmentReducer from "./assessmentSlice";
import userReducer from "./userSlice";
import snackbarReducer from "./snackbarSlice";
import informationReducer from './informationSlice';
import timerReducer from './timerSlice';

const store = configureStore({
  reducer: {
    assessment: assessmentReducer,
    user: userReducer,
      information:informationReducer,
      timer:timerReducer,
    snackbar: snackbarReducer,
    // Add other reducers if needed
  },
});
export default store;

