export const ep = {
 
 localhost: "http://localhost:",
 identity: "https://qoyj2nxyal.execute-api.ap-south-1.amazonaws.com/latest",
 entity: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
 assessment: "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest",
 crawler_service_ep: 'https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest',
 document_page: 'https://dfhteux1g7.execute-api.ap-south-1.amazonaws.com/latest',
 resumeparsing: "https://files.talentiq.app",
 files: "https://files.talentiq.app",
};

export const common = {
  app_version: "0.0.1",
  service_ref: account_prop("common.service_ref") || "02d45aaa",
  guid_format: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
  short_guid_format: "xxxxxxxx",
  log_enabled: true,
};

export const cussWords = [
  "shit",
  "fuck",
  "screw you",
  "asshole",
  "bastard",
  "fucking",
  "motherfucker",
];

// Primary colors and shades
export const primaryShade1 = "#21419E";
export const primaryShade2 = "#3D4DB3";
export const primaryShade3 = "#4051C2";
export const primaryShade4 = "#475AD1";
export const primaryShade5 = "#4D61E3";

// Secondary colors and shades
export const secondaryShade1 = "#01ABED";
export const secondaryShade2 = "#35C4FC";
export const secondaryShade3 = "#52CEFF";
export const secondaryShade4 = "#85DCFF";
export const secondaryShade5 = "#B6E7FA";

export let isFeedbackRequired = true;

//Our Compiler
export const judge0_compiler = {
  api_key_compiler: "902fab5801msh79c167421940296p177004jsn7ff1a9095eab",
  api_compiler_host: "judge0-ce.p.rapidapi.com",
  api_compiler_submission_url: "https://judge0-ce.p.rapidapi.com/submissions",
};

// export const stop_words = ['the','a','in','is','I','to','as']; // TODO improve this list
export const stop_words = [
  "the",
  "a",
  "in",
  "is",
  "I",
  "able",
  "about",
  "above",
  "according",
  "accordingly",
  "across",
  "actually",
  "after",
  "afterwards",
  "again",
  "against",
  "all",
  "allow",
  "allows",
  "almost",
  "alone",
  "along",
  "already",
  "also",
  "although",
  "always",
  "am",
  "among",
  "amongst",
  "an",
  "and",
  "another",
  "any",
  "anybody",
  "anyhow",
  "anyone",
  "anything",
  "anyway",
  "anyways",
  "anywhere",
  "apart",
  "appear",
  "appreciate",
  "appropriate",
  "are",
  "around",
  "as",
  "aside",
  "ask",
  "asking",
  "associated",
  "at",
  "available",
  "away",
  "awfully",
  "be",
  "because",
  "become",
  "becomes",
  "becoming",
  "been",
  "before",
  "beforehand",
  "behind",
  "being",
  "believe",
  "below",
  "beside",
  "besides",
  "best",
  "better",
  "between",
  "beyond",
  "both",
  "brief",
  "but",
  "by",
  "came",
  "can",
  "cause",
  "certain",
  "certainly",
  "changes",
  "clearly",
  "co",
  "com",
  "come",
  "comes",
  "concerning",
  "consequently",
  "consider",
  "considering",
  "contain",
  "containing",
  "contains",
  "corresponding",
  "could",
  "course",
  "currently",
  "definitely",
  "described",
  "despite",
  "did",
  "different",
  "do",
  "does",
  "don't",
  "done",
  "down",
  "during",
  "each",
  "edu",
  "eg",
  "eight",
  "either",
  "else",
  "elsewhere",
  "enough",
  "entirely",
  "especially",
  "et",
  "etc",
  "even",
  "ever",
  "every",
  "everybody",
  "everyone",
  "everything",
  "everywhere",
  "ex",
  "exactly",
  "example",
  "except",
  "far",
  "few",
  "fifth",
  "first",
  "five",
  "followed",
  "following",
  "follows",
  "for",
  "former",
  "formerly",
  "forth",
  "four",
  "from",
  "further",
  "furthermore",
  "get",
  "gets",
  "getting",
  "given",
  "gives",
  "go",
  "goes",
  "going",
  "gone",
  "got",
  "gotten",
  "greetings",
  "had",
  "happens",
  "hardly",
  "has",
  "have",
  "he",
  "hello",
  "help",
  "hence",
  "her",
  "here",
  "hereafter",
  "hereby",
  "herein",
  "hereupon",
  "hers",
  "hi",
  "him",
  "his",
  "hither",
  "hopefully",
  "how",
  "howbeit",
  "however",
  "ie",
  "if",
  "ignored",
  "immediate",
  "in",
  "inasmuch",
  "inc",
  "indeed",
  "indicate",
  "indicated",
  "indicates",
  "inner",
  "insofar",
  "instead",
  "into",
  "inward",
  "is",
  "it",
  "its",
  "itself",
  "just",
  "keep",
  "keeps",
  "kept",
  "know",
  "known",
  "knows",
  "last",
  "lately",
  "later",
  "latter",
  "latterly",
  "least",
  "less",
  "lest",
  "let",
  "like",
  "liked",
  "likely",
  "little",
  "look",
  "looking",
  "looks",
  "mainly",
  "many",
  "may",
  "maybe",
  "me",
  "mean",
  "meanwhile",
  "merely",
  "might",
  "more",
  "moreover",
  "most",
  "mostly",
  "much",
  "must",
  "my",
  "name",
  "nd",
  "near",
  "nearly",
  "necessary",
  "need",
  "needs",
  "never",
  "new",
  "next",
  "nine",
  "no",
  "nobody",
  "non",
  "none",
  "noone",
  "normally",
  "not",
  "nothing",
  "now",
  "nowhere",
  "obviously",
  "of",
  "off",
  "often",
  "oh",
  "ok",
  "okay",
  "old",
  "on",
  "once",
  "one",
  "ones",
  "only",
  "onto",
  "or",
  "other",
  "others",
  "otherwise",
  "ought",
  "our",
  "ours",
  "ourselves",
  "out",
  "outside",
  "over",
  "overall",
  "own",
  "particular",
  "particularly",
  "per",
  "perhaps",
  "placed",
  "plus",
  "possible",
  "presumably",
  "probably",
  "provides",
  "que",
  "quite",
  "qv",
  "rather",
  "rd",
  "re",
  "really",
  "regarding",
  "regardless",
  "regards",
  "relatively",
  "respectively",
  "right",
  "said",
  "same",
  "saw",
  "say",
  "saying",
  "says",
  "second",
  "secondly",
  "see",
  "seeing",
  "seem",
  "seemed",
  "seeming",
  "seems",
  "seen",
  "self",
  "sensible",
  "serious",
  "seriously",
  "seven",
  "several",
  "shall",
  "she",
  "should",
  "since",
  "six",
  "so",
  "some",
  "somebody",
  "somehow",
  "someone",
  "something",
  "sometime",
  "sometimes",
  "somewhat",
  "somewhere",
  "soon",
  "sorry",
  "specified",
  "specify",
  "specifying",
  "still",
  "sub",
  "such",
  "sup",
  "sure",
  "take",
  "taken",
  "tell",
  "tends",
  "than",
  "thank",
  "thanks",
  "thanx",
  "that",
  "the",
  "their",
  "theirs",
  "them",
  "themselves",
  "then",
  "thence",
  "there",
  "thereafter",
  "thereby",
  "therefore",
  "therein",
  "theres",
  "thereupon",
  "these",
  "they",
  "think",
  "third",
  "this",
  "thorough",
  "thoroughly",
  "those",
  "though",
  "three",
  "through",
  "throughout",
  "thru",
  "thus",
  "to",
  "together",
  "too",
  "took",
  "toward",
  "towards",
  "tried",
  "tries",
  "truly",
  "try",
  "trying",
  "twice",
  "two",
  "un",
  "under",
  "unless",
  "unlikely",
  "until",
  "unto",
  "up",
  "upon",
  "us",
  "use",
  "used",
  "useful",
  "uses",
  "using",
  "usually",
  "value",
  "various",
  "very",
  "via",
  "viz",
  "vs",
  "want",
  "wants",
  "was",
  "way",
  "we",
  "welcome",
  "well",
  "went",
  "were",
  "what",
  "when",
  "whence",
  "whenever",
  "where",
  "whereafter",
  "whereas",
  "whereby",
  "wherein",
  "whereupon",
  "wherever",
  "whether",
  "which",
  "while",
  "whither",
  "who",
  "whose",
  "why",
  "will",
  "willing",
  "wish",
  "with",
  "within",
  "without",
  "wonder",
  "would",
  "yes",
  "yet",
  "you",
  "your",
  "yours",
  "zero",
];

export const language_options = [
  {
    id: 63,
    name: "JavaScript (Node.js 12.14.0)",
    label: "JavaScript (Node.js 12.14.0)",
    value: "javascript",
  },
  {
    id: 45,
    name: "Assembly (NASM 2.14.02)",
    label: "Assembly (NASM 2.14.02)",
    value: "assembly",
  },
  { id: 46, name: "Bash (5.0.0)", label: "Bash (5.0.0)", value: "bash" },
  {
    id: 47,
    name: "Basic (FBC 1.07.1)",
    label: "Basic (FBC 1.07.1)",
    value: "basic",
  },
  { id: 75, name: "C (Clang 7.0.1)", label: "C (Clang 7.0.1)", value: "c" },
  {
    id: 76,
    name: "C++ (Clang 7.0.1)",
    label: "C++ (Clang 7.0.1)",
    value: "cpp",
  },
  { id: 48, name: "C (GCC 7.4.0)", label: "C (GCC 7.4.0)", value: "c" },
  { id: 52, name: "C++ (GCC 7.4.0)", label: "C++ (GCC 7.4.0)", value: "cpp" },
  { id: 49, name: "C (GCC 8.3.0)", label: "C (GCC 8.3.0)", value: "c" },
  { id: 53, name: "C++ (GCC 8.3.0)", label: "C++ (GCC 8.3.0)", value: "cpp" },
  { id: 50, name: "C (GCC 9.2.0)", label: "C (GCC 9.2.0)", value: "c" },
  { id: 54, name: "C++ (GCC 9.2.0)", label: "C++ (GCC 9.2.0)", value: "cpp" },
  {
    id: 86,
    name: "Clojure (1.10.1)",
    label: "Clojure (1.10.1)",
    value: "clojure",
  },
  {
    id: 51,
    name: "C# (Mono 6.6.0.161)",
    label: "C# (Mono 6.6.0.161)",
    value: "csharp",
  },
  {
    id: 77,
    name: "COBOL (GnuCOBOL 2.2)",
    label: "COBOL (GnuCOBOL 2.2)",
    value: "cobol",
  },
  {
    id: 55,
    name: "Common Lisp (SBCL 2.0.0)",
    label: "Common Lisp (SBCL 2.0.0)",
    value: "lisp",
  },
  { id: 56, name: "D (DMD 2.089.1)", label: "D (DMD 2.089.1)", value: "d" },
  { id: 57, name: "Elixir (1.9.4)", label: "Elixir (1.9.4)", value: "elixir" },
  {
    id: 58,
    name: "Erlang (OTP 22.2)",
    label: "Erlang (OTP 22.2)",
    value: "erlang",
  },
  { id: 44, label: "Executable", name: "Executable", value: "exe" },
  {
    id: 87,
    name: "F# (.NET Core SDK 3.1.202)",
    label: "F# (.NET Core SDK 3.1.202)",
    value: "fsharp",
  },
  {
    id: 59,
    name: "Fortran (GFortran 9.2.0)",
    label: "Fortran (GFortran 9.2.0)",
    value: "fortran",
  },
  { id: 60, name: "Go (1.13.5)", label: "Go (1.13.5)", value: "go" },
  { id: 88, name: "Groovy (3.0.3)", label: "Groovy (3.0.3)", value: "groovy" },
  {
    id: 61,
    name: "Haskell (GHC 8.8.1)",
    label: "Haskell (GHC 8.8.1)",
    value: "haskell",
  },
  {
    id: 62,
    name: "Java (OpenJDK 13.0.1)",
    label: "Java (OpenJDK 13.0.1)",
    value: "java",
  },
  {
    id: 78,
    name: "Kotlin (1.3.70)",
    label: "Kotlin (1.3.70)",
    value: "kotlin",
  },
  { id: 64, name: "Lua (5.3.5)", label: "Lua (5.3.5)", value: "lua" },
  {
    id: 79,
    name: "Objective-C (Clang 7.0.1)",
    label: "Objective-C (Clang 7.0.1)",
    value: "objectivec",
  },
  { id: 65, name: "OCaml (4.09.0)", label: "OCaml (4.09.0)", value: "ocaml" },
  { id: 66, name: "Octave (5.1.0)", label: "Octave (5.1.0)", value: "octave" },
  {
    id: 67,
    name: "Pascal (FPC 3.0.4)",
    label: "Pascal (FPC 3.0.4)",
    value: "pascal",
  },
  { id: 85, name: "Perl (5.28.1)", label: "Perl (5.28.1)", value: "perl" },
  { id: 68, name: "PHP (7.4.1)", label: "PHP (7.4.1)", value: "php" },
  { id: 43, label: "Plain Text", name: "Plain Text", value: "text" },
  {
    id: 69,
    name: "Prolog (GNU Prolog 1.4.5)",
    label: "Prolog (GNU Prolog 1.4.5)",
    value: "prolog",
  },
  {
    id: 70,
    name: "Python (2.7.17)",
    label: "Python (2.7.17)",
    value: "python",
  },
  { id: 71, name: "Python (3.8.1)", label: "Python (3.8.1)", value: "python" },
  { id: 80, name: "R (4.0.0)", label: "R (4.0.0)", value: "r" },
  { id: 72, name: "Ruby (2.7.0)", label: "Ruby (2.7.0)", value: "ruby" },
  { id: 73, name: "Rust (1.40.0)", label: "Rust (1.40.0)", value: "rust" },
  { id: 81, name: "Scala (2.13.2)", label: "Scala (2.13.2)", value: "scala" },
  {
    id: 82,
    name: "SQL (SQLite 3.27.2)",
    label: "SQL (SQLite 3.27.2)",
    value: "sql",
  },
  { id: 83, name: "Swift (5.2.3)", label: "Swift (5.2.3)", value: "swift" },
  {
    id: 74,
    name: "TypeScript (3.7.4)",
    label: "TypeScript (3.7.4)",
    value: "typescript",
  },
  {
    id: 84,
    name: "Visual Basic.Net (vbnc 0.0.0.5943)",
    label: "Visual Basic.Net (vbnc 0.0.0.5943)",
    value: "vbnet",
  },
];

export const unhandled_error = {
  server_error: {
    code: "TIQ-UH-001",
    message:
      "There appears to be an error. Please reload your page, or contact customer service if the issue persists.",
  },
  traffic_error: {
    code: "TIQ-TF-001",
    message:
      "We're presently experiencing excessive traffic. Please try again later or contact customer support if the problem persists.",
  },
  insufficient_tokens_error: {
    code: "TIQ-INSFTK-001",
    message:
      "You do not have enough tokens to complete this action. Upgrade or contact customer service for more assistance..",
  },
  insufficient_privileges_error: {
    code: "TIQ-INSFPRV-001",
    message:
      "You do not have enough privileges to complete this action. Please contact your account administrator or reachout to customer service for more assistance..",
  },
};

export const questions_timer = {
  video_question_timer: 5,
  code_question_timer: 10,
  mcq_question_timer: 0.5,
};

export const assessmentsession_steps = {
  stepuserprofile: {
    code: "stepuserprofile",
    title: "Complete Your Profile",
    icon: "assets/icon/profile.svg",
  },
  stepskillmapping: {
    code: "stepskillmapping",
    title: "Select Your Skills",
    icon: "assets/icon/skill.svg",
  },
  // stepidentityverification: { code: "stepidentityverification", title: "", icon: "assets/icons/svg/103781_profile_edit_user_icon.svg" },
  stepskilltest: {
    code: "stepskilltest",
    title: "Skill Test",
    icon: "assets/icon/skill.svg",
  },
  stepbehavioural: {
    code: "stepbehavioural",
    title: "Video Interview",
    icon: "assets/icon/video.svg",
  },
  steptalentiqscore: {
    code: "steptalentiqscore",
    title: "TalentIQ Score",
    icon: "assets/icon/score.svg",
  },
};

// export const ph_config = {
//  key: "phc_EpJznfTdrISZIrhO0tSrY0hDwu8lJzTozmDbox7njHI",
//  url: "https://app.posthog.com",
// };

export const pusher_config = {
  key: "6bd1b4335e499a00a292",
  cluster: "ap2",
};

function account_prop(prop_name) {
  let subdomain = window.location.origin.split(".")[0].split("//")[1];
  let acc_props = {};

  // acc_props["tiqdemo"] = { common: { service_ref: "02d45aaa" } };
  acc_props["companybench"] = { common: { service_ref: "yvy7a5sb" } };
  acc_props["account1"] = { common: { service_ref: "b8ilcgos" } };
  acc_props["tiq-webapp-dev"] = { common: { service_ref: "b8ilcgos" } };

  try {
    return acc_props[subdomain][prop_name.split(".")[0]][
      prop_name.split(".")[1]
    ];
  } catch (_prop_err) {
    return null;
  }
}

export function getScoreLabel(score) {
  if (score > 89) {
    return {
      label: "Excellent",
      //    icon: "<i class='icon ion-md-arrow-up'></i>",
      class: "text-success",
    };
  } else if (score > 69) {
    return {
      label: "Very Good",
      class: "primary",
    };
  } else if (score >= 49) {
    return {
      label: "Good",
      class: "text-secondary",
    };
  } else if (score > 29) {
    return {
      label: "Fair",
      class: "text-warning",
    };
  } else if (score > 9) {
    return {
      label: "Poor",
      class: "text-danger",
    };
  } else {
    return {
      label: "Very Poor",
      class: "text-danger",
    };
  }
}

export const tableData = [
 {
  rank_code: "excellent",
  rank_title: "Excellent",
  min_score: 91,
  max_score: 100,
  no_of_candidates: 2808,
  percentage:47.6,
  color: "success",
  background: "table-success",
 },
 {
  rank_code: "very_good",
  rank_title: "Very Good",
  min_score: 71,
  max_score: 80,
  no_of_candidates: 1674,
  percentage:28.4,
  color: "info",
  background: "table-info",
 },
 {
  rank_code: "good",
  rank_title: "Good",
  min_score: 51,
  max_score: 70,
  no_of_candidates: 325,
  percentage:5.5,
  color: "primary",
  background: "table-primary",
 },
 {
  rank_code: "fair",
  rank_title: "Fair",
  min_score: 31,
  max_score: 50,
  no_of_candidates: 298,
  percentage:5.1,
  color: "secondary",
  background: "table-light",
 },
 {
  rank_code: "poor",
  rank_title: "Poor",
  min_score: 11,
  max_score: 30,
  no_of_candidates: 512,
  percentage:8.7,
  color: "warning",
  background: "table-warning",
 },
 {
  rank_code: "very_poor",
  rank_title: "Very Poor",
  min_score: 0,
  max_score: 10,
  no_of_candidates: 280,
  percentage:4.7,
  color: "danger",
  background: "table-danger",
 },
];
