export const programming_languages = [
 { title: "Assembly language (ASM)", category: "Programming Language", icon: "assets/icons/img/prog-languages/assembly-language-asm.png", status: "Enabled" },
 { title: "Bash", category: "Programming Language", icon: "assets/icons/img/prog-languages/bash.png", status: "Enabled" },
 { title: "BASIC", category: "Programming Language", icon: "assets/icons/img/prog-languages/basic.png", status: "Enabled" },
 { title: "C", category: "Programming Language", icon: "assets/icons/img/prog-languages/c.png", status: "Enabled" },
 { title: "C++ (C plus plus) – ISO/IEC 14882", category: "Programming Language", icon: "assets/icons/img/prog-languages/c-plus-plus.png", status: "Enabled" },
 { title: "C# (C sharp) – ISO/IEC 23270", category: "Programming Language", icon: "assets/icons/img/prog-languages/c-sharp.png", status: "Enabled" },
 { title: "Clojure", category: "Programming Language", icon: "assets/icons/img/prog-languages/clojure.png", status: "Enabled" },
 { title: "COBOL – ISO/IEC 1989", category: "Programming Language", icon: "assets/icons/img/prog-languages/cobol.png", status: "Enabled" },
 { title: "Common Lisp (also known as CL)", category: "Programming Language", icon: "assets/icons/img/prog-languages/common-lisp.png", status: "Enabled" },
 { title: "D (also known as dlang)", category: "Programming Language", icon: "assets/icons/img/prog-languages/d.png", status: "Enabled" },
 { title: "Elixir", category: "Programming Language", icon: "assets/icons/img/prog-languages/elixir.png", status: "Enabled" },
 { title: "Erlang", category: "Programming Language", icon: "assets/icons/img/prog-languages/erlang.png", status: "Enabled" },
 { title: "Executable UML", category: "Programming Language", icon: "assets/icons/img/prog-languages/executable-uml.png", status: "Enabled" },
 { title: "F# (F sharp)", category: "Programming Language", icon: "assets/icons/img/prog-languages/f-sharp.png", status: "Enabled" },
 { title: "Fortran – ISO/IEC 1539", category: "Programming Language", icon: "assets/icons/img/prog-languages/fortran.png", status: "Enabled" },
 { title: "Go", category: "Programming Language", icon: "assets/icons/img/prog-languages/go.png", status: "Enabled" },
 { title: "Groovy (by Apache)", category: "Programming Language", icon: "assets/icons/img/prog-languages/groovy-by-apache.png", status: "Enabled" },
 { title: "Haskell", category: "Programming Language", icon: "assets/icons/img/prog-languages/haskell.png", status: "Enabled" },
 { title: "Java", category: "Programming Language", icon: "assets/icons/img/prog-languages/java.png", status: "Enabled" },
 { title: "JavaScript", category: "Programming Language", icon: "assets/icons/img/prog-languages/javascript.png", status: "Enabled" },
 { title: "Kotlin", category: "Programming Language", icon: "assets/icons/img/prog-languages/kotlin.png", status: "Enabled" },
 { title: "Lua", category: "Programming Language", icon: "assets/icons/img/prog-languages/lua.png", status: "Enabled" },
 { title: "Objective-C", category: "Programming Language", icon: "assets/icons/img/prog-languages/objective-c.png", status: "Enabled" },
 { title: "OCaml", category: "Programming Language", icon: "assets/icons/img/prog-languages/ocaml.png", status: "Enabled" },
 { title: "Octave", category: "Programming Language", icon: "assets/icons/img/prog-languages/octave.png", status: "Enabled" },
 { title: "Pascal – ISO 7185", category: "Programming Language", icon: "assets/icons/img/prog-languages/pascal.png", status: "Enabled" },
 { title: "Perl", category: "Programming Language", icon: "assets/icons/img/prog-languages/perl.png", status: "Enabled" },
 { title: "PHP", category: "Programming Language", icon: "assets/icons/img/prog-languages/php.png", status: "Enabled" },
 { title: "Prolog", category: "Programming Language", icon: "assets/icons/img/prog-languages/prolog.png", status: "Enabled" },
 { title: "Python", category: "Programming Language", icon: "assets/icons/img/prog-languages/python.png", status: "Enabled" },
 { title: "R", category: "Programming Language", icon: "assets/icons/img/prog-languages/r.png", status: "Enabled" },
 { title: "Ruby", category: "Programming Language", icon: "assets/icons/img/prog-languages/ruby.png", status: "Enabled" },
 { title: "Rust", category: "Programming Language", icon: "assets/icons/img/prog-languages/rust.png", status: "Enabled" },
 { title: "Scala", category: "Programming Language", icon: "assets/icons/img/prog-languages/scala.png", status: "Enabled" },
 { title: "SQL", category: "Programming Language", icon: "assets/icons/img/prog-languages/sql.png", status: "Enabled" },
 { title: "Swift (Apple programming language)", category: "Programming Language", icon: "assets/icons/img/prog-languages/swift-apple-programming-language.png", status: "Enabled" },
 { title: "TypeScript", category: "Programming Language", icon: "assets/icons/img/prog-languages/typescript.png", status: "Enabled" },
];
