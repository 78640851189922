import React, { useEffect, useState } from "react";
 
const QuestionStatusCalenders = ({ questionsCount, type, currentQuestionData }) => {
  let totalQuesions=[];
  const [dataArray, setDataArray] = useState([]);
 
  const onClickCurrentQues = (index) => {
    // Add logic to handle the click event, if needed
  };

  if(type === "mcq"){
    totalQuesions = Array.from(
      { length: questionsCount },
      (_, index) => index + 1
    );
  }
  if(type === "coding"){
    totalQuesions = Array.from(
      { length: questionsCount },
      (_, index) => index + 1
    );
  }
  if(type === "video"){
    totalQuesions = Array.from(
      { length: questionsCount },
      (_, index) => index + 1
    );
  }
 
  useEffect(() => {
     if(type === 'mcq'){
      if (currentQuestionData) {
        // Update the array with the currentQuestionData
        setDataArray((prevArray) => [...prevArray, currentQuestionData]);
      }
     }
   
     if(type === 'coding'){
      if (currentQuestionData) {
        // Update the array with the currentQuestionData
        setDataArray((prevArray) => [...prevArray, currentQuestionData]);
      }
     }
     if(type === 'video'){
      if (currentQuestionData) {
        // Update the array with the currentQuestionData
        setDataArray((prevArray) => [...prevArray, currentQuestionData]);
      }
     }
   
 
    // Use newArray as needed, e.g., logging it
  }, [currentQuestionData]);
 
  return (
    <>
    {type === "mcq" && (<>
      {/* <h5 className="font-size-14">Multiple Choice Questions</h5> */}
      <div className="d-flex flex-wrap gap-2">
        {totalQuesions.map((question, index) => {
          const isAttempted = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "attempted"
          );
          const isSkipped = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "skipped"
          );
 
 
          // Check if a matching record is found and its status is 'attempted'
          // const isAttempted = matchingRecord;
 
          // Define the className based on the condition
          const className = `badge ${isAttempted ? "bg-primary" : isSkipped ? "bg-warning bg-gradient text-black" : "bg-secondary-subtle text-muted"
            } rounded-circle avatar-s font-size-10 p-1`;
          return (
           
            <span key={index} onClick={() => onClickCurrentQues(index+1)} className={className}>
              {question}
            </span>
          );
        })}
 
      </div></>)}
    {type === "coding" && (<>
      {/* <h5 className="font-size-14">Coding Questions</h5> */}
      <div className="d-flex flex-wrap gap-2">
        {totalQuesions.map((question, index) => {
          const isAttempted = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "attempted"
          );
          const isSkipped = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "skipped"
          );
 
 
          // Check if a matching record is found and its status is 'attempted'
          // const isAttempted = matchingRecord;
 
          // Define the className based on the condition
          const className = `badge ${isAttempted ? "bg-primary" : isSkipped ? "bg-warning bg-gradient text-black" : "bg-secondary-subtle text-muted"
            } rounded-circle avatar-s font-size-10 p-1`;
          return (
 
            <span key={index} className={className}>
              {question}
            </span>
          );
        })}
 
      </div></>)}
    {type === "video" && (<>
      {/* <h6>Behavioural Questions</h6> */}
      <div className="d-flex flex-wrap gap-2">
        {totalQuesions.map((question, index) => {
          const isAttempted = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "attempted"
          );
          const isSkipped = dataArray.find(
            (record) => record.questionIndex === index && record.questionStatus === "skipped"
          );
 
 
          // Check if a matching record is found and its status is 'attempted'
          // const isAttempted = matchingRecord;
 
          // Define the className based on the condition
          const className = `badge ${isAttempted ? "bg-primary" : isSkipped ? "bg-warning bg-gradient text-black" : "bg-secondary-subtle text-muted"
            } rounded-circle avatar-s font-size-10 p-1`;
          return (
 
            <span key={index} className={className}>
              {question}
            </span>
          );
        })}
 
      </div></>)}
     
    </>
  );
};
 
export default QuestionStatusCalenders;