export const salesforce = [
 { title: "Sales Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Service Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Marketing Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Commerce Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Experience Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Analytics Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Apex", category: "Programming Language", icon: "assets/icons/img/salesforce/apex.png", status: "" },
 { title: "LWC", category: "Framework", icon: "assets/icons/img/salesforce/lwc.png", status: "" },
 { title: "Community Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Health Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Financial Services Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Government Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/government-cloud.png", status: "" },
 { title: "Not-for-Profit Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/saleforce.png", status: "" },
 { title: "Education Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/education-cloud.png", status: "" },
 { title: "Data Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Industry Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Einstein", category: "Platform / Tool", icon: "assets/icons/img/salesforce/einstein.png", status: "" },
 { title: "Net Zero Cloud", category: "Platform / Tool", icon: "assets/icons/img/salesforce/net-zero-cloud.png", status: "" },
 { title: "Visualforce Markup Language", category: "Programming Language", icon: "assets/icons/img/salesforce/visualforce-markup-language.png", status: "" },
 { title: "SOQL ", category: "Programming Language", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "SOSL", category: "Programming Language", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
//  { title: "Aura Framework", category: "Framework", icon: "assets/icons/img/salesforce/aura-framework.png", status: "" },
 { title: "Mulesoft", category: "Platform / Tool", icon: "assets/icons/img/salesforce/mulesoft.png", status: "" },
 { title: "Salesforce Admin", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Data Loader", category: "Platform / Tool", icon: "assets/icons/img/salesforce/download.png", status: "" },
 { title: "AppExchange", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
//  { title: "Integration", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Salesforce Reports and Dashboards", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Salesforce CPQ", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "LMA", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Journey Builder", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
 { title: "Email Template Builder", category: "Platform / Tool", icon: "assets/icons/img/salesforce/salesforce.png", status: "" },
];
