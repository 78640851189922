import React, { useEffect, useRef, useState } from 'react'
import { Modal, Row, Col, Label,Card,CardBody,Spinner,Alert } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import { assessmentsession_steps } from '../../config/dev';
import { useDispatch } from "react-redux";
import { setRoleBasedJob } from '../../store/informationSlice';


const Permission = ({ assessmentMapData, isModalActive, onToggleModal,onSkillTest }) => {
  const [isAvailable, setIsAvailable] = useState(false);

  const handleCheckboxChange1 = (event) => {
    setIsAvailable(event.target.checked);
  };

  const dispatch = useDispatch();
  // let assess_str = useSelector(getAssessmentData);
  

  const mediaRef = useRef(null);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [permissionGranted , setPermissionGranted] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalActive) {
      requestMediaPermissions();
    }
    return () => {
      stopMediaPermissions();
    };
  }, [isModalActive])


  function handleCloseModal() {
    onToggleModal(!isModalActive);
    stopMediaPermissions();
    removeBodyCss();
  }
  
  const handleStepClick = (e) => {
    setLoading(true);
    e.preventDefault();
    onSkillTest();
    setTimeout(() => {
      stopMediaPermissions();
      dispatch(setRoleBasedJob({}));
    }, 2000);
  }

  const handleCheckboxChange = (event) => {
    setTermsAndConditions(event.target.checked); // Enable/disable button based on checkbox state
  };

  const requestMediaPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (mediaRef.current) {
        mediaRef.current.srcObject = stream;
        setPermissionGranted(true);
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      setPermissionDenied(true);
    }
  };

  const stopMediaPermissions = () => {
    if (mediaRef.current) {
      const stream = mediaRef.current.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        setPermissionGranted(false);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <div>
      <Modal
        size="xl"
        isOpen={isModalActive}
        toggle={() => {
          handleCloseModal();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 text-primary" id="myExtraLargeModalLabel">
            Permission&nbsp;&nbsp;
            <span className="text-muted mb-3 font-size-10">Permissions are mandatory for the Assessment to start</span>
          </h5>
          <button
            onClick={() => {
              handleCloseModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{zoom: '95%'}}>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <div>
              <Col lg={8} md={8} xs={12}>
                <h5 className="font-size-14 text-primary">Questions <span className="font-size-10 text-muted">Please answer these questions to help us serve you better</span></h5>
                <div className="d-flex form-switch mb-2 ps-2">
                  <Label className="form-check-label font-size-12" htmlFor="customSwitch1">Are you available immediately for joining?</Label>
                  <input type="checkbox" className="form-check-input ms-2 border-secondary" id="customSwitch1" checked={isAvailable}
                    onChange={handleCheckboxChange1} />
                </div>

                <div className="d-flex form-switch mb-2 ps-2">
                  <Label className="form-check-label font-size-12" htmlFor="customSwitch1">Are you willing to take a part-time role?</Label>
                  <input type="checkbox" className="form-check-input ms-2 border-secondary" id="customSwitch1" />
                </div>
                </Col>
              </div>

              <div>
                <h5 className="font-size-14 text-primary mt-5">Guidelines <span className="font-size-10 text-muted">Please read these carefully</span></h5>
                <ul className="list-unstyled mb-3">
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">Do Not Switch Tabs between Tests.</span></li>
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">Do Not Copy-Paste Code.</span></li>
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">Maintain Eye Contact with Camera.</span></li>
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">Cheating or plagiarism during the test will result in immediate disqualification.</span></li>
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">All test content and materials are protected by copyright and may not be used or distributed without permission.</span></li>
                  <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><span className="font-size-11">We reserve the right to modify these terms and conditions at any time.</span></li>
                </ul>
              </div>
              <div className="form-check mt-5">
                <input
                  className="form-check-input border-secondary"
                  type="checkbox"
                  id="formCheck1"
                  disabled={!permissionGranted}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label font-size-12" htmlFor="formCheck1">
                By participating, you consent to the collection and use of your data in accordance with our <Link to="https://www.talentiq.app/terms">Terms & Conditions</Link> and <Link to="https://www.talentiq.app/privacy">Privacy Policy</Link>.
                </label>

                <div className='mt-2'>
                <Link role="button" to="#" className={`mt-3 btn btn-primary btn-sm waves-effect waves-light p-2 ${(termsAndConditions || loading) ? '' : 'disabled'}`} onClick={(e) => handleStepClick(e)}> 
                Start the Assessment
                {loading ? (<i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 py-1"></i>):(<i className="fas fa-arrow-right align-middle ms-1"></i>)}
                </Link>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <div className='mb-3 font-size-13'>
               {permissionGranted ? (<i className='mdi mdi-camera font-size-12 text-primary'></i>) : ( <span><Spinner className="me-1 spinner-border-sm" color="primary" /></span>)} Camera&nbsp;&nbsp;
               {permissionGranted ? (<i className='mdi mdi-microphone font-size-12 text-primary'></i>) : ( <span><Spinner className="me-1 spinner-border-sm" color="primary" /></span>)} Microphone
              </div>
               
              <div>
                {permissionDenied ? (
                   <Alert color="danger" className="alert-label-icon label-arrow rounded-2">
                        {/* <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i> */}
                        <i className="mdi mdi-alert-circle-outline label-icon"></i>
                        Camera access denied. Please grant permission to proceed.
                    </Alert> 
                  // <p>Camera access denied. Please grant permission to proceed.</p>
                ) : (
                  <Card>
                  <CardBody className='p-2'>
                      <h5 className="font-size-14 mb-2 text-primary">Private Preview <span className="text-muted mb-3 font-size-10">This is only visible to you</span></h5>
                        <video className="w-100 rounded" ref={mediaRef} autoPlay playsInline muted />
                  </CardBody>
              </Card>
                )}
              </div>
            </Col>
          </Row>
          
        </div>
      </Modal>
    </div>
  )
}

export default Permission