export const analytics = [
 { title: "Tableau", category: "Platform / Tool", icon: "assets/icons/img/analytics/tableau.png", status: "" },
 { title: "Splunk", category: "Platform / Tool", icon: "assets/icons/img/analytics/splunk.png", status: "" },
 { title: "Talend", category: "Platform / Tool", icon: "assets/icons/img/analytics/talend.png", status: "" },
 { title: "KNIME", category: "Platform / Tool", icon: "assets/icons/img/analytics/knime.png", status: "" },
 { title: "RapidMiner", category: "Platform / Tool", icon: "assets/icons/img/analytics/rapidminer.png", status: "" },
 { title: "Microsoft Excel", category: "Platform / Tool", icon: "assets/icons/img/analytics/microsoft-excel.png", status: "" },
 { title: "R", category: "Programming Language", icon: "assets/icons/img/analytics/r.png", status: "" },
 { title: "Python", category: "Programming Language", icon: "assets/icons/img/analytics/python.png", status: "" },
 { title: "SQL", category: "Database", icon: "assets/icons/img/analytics/sql.png", status: "" },
 { title: "MATLAB", category: "Programming Language", icon: "assets/icons/img/analytics/matlab.png", status: "" },
 { title: "Scala", category: "Programming Language", icon: "assets/icons/img/analytics/scala.png", status: "" },
 { title: "D3", category: "Framework", icon: "assets/icons/img/analytics/d3.png", status: "" },
 { title: "Matplotlib", category: "Framework", icon: "assets/icons/img/analytics/matplotlib.png", status: "" },
 { title: "Seaborn", category: "Framework", icon: "assets/icons/img/analytics/seaborn.png", status: "" },
 { title: "Scikit-Learn", category: "Framework", icon: "assets/icons/img/analytics/scikit-learn.png", status: "" },
 { title: "Numpy", category: "Framework", icon: "assets/icons/img/analytics/numpy.png", status: "" },
 { title: "Pandas", category: "Framework", icon: "assets/icons/img/analytics/pandas.png", status: "" },
 { title: "SaS", category: "SaaS", icon: "assets/icons/img/analytics/sas.png", status: "" },
 { title: "Scipy", category: "Framework", icon: "assets/icons/img/analytics/scipy.png", status: "" },
 { title: "RStudio", category: "Platform / Tool", icon: "assets/icons/img/analytics/rstudio.png", status: "" },
 { title: "Trifacta", category: "Platform / Tool", icon: "assets/icons/img/analytics/trifacta.png", status: "" },
 { title: "Google Analytics", category: "Platform / Tool", icon: "assets/icons/img/analytics/google-analytics.png", status: "" },
 { title: "Adobe Analytics", category: "Platform / Tool", icon: "assets/icons/img/analytics/adobe-analytics.png", status: "" },
 { title: "Microsoft Power BI", category: "Platform / Tool", icon: "assets/icons/img/analytics/microsoft-power-bi.png", status: "" },
 { title: "IBM Cognos", category: "Platform / Tool", icon: "assets/icons/img/analytics/ibm-cognos.png", status: "" },
 { title: "QlikView", category: "Platform / Tool", icon: "assets/icons/img/analytics/qlikview.png", status: "" },
 { title: "SAS Analytics", category: "Platform / Tool", icon: "assets/icons/img/analytics/sas-analytics.png", status: "" },
 { title: "Apache Hadoop", category: "Platform / Tool", icon: "assets/icons/img/analytics/apache-hadoop.png", status: "" },
 { title: "Apache Spark", category: "Platform / Tool", icon: "assets/icons/img/analytics/apache-spark.png", status: "" },
 { title: "Snowflake", category: "Platform / Tool", icon: "assets/icons/img/analytics/snowflake.png", status: "" },
 { title: "Alteryx", category: "Platform / Tool", icon: "assets/icons/img/analytics/alteryx.png", status: "" },
 { title: "Orange", category: "Platform / Tool", icon: "assets/icons/img/analytics/orange.png", status: "" },
 { title: "Weka", category: "SaaS", icon: "assets/icons/img/analytics/weka.png", status: "" },
 { title: "Julia", category: "Programming Language", icon: "assets/icons/img/analytics/julia.png", status: "" },
 { title: "SPSS", category: "Platform / Tool", icon: "assets/icons/img/analytics/spss.png", status: "" },
 { title: "OpenRefine", category: "Platform / Tool", icon: "assets/icons/img/analytics/openrefine.png", status: "" },
 { title: "SQLAlchemy", category: "Platform / Tool", icon: "assets/icons/img/analytics/sqlalchemy.png", status: "" },
 { title: "Lucidchart", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Elasticsearch", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Logstash", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Kibana", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
];
