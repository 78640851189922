import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Card,CardHeader,Alert, CardBody } from 'reactstrap'
import { ep,assessmentsession_steps } from '../../config/dev'
import { api_post } from '../../services/service'
import VideoTest from '../../components/assessment/VideoTest'
// import Feedback from '../../components/modal/Feedback'
import Feedback from '../../components/Feedback'
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../store/snackbarSlice";
import { setTimerData } from '../../store/timerSlice'

const StepBehaviouralTest = ({data,toggleCurrentStep}) => {

  const currentTab = `ⓘ Participate in the video assessment to showcase your skills and personality. This is your chance to shine and impress potential employers.`;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTab(currentTab));
  }, []);

  const [currAssessmentSession, setCurrAssessmentSession] = useState({});
  const [bindAssessmentQuestionVideo, setBindAssessmentQuestionVideo] = useState([]);
  const [bindVideoStatusArray, setBindVideoStatusArray] = useState([]);
  const [currentSkillTestIndex, setCurrentSkillTestIndex] = useState(0);
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);

    useEffect(() => {
      handleVideoTest();
    }, [currAssessmentSession])
    
    useEffect(() => { 
      setCurrAssessmentSession(data.assessmentSessionData);   
    }, [data.assessmentSessionData]);
    
    function filterNewAssessmentQuestions(list1, list2, isUnion) {
      var result = [];
      for (var i = 0; i < list1.length; i++) {
       var item1 = list1[i],
        found = false;
      for (var j = 0; j < list2.length && !found; j++) {
        found = item1.guid === list2[j].guid;
      }
      if (found === !!isUnion) {
        // isUnion is coerced to boolean
        result.push(item1);
       }
      }
      result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      return result;
     }

    const handleVideoTest = ()=>{
      // if(data?.assessmentSessionData?.video?.questions)  {
        if(currAssessmentSession && currAssessmentSession.video){
        const sortedVideoQuestions = [...data.assessmentSessionData.video.questions].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const newAssessmentQuestionVideo = filterNewAssessmentQuestions(sortedVideoQuestions, bindAssessmentQuestionVideo);
        setBindAssessmentQuestionVideo((prev) => [...prev, ...newAssessmentQuestionVideo]);
        const mcqStatusArray = newAssessmentQuestionVideo.map((question) => ({...question, status: ""}));
        setBindVideoStatusArray((prev) => [...prev, ...mcqStatusArray]);
      }    
    }

    const handleTIQScore = () =>{
      toggleCurrentStep(assessmentsession_steps.steptalentiqscore.code);
    }

    const handleVideoTransition = (questionIndex) =>{
      setCurrentSkillTestIndex(questionIndex);

      if( questionIndex === bindAssessmentQuestionVideo.length){
        afterVideoCompletion();
        setIsFeedbackActive(!isFeedbackActive);
      }
    }

    function afterVideoCompletion(){
      dispatch(setTimerData(0));
      const assesmentRequestBody = {
        assessment: {
          status: "completed",
          completedAt: new Date().toISOString(),
         },
      }
  
      const assesmentSessionRequestBody = {
        assessmentsession: {
         status: "video-completed",
         completedAt: new Date().toISOString(),
        },
       };      
      api_post(null, ep.assessment + "/v1/assessment/" + data.assessmentSessionData.video_assessment_guid, assesmentRequestBody, (_videoerror, _videoresponse) => {
  
      });
    
      api_post(null, ep.assessment + "/v1/assessmentsession/" + data.guid, assesmentSessionRequestBody, (assess_sess_error, assess_sess_response) => {
        if (assess_sess_error) {
         console.error(assess_sess_error);
        } else {
        //  log_debug("StepTalentIQScore", "api_post()", "assessment session MCQ update :: " + JSON.stringify(responseData));
        }
       });
      //  handleStepClick();
    }
  

  //   const handleAskFeedback = (e)=>{
  //     if(!isFeedbackActive){
  //         e.preventDefault();
  //     }
  //     setIsFeedbackActive(!isFeedbackActive);
  // }

  return (
    <>
      <Card className="mt-2 border border-2">
        <CardBody>
          {/* <Alert color="info" className="alert-label-icon label-arrow mb-0">
            <i className="mdi mdi-alert-circle-outline label-icon"></i>Ready to take the next step towards your dream job? Participate in the video assessment to showcase your skills and personality. This is your chance to shine and impress potential
            employers. Completing the video assessment can significantly boost your chances of getting selected and landing that dream job you've always wanted. Don't miss out on this exciting
            opportunity! 🎥🚀🌟
          </Alert> */}
           { 
           currentSkillTestIndex < bindAssessmentQuestionVideo.length ? (
             <VideoTest data={{questions:bindAssessmentQuestionVideo}} videoQuestionTransition={handleVideoTransition}/>
           ):(
             <div className='mt-2'>
               { isFeedbackActive && (
              <Feedback data={data} onTalentIQScore={handleTIQScore} ></Feedback>)}
            {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-end p-2" onClick={(e) => handleAskFeedback(e)}> Feedback <i className="fas fa-arrow-right align-middle ms-1"></i></Link> */}
            {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-end p-2" onClick={(e) => handleStepClick(e)}> Go To {assessmentsession_steps.steptalentiqscore.title} <i className="fas fa-arrow-right align-middle ms-1"></i></Link> */}
          </div>
            )}
          
        </CardBody>
      </Card>
      {/* { isFeedbackActive && (
      <Feedback data={data} onTalentIQScore={handleTIQScore} ></Feedback>
      <Feedback data={data} isModalActive={isFeedbackActive} onTalentIQScore={handleTIQScore} onToggleModal={handleAskFeedback}></Feedback>
      )} */}
    </>
  );
};

export default StepBehaviouralTest;
