import React, { useEffect,useState } from 'react'
import { Card,CardBody,Alert,Row, Col,Table,Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ep,tableData,getScoreLabel } from '../../config/dev'
import { log_debug } from '../../helpers/logger'
import { api_post,api_get } from '../../services/service'
import Doughnut from '../../common/charts/Doughnut';  
import DoughnutSmooth from '../../common/charts/DoughnutSmooth'

import { useDispatch,useSelector } from "react-redux";
import { setSelectedTab } from "../../store/snackbarSlice";
import { getuserData } from '../../store/userSlice'
import { primaryShade1 } from "../../config/dev";
import { secondaryShade2 } from "../../config/dev";
import { color } from "echarts";

import LearnMoreMcq from '../../components/sliders/LearnMoreMcq'
import LearnMoreCoding from '../../components/sliders/LearnMoreCoding'
import LearnMoreVideo from '../../components/sliders/LearnMoreVideo'

const customStyles = {
  backgroundColor: primaryShade1,
  color: "white",
};

const StepTalentIQScore = ({data}) => {
  const currentTab = `Congratulations! 🎉 You've successfully completed all the assessments. Your hard work has paid off!`;

  const dispatch = useDispatch();
  const user_data = useSelector(getuserData);
  const [loading, setLoading] = useState(false);

  const [candidate, setCandidate] = useState(null);
  const [isMcq, setIsMcq] = useState(false);
  const [isCoding, setIsCoding] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [mcqGuid, setMcqGuid] = useState(null);
  const [codingGuid, setCodingGuid] = useState(null);
  const [videoGuid, setVideoGuid] = useState(null);



  useEffect(() => {
    dispatch(setSelectedTab(currentTab));
  }, []);

  useEffect(() => {
    setCandidate(user_data?.candidate || null);
  }, [user_data.candidate]);

  const [scoreData, setScoreData] = useState(null);

  function fetchData() {
    api_get(null, ep.assessment + "/v1/assessmentsession/" + data.guid, (_assessmentsesserror, _assessmentsessscore) => {
      if (_assessmentsesserror) {
      } else {
        log_debug("StepTalentIQScore", "fetch_data()", "_assessmentsessscore :: " + JSON.stringify(_assessmentsessscore));
        api_get(null, ep.assessment + "/v1/assessmentsession/" + data.guid, (_assessmentsesserror, _assessmentsessdata) => {
          if (_assessmentsesserror) {
            console.error("API requestttt error:", _assessmentsesserror);
          } else {
            setScoreData(_assessmentsessdata.assessmentsession.score);
            api_get(null, ep.assessment + "/v1/assessment/assessmentsession/" + data.guid, (_assessmentsesserror, _assessmentsessdata) => {
              if (_assessmentsesserror) {
                console.error("API request error:", _assessmentsesserror);
              } else {
                const mcqAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "mcq")[0];
                const codingAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "coding")[0];
                const videoAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "behavioural")[0];
                setMcqGuid(mcqAssessment.guid);
                setCodingGuid(codingAssessment.guid);
                setVideoGuid(videoAssessment.guid);
                // setTimeout(() => {
                  
                // }, 500);
              }})

            // const mcqAssessment = _assessmentsessdata.assessmentsession.filter((assessment) => assessment.type === "mcq")[0];
            // const codingAssessment = _assessmentsessdata.assessmentsession.filter((assessment) => assessment.type === "coding")[0];
            // const videoAssessment = _assessmentsessdata.assessmentsession.filter((assessment) => assessment.type === "behavioural")[0];

          }
        })
        //  assessmentSession = _assessmentsessscore;
        //  talentiq_score = _assessmentsessscore.assessmentsession.score.tiq_score.toFixed(1);
        //  mcqMaxScore = _assessmentsessscore.assessmentsession.score.mcq.max_score;
        //  mcqObtainScore = _assessmentsessscore.assessmentsession.score.mcq.score;
        //  mcq_score = ((_assessmentsessscore.assessmentsession.score.mcq.score / _assessmentsessscore.assessmentsession.score.mcq.max_score) * 100).toFixed(0);
        //  codingMaxScore = _assessmentsessscore.assessmentsession.score.coding.max_score;
        //  codingObtainScore = _assessmentsessscore.assessmentsession.score.coding.score;
        //  coding_score = ((_assessmentsessscore.assessmentsession.score.coding.score / _assessmentsessscore.assessmentsession.score.coding.max_score) * 100).toFixed(0);
        //  behaviouralMaxScore = _assessmentsessscore.assessmentsession.score.behavioural.max_score;
        //  behaviouralObtainScore = _assessmentsessscore.assessmentsession.score.behavioural.score;
        //  behavioural_score = ((_assessmentsessscore.assessmentsession.score.behavioural.score / _assessmentsessscore.assessmentsession.score.behavioural.max_score) * 100).toFixed(0);
      }
    })
  }
  
  useEffect(() => { 
    fetchData();
    const requestBody = {
      assessmentsession: {
       status: "completed",
       completedAt: new Date().toISOString(), // Convert the date to ISO format
      },
     };
     api_post(null, ep.assessment + "/v1/assessmentsession/" + data.guid, requestBody, (error, responseData) => {
      if (error) {
       console.error(error);
      } else {
       log_debug("StepTalentIQScore", "api_post()", "assessment session status update :: " + JSON.stringify(responseData));
      }
     }); 
  }, [])
  

  const handleDownloadCerti = (e) => {
    e.preventDefault();
    setLoading(true);
    // const requestBody = {
    //   certificate:{
    //       "assessment_session_guid":""
    //   }
    // };
    const requestBody = {
        certificate : {
          assessment_session_guid : data.guid
        }
    }
    // setIsCertificateDwonloaded(true);
    // requestBody.certificate.assessment_session_guid =
      // candidate_assmnt_sess_details.guid;
    // let globalIndex=index;
    api_post( null, ep.document_page + "/v1/certificate",requestBody,(error, responseData) => {
        if (responseData) {
          // window.location.href = responseData.certificate.s3_url
          setLoading(false);
          window.open(responseData.certificate.s3_url, "_blank");
        } else {}
      },
    );
  };

  const handleHome = () => {
    window.location.href = "https://talentiq.app";
  };
  const handleLearnMore = (type) => {
    if(type === 'mcq'){
        setIsMcq(!isMcq);
    }
    if(type === 'coding'){
        setIsCoding(!isCoding);
    }
    if(type === 'video'){
        setIsVideo(!isVideo);
    }
  };
  const handleLearnCoding = () => {

  };
  const handleLearnVideo = () => {

  };
  return (
    <>
      <Card className="mt-2 border border-2">
        <CardBody>
          {/* <Alert color="info" className="alert-label-icon label-arrow mb-0">
            <i className="mdi mdi-alert-circle-outline label-icon"></i>Congratulations! 🎉 You've successfully completed all the assessments. Your hard work has paid off! Below, you'll find your assessment results. Take a moment to review and celebrate your
              achievements. Your dedication and skills have brought you one step closer to your goals. Keep up the great work! 🎥🚀🌟
          </Alert> */}
          <Row>
            <Col lg={7}>
                        <h5 className="mb-1 font-size-14 text-black">Hi, {candidate?.full_name}. Your Score Card.</h5>
                        {scoreData?.tiq_score && (
                          <div className="faq-count mb-0 d-flex">
                              {/* <h1 className={`${getScoreLabel(scoreData?.tiq_score.toFixed(1)).class} text-primary display-5 m-0 p-1`}>{scoreData?.tiq_score}<span className="text-muted font-size-18">/10</span></h1> */}
                              <h1 className={`${getScoreLabel(scoreData?.tiq_score).class} text-primary display-5 m-0 p-1`}>{scoreData?.tiq_score.toFixed(2)}<span className="text-muted font-size-18">/100</span></h1>
                              {/* <span class="{getScoreLabel(talentiq_score).class}">
                              {getScoreLabel(talentiq_score).label}
                            </span> */}
                              <p className={`${getScoreLabel(scoreData?.tiq_score).class} font-size-12 ms-4 mt-4`}>{getScoreLabel(scoreData?.tiq_score).label}</p>
                            </div>
                            )}
                            <h5 className='card-title mt-0 font-size-12'>PERFORMANCE SCORE</h5>
                            <p className='text-muted font-size-12 mt-0 mb-2'>Here's how you performed in the assessment compared to other candidates:</p>
                            <div className="mb-3">
                            <Progress multi>
                              {tableData.map((rowData, index) => (
                                <Progress key={index} bar color={rowData.color} value={rowData.percentage}></Progress>
                              ))}
                            </Progress>
                            </div>
                            <Card>      
                        <CardBody className='p-0'>
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead className='font-size-13'>
                                  <tr>
                                    <th>Rank</th>
                                    <th>Min. Score</th>
                                    <th>Max. Score</th>
                                    <th># of Candidate</th>
                                    <th>% of Candidate</th>
                                  </tr>
                                </thead>
                                <tbody className='font-size-13'>
                                {tableData.map((rowData, index) => (
                                  <tr key={index} className={`${rowData.background}`}>
                                    <th scope="row">{rowData.rank_title}</th>
                                    <td>{rowData.min_score}</td>
                                    <td>{rowData.max_score}</td>
                                    <td>{rowData.no_of_candidates}</td>
                                    <td>{rowData.percentage} %</td>
                                  </tr>
                                ))}
                                </tbody>
                                {/* <tbody>
                                  <tr className="table-light">
                                    <th scope="row">Excellent</th>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>2048</td>
                                    <td>48.2%</td>
                                  </tr>
                                </tbody> */}
                              </Table>
                            </div>
                                </CardBody>
                              </Card>
                               
                                <div className='mt-2'>
                                <button type="button" className="btn btn-primary btn-sm waves-effect waves-light float-start p-2 me-3" onClick={handleHome}><i className="mdi mdi-home align-middle ms-1"></i> Go To Home</button>
                                {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-start p-2" disabled onClick={(e) => handleDownloadCerti(e)}>
                                 {loading ? (<i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 py-1"></i>):(<i className="mdi mdi-download align-middle ms-1"></i>)} Download Certificate
                                </Link> */}
                                <Link to="#" className={`btn btn-primary btn-sm waves-effect waves-light float-start p-2 ${loading ? 'disabled' : ''}`} onClick={(e) => !loading && handleDownloadCerti(e)}>
                                  {loading ? (<i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 py-1"></i>) : (<i className="mdi mdi-download align-middle ms-1"></i>)} Download Certificate
                                </Link>
                              </div>
                            </Col>
                          <Col lg={5}>
                            <Card className="bg-gradient mb-3">
                                <CardBody className='p-0'>
                                  <div className='align-items-start row'>
                                    <div className='col-sm p-0 ms-2'>
                                      {/* <Doughnut outOf={scoreData?.mcq?.max_score} score={scoreData?.mcq?.score}/> */}
                                      <DoughnutSmooth value={scoreData?.mcq?.score} total={scoreData?.mcq?.max_score} />
                                     </div> 
                                      <div className='col-sm mx-auto mt-2'>
                                        <h5 className="mt-3 font-size-14 text-primary">Multiple Choice Questions</h5>
                                        <p className="text-muted font-size-12">See where your answers landed, understand the gaps</p>
                                        <button type='button' className="mt-1 mb-2 btn btn-outline-primary btn-sm waves-effect waves-light p-1" onClick={() => handleLearnMore('mcq')}><i className='mdi mdi-information-outline'></i> Learn More</button>
                                      </div>
                                  </div>
                                </CardBody>
                            </Card>
                            <Card className="bg-gradient mb-3">
                                <CardBody className='p-0'>
                                  <div className='align-items-start row'>
                                    <div className='col-sm p-0 ms-2'>
                                      {/* <Doughnut outOf={scoreData?.coding?.max_score} score={scoreData?.coding?.score}/> */}
                                      <DoughnutSmooth value={scoreData?.coding?.score} total={scoreData?.coding?.max_score} />
                                     </div> 
                                      <div className='col-sm mx-auto mt-2'>
                                        <h5 className="mt-3 font-size-14 text-primary">Coding Assessment</h5>
                                        <p className="text-muted font-size-12">Discover where your code went right and where it could be optimized</p>
                                        <button type='button' className="mt-1 mb-2 btn btn-outline-primary btn-sm waves-effect waves-light p-1" onClick={() => handleLearnMore('coding')}><i className='mdi mdi-information-outline'></i> Learn More</button>
                                      </div>
                                  </div>
                                </CardBody>
                            </Card>
                            <Card className="bg-gradient">
                                <CardBody className='p-0'>
                                  <div className='align-items-start row'>
                                    <div className='col-sm p-0 ms-2'>
                                    <DoughnutSmooth value={scoreData?.behavioural?.score} total={scoreData?.behavioural?.max_score} />
                                      {/* <Doughnut outOf={scoreData?.behavioural?.max_score} score={scoreData?.behavioural?.score}/> */}
                                     </div> 
                                      <div className='col-sm mx-auto mt-2'>
                                        <h5 className="mt-3 font-size-14 text-primary">Behavioural Questions</h5>
                                        <p className="text-muted font-size-12">Chedkout how you've performed in terms of Communication skills, cultural fit, etc.</p>
                                        <button type='button' className="mt-1 mb-2 btn btn-outline-primary btn-sm waves-effect waves-light p-1" onClick={() => handleLearnMore('video')}><i className='mdi mdi-information-outline'></i> Learn More</button>
                                      </div>
                                  </div>
                                </CardBody>
                            </Card>
                          </Col>
          </Row>
        </CardBody>
      </Card>
      {isMcq ? (
          <LearnMoreMcq isMcq={isMcq} toggleLearnMore = {(e) => { handleLearnMore('mcq')}} data={mcqGuid}/>
      ):( isCoding ? (
        <LearnMoreCoding isCoding={isCoding} toggleLearnMore = {(e) => { handleLearnMore('coding')}} data={codingGuid}/>
      ): isVideo && (
        <LearnMoreVideo isVideo={isVideo} toggleLearnMore = {(e) => { handleLearnMore('video')}} data={videoGuid}/>
      )
      )}
    </>
  );
};

export default StepTalentIQScore;
