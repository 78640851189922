import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentData } from "../../store/assessmentSlice";
import { setTimerData } from '../../store/timerSlice';
import { ep, assessmentsession_steps } from "../../config/dev";
import { api_get,api_post } from "../../services/service";
import MCQTest from "../../components/assessment/MCQTest";
import CodingTest from "../../components/assessment/CodeTest";
import { setSelectedTab } from "../../store/snackbarSlice";
import { getTimerData } from "../../store/timerSlice";

const StepSkillTest = ({ data, currAssessmentSession1, toggleCurrentStep }) => {
  const dispatch = useDispatch();
  const [retrieveOldAssessment, setRetrieveOldAssessment] = useState(false);
  const [currAssessmentSession, setCurrAssessmentSession] = useState({});
  const [bindAssessmentQuestionMCQ, setBindAssessmentQuestionMCQ] = useState([]);
  const [bindMCQStatusArray, setBindMCQStatusArray] = useState([]);
  const [bindAssessmentQuestionCoding, setBindAssessmentQuestionCoding] = useState([]);
  const [bindCodingStatusArray, setBindCodingStatusArray] = useState([]);
  const [bindAssessmentQuestionSkillTest, setBindAssessmentQuestionSkillTest] = useState([]);
  const [currentSkillTestIndex, setCurrentSkillTestIndex] = useState(0);
  const [mcqCompleted, setMcqCompleted] = useState(false);
  const [codingCompleted, setCodingCompleted] = useState(false);
  const assess_str = useSelector(getAssessmentData);
  const isTimeLimitOver = useSelector(getTimerData);
  const [roleSkills, setRoleSkills] = useState({
    role: '',
    primarySkills: [],
    secondarySkills: []
  });

  // assess_str && setRetrieveOldAssessment(false);

  // const currentTab = "ⓘ Please choose your relevant skills from the list below. Your selections will help us tailor your experience and provide you with the most relevant content and opportunities.";
  const currentTab = "⚡Great choices! We are assessing your skills through a set of multiple-choice and coding questions to gauge your expertise. Best of luck!";

  useEffect(() => {
    dispatch(setSelectedTab(currentTab));
  }, []);

  useEffect(() => {
    if( isTimeLimitOver === 0 ){
       setCurrentSkillTestIndex(bindAssessmentQuestionMCQ.length);
     afterMcqCompletion();
    }
}, [isTimeLimitOver])

  // if(!currAssessmentSession){
  //   setCurrAssessmentSession(data.assessmentSessionData);
  // }

  // useEffect(() => {
  //   if(currAssessmentSession){
  //     handleSkillTest();
  //   }

  // if(data && data.assessmentSessionData){
  //   setCurrAssessmentSession(data);

  //   // setCurrAssessmentSession(data);
  //   // fetchData();
  //   setRetrieveOldAssessment(false)
  // }else{
  //   // retrieve_assessment();
  //   setRetrieveOldAssessment(true);
  // }
  // assess_str?.guid ? setRetrieveOldAssessment(false) : setRetrieveOldAssessment(true);
  // return () => {

  // }
  // }, [currAssessmentSession])

  useEffect(() => {
    handleSkillTest();
      // setRoleSkills({
      //   role: data.assessmentSessionData.role || '',
      //   primarySkills: data.assessmentSessionData.role || [],
      //   secondarySkills: data.assessmentSessionData.role || []
      // });
  }, [currAssessmentSession]);

  useEffect(() => {
    setCurrAssessmentSession(data.assessmentSessionData);
  }, [data.assessmentSessionData]);

  function filterNewAssessmentQuestions(list1, list2, isUnion) {
    var result = [];
    for (var i = 0; i < list1.length; i++) {
      var item1 = list1[i],
        found = false;
      for (var j = 0; j < list2.length && !found; j++) {
        found = item1.guid === list2[j].guid;
      }
      if (found === !!isUnion) {
        // isUnion is coerced to boolean
        result.push(item1);
      }
    }
    result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return result;
  }

  const handleSkillTest = () => {
    let current_assess_data = data.assessmentSessionData;
    if (currAssessmentSession && currAssessmentSession.mcq) {
      // setMcqCompleted(currAssessmentSession.mcq.status === 'completed');
      const sortedMCQQuestions = [...currAssessmentSession.mcq.questions].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      const newAssessmentQuestionMCQ = filterNewAssessmentQuestions( sortedMCQQuestions,bindAssessmentQuestionMCQ);
      setBindAssessmentQuestionMCQ((prev) => [
        ...prev,
        ...newAssessmentQuestionMCQ,
      ]);
      setBindAssessmentQuestionSkillTest((prev) => [
        ...prev,
        ...newAssessmentQuestionMCQ,
      ]);
      const mcqStatusArray = newAssessmentQuestionMCQ.map((question) => ({...question,status: "",}));
      setBindMCQStatusArray((prev) => [...prev, ...mcqStatusArray]);

      // if (currentSkillTestIndex < currAssessmentSession.mcq.questions.length) {
      //   dispatch(setTimerData(currAssessmentSession.mcq.questions.length));
      // } else {
      //   dispatch(setTimerData(0));
      // }
    }
    if (currAssessmentSession && currAssessmentSession.coding) {
      setCodingCompleted(currAssessmentSession.coding === 'completed');
      // Handle Coding questions
      const sortedCodingQuestions = [
        ...currAssessmentSession.coding.questions,
      ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      const newAssessmentQuestionCoding = filterNewAssessmentQuestions(
        sortedCodingQuestions,
        bindAssessmentQuestionCoding
      );
      setBindAssessmentQuestionCoding((prev) => [
        ...prev,
        ...newAssessmentQuestionCoding,
      ]);
      setBindAssessmentQuestionSkillTest((prev) => [
        ...prev,
        ...newAssessmentQuestionCoding,
      ]);
      const codingStatusArray = newAssessmentQuestionCoding.map((question) => ({
        ...question,
        status: "",
      }));
      setBindCodingStatusArray((prev) => [...prev, ...codingStatusArray]);
    }

    // curr_assessmentsession.coding.questions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    // let new_assessmentquestion_coding = filter_new_assessment_questions(curr_assessmentsession.coding.questions, bind_assessmentquestion_coding);
    // bind_assessmentquestion_coding = bind_assessmentquestion_coding.concat(...new_assessmentquestion_coding);
    // bind_assessmentquestion_skilltest = bind_assessmentquestion_skilltest.concat(...new_assessmentquestion_coding);
    // bind_coding_status_array = bind_assessmentquestion_coding.map((question) => ({
    //   ...question, // Copy all fields from the original question object
    //   status: "", // Add the 'status' field and initialize with 'unanswered'
    // }));
  };

  const retrieve_assessment = () => {
    // api_get(null, ep.assessment + "/v1/assessmentsession/" + data.guid, (_assessmentsession_err, _assessmentsession_res) => {
    //   if (!_assessmentsession_err) {
    //   }
    //   });
    // api_get(null, ep.assessment + "/v1/assessment/assessmentsession/" + data.guid, (_assessmentsesserror, _assessmentsessdata) => {
    //   if (_assessmentsesserror) {
    //    console.error("API request error:", _assessmentsesserror);
    //   } else {
    //       const mcqAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "mcq")[0];
    //       const codingAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "coding")[0];

    //      api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + mcqAssessment.guid, (_mcq_err, _mcqResponse) => {
    //       if (_mcq_err) {
    //         console.error("API request error:", _mcq_err);
    //       }else{
    //       setCurrAssessmentSession((prev) => ({
    //         ...prev,
    //         mcq: {
    //           questions: _mcqResponse.assessmentquestions || [],
    //         },
    //       }));
    //       api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + codingAssessment.guid, (_coding_err, _codingResponse) => {
    //        setCurrAssessmentSession((prev) => ({
    //          ...prev,
    //          coding: {
    //            questions: _codingResponse.assessmentquestions || [],
    //          },
    //        }));
    //        handleSkillTest();
    //       })
    //       }
    //      })
    //   }
    // });
  };

  function afterMcqCompletion(){
    dispatch(setTimerData(0));
    const assesmentRequestBody = {
      assessment: {
        status: "completed",
        completedAt: new Date().toISOString(),
       },
    }

    const assesmentSessionRequestBody = {
      assessmentsession: {
       status: "mcq-completed",
       completedAt: new Date().toISOString(),
      },
     };

    api_post(null, ep.assessment + "/v1/assessment/" + data.assessmentSessionData.mcq_assessment_guid, assesmentRequestBody, (_mcqerror, _mcqresponse) => {

    });
  
    api_post(null, ep.assessment + "/v1/assessmentsession/" + data.guid, assesmentSessionRequestBody, (assess_sess_error, assess_sess_response) => {
      if (assess_sess_error) {
       console.error(assess_sess_error);
      } else {
      //  log_debug("StepTalentIQScore", "api_post()", "assessment session MCQ update :: " + JSON.stringify(responseData));
      }
     });
  }

  function afterCodingCompletion(){
    dispatch(setTimerData(0));
    const assesmentRequestBody = {
      assessment: {
        status: "completed",
        completedAt: new Date().toISOString(),
       },
    }

    const assesmentSessionRequestBody = {
      assessmentsession: {
       status: "coding-completed",
       completedAt: new Date().toISOString(),
      },
     };
        
    api_post(null, ep.assessment + "/v1/assessment/" + data.assessmentSessionData.coding_assessment_guid, assesmentRequestBody, (_codingerror, _codingresponse) => {

    });
  
    api_post(null, ep.assessment + "/v1/assessmentsession/" + data.guid, assesmentSessionRequestBody, (assess_sess_error, assess_sess_response) => {
      if (assess_sess_error) {
       console.error(assess_sess_error);
      } else {
      //  log_debug("StepTalentIQScore", "api_post()", "assessment session MCQ update :: " + JSON.stringify(responseData));
      }
     });
     handleStepClick();
     
  }

  const handleMcqTransition = (questionIndex) =>{
    setCurrentSkillTestIndex(questionIndex);
    if( questionIndex === bindAssessmentQuestionMCQ.length){
      setMcqCompleted(true);
      afterMcqCompletion();
      if(bindAssessmentQuestionCoding.length ===0){handleStepClick()}
    }
  }

  const handleCodingTransition = (questionIndex) => {
    setCurrentSkillTestIndex(bindAssessmentQuestionMCQ.length + questionIndex);
    if (bindAssessmentQuestionMCQ.length+questionIndex === bindAssessmentQuestionSkillTest.length) {
      afterCodingCompletion();
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  const handleStepClick = (e) => {
    // e.preventDefault();
    toggleCurrentStep(assessmentsession_steps.stepbehavioural.code);
  };

  return (
    <>
      <Card className="mt-2">
        <CardBody>
          {bindAssessmentQuestionMCQ && bindAssessmentQuestionCoding && (
            <Row>
              <Col xl={12} sm={6}>
            { currentSkillTestIndex < bindAssessmentQuestionMCQ.length && !mcqCompleted ? (
              <MCQTest data={{questions:bindAssessmentQuestionMCQ}} mcqquestiontransition={handleMcqTransition}/>
              ) :
            currentSkillTestIndex < (bindAssessmentQuestionMCQ.length + bindAssessmentQuestionCoding.length) && bindAssessmentQuestionCoding.length>0 && !codingCompleted ? (
              <CodingTest data={{questions:bindAssessmentQuestionCoding}} codingQuestionTransition={handleCodingTransition}/>
              ): currentSkillTestIndex === bindAssessmentQuestionMCQ.length + bindAssessmentQuestionCoding.length && (
              <div className='mt-2'>
              {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-end p-2" onClick={(e) => handleStepClick(e)}> Go To {assessmentsession_steps.stepbehavioural.title} <i className="fas fa-arrow-right align-middle ms-1"></i></Link> */}
            </div>
            )} 
            </Col>
          </Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default StepSkillTest;
