import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import { map } from "lodash";
import validator from 'validator'
import { validateStartEndDate } from '../../helpers/date'

const EditWorkExperience = ({ isWork, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditWorkExperience', 'edit work data' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState({
        resume: {
            work_experiences: [],
        },
    });

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    // Handler for input changes in the form
    const handleInputChange = (index, field, value) => {

        // Validation checks
        let updatedErrors = { ...validationErrors };
        let currentErrors = updatedErrors[index] || {};

        switch (field) {
            case 'designation':
                currentErrors[field] = validator.isEmpty(value) ? 'Designation is required' : '';
                break;
            case 'company_name':
                currentErrors[field] = validator.isEmpty(value) ? 'Company name is required' : '';
                break;
            case 'from_date':
                currentErrors[field] = validator.isEmpty(value) ? 'Start date is required' : '';
                break;
            case 'to_date':
                currentErrors[field] = validator.isEmpty(value) ? 'End date is required' : '';
                break;
            case 'project_details':
                currentErrors[field] = validator.isEmpty(value) ? 'Project details are required' : '';
                break;
            default:
                break;
        }

        updatedErrors[index] = currentErrors;
        setValidationErrors(updatedErrors);

        setEditedData((prevData) => {
            const updatedWorkExperiences = [...prevData.resume.work_experiences];

            if (updatedWorkExperiences[index]) {
                updatedWorkExperiences[index] = {
                    ...updatedWorkExperiences[index],
                    [field]: value,
                };
            }
            return {
                ...prevData,
                resume: {
                    ...prevData.resume,
                    work_experiences: updatedWorkExperiences,
                },
            };
        });
    };

    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    // Handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        editedData.resume.work_experiences.forEach((workExperience, index) => {
            // Initialize errors for the current work experience
            let experienceErrors = {};
            let project_details = workExperience.project_details || '';
            let company_name = workExperience.company_name || '';
            let designation = workExperience.designation || '';
            let from_date = workExperience.from_date || '';
            let to_date = workExperience.to_date || '';

            // Set errors for the current field in the current work experience
            experienceErrors['company_name'] = validator.isEmpty(company_name) ? 'Company name is required' : '';
            experienceErrors['designation'] = validator.isEmpty(designation) ? 'Designation is required' : '';
            experienceErrors['project_details'] = validator.isEmpty(project_details) ? 'Project details are required' : '';
            experienceErrors['from_date'] = validator.isEmpty(from_date) ? 'Start date is required' : '';
            experienceErrors['to_date'] = validator.isEmpty(to_date) ? 'End date is required' : '';

            if (!validator.isLength(project_details, { min: 0, max: 1000 })) {
                experienceErrors['project_details'] = 'Project details are Too large';
            }

            if (from_date) {
                if (!validator.isDate(dateyyyymmdd_tohtml(from_date))) {
                    experienceErrors['from_date'] = 'Please enter valid start date';
                }
            }

            if (to_date) {
                if (!validator.isDate(dateyyyymmdd_tohtml(to_date))) {
                    experienceErrors['to_date'] = 'Please enter valid end date';
                }
            }

            if (!validateStartEndDate(from_date, to_date)) {
                experienceErrors['from_date'] = 'Start Date can not be greater than End Date';
            }
            // Set errors for the current work experience in the main errors object
            errors[index] = experienceErrors;
        });

        setValidationErrors(errors);

        if (Object.values(errors).some((experienceErrors) => Object.values(experienceErrors).some((error) => error !== ''))) {
            // If there are validation errors, do not submit
            return;
        }

        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;

        //request body with the updated work experiences
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                work_experiences: editedData.resume.work_experiences,
            },
        };
        // API call to update the resume
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'responseData::' + JSON.stringify(responseData));
                // Invoke the onSubmit callback with the updated data
                onSubmit(editedData);
                // Close the Offcanvas
                toggleRightCanvas();
            }
        });
    };

    return (
        <>
            <Offcanvas
                isOpen={isWork}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
                style={{ zoom: '80%' }}
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Edit Work Experience
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                {map(editedData?.resume.work_experiences, (exp, index) => (
                                    <div key={exp._id}>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Designation <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input type="text" name='designation' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" value={exp?.designation || ''} onChange={(e) => handleInputChange(index, 'designation', e.target.value)} />
                                                {validationErrors[index]?.designation && (<small className="text-danger">{validationErrors[index]?.designation}</small>)}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Company Name <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input type="text" name='company_name' className="form-control" id="horizontal-firstname-input" placeholder="Enter Company Name" value={exp?.company_name || ''} onChange={(e) => handleInputChange(index, 'company_name', e.target.value)} />
                                                {validationErrors[index]?.company_name && <small className="text-danger">{validationErrors[index]?.company_name}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Start Date <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input className="form-control" name='from_date' type="date" id="horizontal-dob-input" value={exp?.from_date ? dateyyyymmdd_tohtml(exp.from_date) : ''} onChange={(e) => handleInputChange(index, 'from_date', e.target.value)} />
                                                {validationErrors[index]?.from_date && <small className="text-danger">{validationErrors[index]?.from_date}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">End Date <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input className="form-control" name='to_date' type="date" id="horizontal-dob-input" value={exp?.to_date ? dateyyyymmdd_tohtml(exp.to_date) : ''} onChange={(e) => handleInputChange(index, 'to_date', e.target.value)} />
                                                {validationErrors[index]?.to_date && <small className="text-danger">{validationErrors[index]?.to_date}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Project Details <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <textarea type="text" name='project_details' className="form-control" id="horizontal-firstname-input" placeholder="Enter Project Details" value={exp?.project_details || ''} onChange={(e) => handleInputChange(index, 'project_details', e.target.value)} />
                                                {validationErrors[index]?.project_details && <small className="text-danger">{validationErrors[index]?.project_details}</small>}
                                            </Col>
                                        </Row>
                                        <hr />

                                    </div>
                                ))}

                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EditWorkExperience