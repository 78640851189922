import { Routes, Route } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { userRoutes } from "./routes/allRoutes";
import HorizontalLayout from './components/HorizontalLayout/' 

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

function App() {
  function getLayout() {
    let layoutCls = HorizontalLayout
    // switch (props.layout.layoutType) {
    //   case "horizontal":
    //     layoutCls = HorizontalLayout
    //     break
    //   default:
    //     layoutCls = VerticalLayout
    //     break
    // }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      {/* <Router> */}
      <Routes>
        {/* {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />

        ))} */}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
                <Layout>
                  {route.component}
                   </Layout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

export default App;
