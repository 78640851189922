import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './store/store'
// import store from "./store";

// const store='';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Provider store={store} > 
  <React.Fragment>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </React.Fragment>
   </Provider>
);

reportWebVitals();
