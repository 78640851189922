

import { useEffect, useState } from 'react';

const useCountdown = (targetMinutes) => {
  let countDownMilliseconds = targetMinutes * 60 * 1000;
  useEffect(() => {
    setCountDown(countDownMilliseconds);
  }, [countDownMilliseconds])
  
  const [countDown, setCountDown] = useState(countDownMilliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prevCountDown) => Math.max(prevCountDown - 1000, 0)); // Ensure countdown doesn't go below zero
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const minutes = Math.floor(countDown / (1000 * 60));
  const seconds = Math.floor((countDown / 1000) % 60);

  return [minutes, seconds ] ;
};

export { useCountdown };
