import React, {useState, useEffect } from 'react'
import { Row,Col,Modal,Card,CardHeader,CardBody,Nav, NavItem, NavLink,TabContent,TabPane} from 'reactstrap';
import classnames from "classnames";

import { useDispatch,useSelector } from 'react-redux';

import CountdownTimer from '../../common/questionTimer/CountdownTimer';
import QuestionStatusCalenders from '../../common/calender/QuestionStatusCalenders';
// import MonacoEditor from 'react-monaco-editor';
import Editor from "@monaco-editor/react";
import { api_post,api_get } from '../../services/service';
import { compileAndExecuteCode, checkExecutionStatus, getOutputDetails } from "../../helpers/compiler";
import { language_options,ep,questions_timer } from '../../config/dev';
import { log_debug } from '../../helpers/logger';
import { getQuestionIndex,getTimerData } from '../../store/timerSlice';
import { setQuestionIndex } from '../../store/timerSlice';

// import AceEditor from 'react-ace';
// import "ace-builds/src-noconflict/ace";
// import "ace-builds/src-noconflict/mode-javascript";
// import "ace-builds/src-noconflict/theme-monokai";
// import "ace-builds/src-noconflict/ext-language_tools";


const CodeTestModal = ({ data,codingQuestionTransition, isModalActive, onToggleModal}) => {

    const dispatch = useDispatch();
    const currentQuestionData = useSelector(getQuestionIndex);
    const codingQuestionTime = useSelector(getTimerData);

    const [code, setCode] = useState('');
    const [language, setLanguage] = useState('javascript');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [outputDetails, setOutputDetails] = useState(null);
    const [codeError, setCodeError] = useState('');
    const [outputDisplay, setOutputDisplay ] = useState(false);
    const [headerTab, setHeaderTab] = useState("1");


    const languageModes = {
        javascript: 'javascript',
        python: 'python',
        java: 'java',
        cpp: 'c_cpp',
        html: 'html',
        css: 'css',
        // Add more languages as needed
      };

      const editorOptions = {
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
        matchBrackets: 'always',
      };

      const options = {
        mode: 'javascript',
        theme: 'material',
        lineNumbers: true,
      };

    //   useEffect(() => {
    //     // Dynamically load the JavaScript language definition
    //     const loadLanguage = async () => {
    //       const monaco = await import('monaco-editor/esm/vs/editor/editor.api');
    
    //       // Load the JavaScript language definition
    //       await monaco.languages.typescript.typescriptDefaults.addExtraLib(
    //         await fetch('https://unpkg.com/browse/monaco-typescript@3.10.1/release/esm/typescriptServices.js').then(response => response.text()),
    //         'filename:///typescriptServices.js'
    //       );
    //     };
    
    //     loadLanguage();
    //   }, []); // Run this effect only once, on component mount
    

    // useEffect(() => {
    //     // Dynamically load the TypeScript language definition for JavaScript
    //     const loadLanguage = async () => {
    //       const monaco = await import('monaco-editor/esm/vs/editor/editor.api');
    
    //       // Check if the language service is available
    //       if (monaco.languages.typescript) {
    //         // Load the TypeScript language definition
    //         await monaco.languages.typescript.typescriptDefaults.addExtraLib(
    //           await fetch('https://unpkg.com/browse/monaco-typescript@3.10.1/release/esm/typescriptServices.js').then(response => response.text()),
    //           'filename:///typescriptServices.js'
    //         );
    //       } else {
    //         console.error('Monaco Editor TypeScript language service not available.');
    //       }
    //     };
    
    //     loadLanguage();
    //   }, []); // Run this effect only once, on component mount

      useEffect(() => {
    const handleCopyPaste = (event) => {
      // Prevent copy and paste events
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    const handleRightClick = (event) => {
      // Prevent right-click context menu
      event.preventDefault();
    };

    // Attach event listeners to the document when the modal is active
    if (isModalActive) {
      document.addEventListener('keydown', handleCopyPaste);
      document.addEventListener('contextmenu', handleRightClick);
    }

    // Remove event listeners when the modal is closed
    return () => {
      document.removeEventListener('keydown', handleCopyPaste);
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, [isModalActive]);
  
  // const handleEditorDidMount = (editor, monaco) => {
  //   const container = editor.getDomNode();
  
  //   const disableCopyPaste = (event) => {
  //     event.preventDefault();
  //   };
  
  //   container.addEventListener('contextmenu', disableCopyPaste);
  //   container.addEventListener('copy', disableCopyPaste);
  //   container.addEventListener('cut', disableCopyPaste);
  //   container.addEventListener('paste', disableCopyPaste);
  // };

  // function disableCopyPaste(event) {
  //   if (event.ctrlKey === true && (event.key === "c" || event.key === "x" || event.key === "v")) {
  //    event.preventDefault();
  //   //  UpdateshowWarning("Copying and pasting are temporarily disabled during the test to maintain its integrity.");
  //   }
  //  }
  
  //  document.addEventListener("keydown", disableCopyPaste);
  //  document.addEventListener("contextmenu", disableRightClick);

    function getLanguageId(selectedLanguageName) {
      const selectedLanguage = language_options.find((option) => option.value === selectedLanguageName);
    
      return selectedLanguage ? selectedLanguage.id : null;
     }

     async function pollExecutionStatus(token) {
      let response = await checkExecutionStatus(token);
      setOutputDetails(getOutputDetails());
      // outputDetails = getOutputDetails();
    
      if (outputDetails) {
        if (outputDetails?.stdout) {
          
          // output = atob(outputDetails.stdout);
          setCodeError('No Error');
        } else if (outputDetails?.status?.id === 6 && outputDetails?.compile_output) {
          // Compilation Error with compile_output
          setCodeError(atob(outputDetails?.compile_output));
        } else if (outputDetails.stderr) {
          // Other Errors in stderr
          setCodeError(atob(outputDetails?.stderr));
        } else if (!atob(outputDetails.stdout)) {
          // Default message for other statuses
          setCodeError("An error occurred: " + outputDetails.status.description);
        }
        // sendDataToServer(code, output, CodeError);
      } else {
        // An issue with Judge0
      }
    }

    const handleRunCode = async () => {
      const codeLanguageName = currentQuestion.skills[0].toLocaleLowerCase();
      const codeLanguageId = getLanguageId(codeLanguageName);      
      // setOutputDisplay(true);

      try {
        const token = await compileAndExecuteCode(codeLanguageId, code);
        pollExecutionStatus(token);
      } catch (error) {
        console.error("Error while executing code:", error);
        // setErrorState(true);
      }

      };

      function fetchQuestionDetail(){
        api_get(null, ep.assessment + "/v1/assessmentquestion/" + data.questions[currentQuestionIndex + 1].guid, (_ques_err, _queResponse) => {
            if (!_ques_err) {
            setCode(_queResponse.assessmentquestion?.code_snippet);
            //  setSelectedOption(_queResponse.assessmentquestion.submitted_answer || null); 
            }
           });
      }

      function clearCodeOutput(){
        setCode('');
        setOutputDetails(null);
      }

      function sendDataToServer() {
        const requestData = {
         assessmentquestion: {
          code_snippet: code,
          code_error: codeError,
         },
        };
      
        log_debug("CodeTest", "None", "this is requestData-->" + JSON.stringify(requestData));
      
        api_post(null, ep.assessment + "/v1/assessmentquestion/" + currentQuestion.guid, requestData, (error, responseData) => {
         if (error) {
          console.error("Failed to send data to the server:", error);
         } else {
          log_debug("CodeTest", "SendToServer", "Reponse" + JSON.stringify(responseData));
         }
        });
       }

    const handlePrev = () => {
      clearCodeOutput();
      api_get(null, ep.assessment + "/v1/assessmentquestion/" + data.questions[currentQuestionIndex - 1].guid, (_ques_err, _queResponse) => {
        if (!_ques_err) {
            setCode(_queResponse.assessmentquestion?.code_snippet || '');
        }
       });
        setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        codingQuestionTransition(currentQuestionIndex - 1);
    };

    const handleSkip = () => {
      const requestBody = {
          assessmentquestion: {
          status: "skipped",
          },
      };
      api_post(null, ep.assessment + "/v1/assessmentquestion/" + currentQuestion.guid, requestBody, (error, responseData) => {
          if (error) {
          console.error("API request error:", error);
          } else {}
        });
        // if (currentQuestionIndex < data.questions.length - 1) {
        //   // fetchQuestionDetail();
        //   }
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
        codingQuestionTransition(currentQuestionIndex + 1);
        const currentQuestionDetails={
          questionIndex:currentQuestionIndex,
          questionStatus:'skipped'
      }    
      dispatch(setQuestionIndex(currentQuestionDetails));
    };

    const handleNext = () => {
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
        sendDataToServer();
        if (currentQuestionIndex < data.questions.length - 1) {
          clearCodeOutput();
          // fetchQuestionDetail();
          }
        codingQuestionTransition(currentQuestionIndex + 1);
        const currentQuestionDetails={
          questionIndex:currentQuestionIndex,
          questionStatus:'attempted'
      }    
     dispatch(setQuestionIndex(currentQuestionDetails));
    };

    function handleCloseModal() {
        onToggleModal(!isModalActive);
        removeBodyCss();
      }
    
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      const toggleHeader = (tab) => {
        if (headerTab !== tab) setHeaderTab(tab);
      };
      
      useEffect(() => {
        setCurrentQuestion(data.questions[currentQuestionIndex]);
       }, [currentQuestionIndex])

      useEffect(() => {
        setOutputDisplay(getLanguageId(currentQuestion?.skills[0].toLocaleLowerCase()) !== null);
      },[currentQuestion])
      
      function hasNonWhitespaceOutput(output) {
        return output !== null && output.trim() !== "";
       }

      const handleEditorChange = (value, event) => {
        setCode(value);
        // settingState("codeText", value);
        // value = value.replace(/\n/g, '');
      };



  return (
    <div>
    <Modal
    //   size="xl"
      style={{ maxWidth: '90%', width: 'auto' }}
      isOpen={isModalActive}
    //   toggle={() => {
    //     handleCloseModal();
    //   }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0 text-primary" id="myExtraLargeModalLabel">
          Coding Assessment&nbsp;&nbsp;<span className="text-muted mb-3 font-size-10">This coding test is designed to assess your coding abilities and problem-solving skills</span>
        </h5>
        <button
          onClick={() => {
            handleCloseModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body"  style={{zoom: '85%'}}>
        <Row>
          <Col lg={5} md={5} xs={12}>
                          <div className="faq-count mt-1">
                          < QuestionStatusCalenders type='coding' questionsCount={data.questions.length} currentQuestionData={currentQuestionData}/>
                              {/* <h5 className="text-primary">{currentQuestionIndex + 1} <span className='text-muted fs-6'>of {data.questions.length}</span></h5> */}
                          </div>
                          <h5 className="mt-2 font-size-20">{data.questions[currentQuestionIndex].question}</h5>
                          {data.questions[currentQuestionIndex].skills && (
                              <span className='bg-primary badge font-size-12 mt-1'>{data.questions[currentQuestionIndex].skills[0]}</span> 
                          )}

                            <div className='mt-3'>
                                <h5 className='m-0 font-size-14'>Hints</h5>
                                <p className='text-muted font-size-12'>Here are some hints to help you complete the assignment.</p>
                                <ul className='list-unstyled mb-3 font-size-12 text-dark'>
                                 {data.questions[currentQuestionIndex].hints.map((hint,idx) => (
                                <li key={idx}><span className='mdi mdi-circle-medium me-1 text-success'></span>{hint}</li>
                                 ))}
                                </ul>
                            </div>

                            <div className='mt-3'>
                                <h5 className='m-0 font-size-14'>Recommendations</h5>
                                <p className='text-muted font-size-12 mt-0'>Your code snippet will be evaluated against specific best practices listed below.</p>
                                <ul className='list-unstyled mb-3 font-size-12 text-dark'>
                                {data.questions[currentQuestionIndex].recommendations.map((rec,idx) => (
                                <li key={idx}><span className='mdi mdi-circle-medium me-1 text-success'></span>{rec}</li>
                                 ))}
                                </ul>
                            </div>
                            <div className='float-start'>    
                                 {<CountdownTimer questionTime={data.questions.length * questions_timer.code_question_timer}/>}
                            </div>

                          {/* <Card className='mt-3 text-grey bg-secondary-subtle'>
                              <CardBody>
                                  {data.questions[currentQuestionIndex].options.map((option, index) => (
                                      <div className="form-check mb-3" key={index}>
                                          <input
                                              className="form-check-input"
                                              type="radio"
                                              name="formRadios"
                                              id={`formRadios${index + 1}`}
                                          // defaultChecked={index === 0}
                                          />
                                          <label className="form-check-label" htmlFor={`formRadios${index + 1}`}>
                                              {option}
                                          </label>
                                      </div>
                                  ))}
                              </CardBody>
                          </Card> */}
          </Col>
          <Col lg={7} md={7} xs={12}>
          <Card>
                <CardHeader className="align-items-center d-flex">
                  {/* <h4 className="fs-5 lead mb-0 flex-grow-1">
                    Type Your Code Below:
                  </h4> */}
                  <h5 className="flex-grow-1 font-size-14 text-primary">
                    Type Your Code Below:
                    <em><p className="text-muted font-size-12 mt-1 mb-0">Ready, set, code! Write your solution in the editor below.<br/>Don't worry if it's not perfect – just give it your best shot! We're here to learn and grow</p></em>
                  </h5>
                    {/* <button className="btn btn-primary me-5" onClick={handleRunCode} disabled={!code.length>0 || !outputDisplay}><i className='dripicons-media-play'></i> Run </button> */}
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem className=''>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="mdi mdi-code-tags"></i>
                          </span>
                          <span className="font-size-12"> <i className="mdi mdi-code-tags me-1"></i> Editor</span>
                        </NavLink>
                      </NavItem>
                      {/* { outputDisplay && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "2",
                          })}
                          onClick={() => {
                            toggleHeader("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="font-size-12">Console</span>
                        </NavLink>
                      </NavItem>
                      )} */}
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body p-1">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    <TabPane tabId="1">
                    {/* <div className='border border-primary p-2' style={{width:"100%"}}> */}
                    {/* {state} */}
                    <Editor
                        height="60vh"
                        theme="vs-dark"
                        loading
                        language={currentQuestion?.skills[0].toLowerCase() || 'javascript'}
                        value={code}
                        onChange={handleEditorChange}
                        options={{
                        cursorStyle: "line",
                        formatOnPaste: true,
                        formatOnType: true,
                        wordWrap: true,
                        contextmenu:false,
                        autoIndent: "full"
                        }}
                        // onMount={(editor, monaco) => {
                        // setTimeout(function () {
                        //     editor.getAction("editor.action.formatDocument").run();
                        // }, 300);
                        // }}
                    />
                    {/* </div> */}
                    </TabPane>
                    <TabPane tabId="2">
                      <div className='p-0 bg-gradient bg-light'>  
                              {outputDisplay && (
                                <div>
                                  {outputDetails ? (
                                    (() => {
                                      switch (outputDetails?.status?.id) {
                                        case 6:
                                          return (
                                            <pre className="p-2 font-normal text-sm text-danger mt-2">
                                              {atob(outputDetails?.compile_output)}
                                            </pre>
                                          );
                                        case 3:
                                          return (
                                            <React.Fragment>
                                              {hasNonWhitespaceOutput(outputDetails?.stdout) ? (
                                                <pre className="p-1 text-sm text-success mt-2">
                                                  {atob(outputDetails?.stdout)}
                                                </pre>
                                              ) : (
                                                <pre className="p-2 text-sm text-warning mt-2">
                                                  There is No Code
                                                </pre>
                                              )}
                                            </React.Fragment>
                                          );
                                        case 5:
                                          return (
                                            <pre className="p-2 text-sm text-danger mt-2">
                                              Time Limit Exceeded
                                            </pre>
                                          );
                                        default:
                                          return (
                                            <pre className="p-2 text-sm text-danger">
                                              {atob(outputDetails?.stderr)}
                                            </pre>
                                          );
                                      }
                                    })()
                                  ) : (
                                    <p className="p-2">Output will be displayed here...</p>
                                  )}
                                </div>
                              )}
                      </div>
                    </TabPane>

                  </TabContent>
                </div>
              </Card>

            {/* <div className='border border-primary p-2'>
                            <div style={{width:"100%"}}>
                    <Editor
                        height="60vh"
                        theme="vs"
                        defaultLanguage="java"
                        defaultValue='Test'
                        onChange={handleEditorChange}
                        options={{
                        cursorStyle: "line",
                        formatOnPaste: true,
                        formatOnType: true,
                        wordWrap: true
                        // autoIndent: "full"
                        }}
                        // onMount={(editor, monaco) => {
                        // setTimeout(function () {
                        //     editor.getAction("editor.action.formatDocument").run();
                        // }, 300);
                        // }}
                    />
                    </div> */}



                {/* <MonacoEditor
                    width="100%"
                    height="100%"
                    language="javascript"
                    theme="vs-dark"
                    value={code}
                    options={editorOptions}
                    onChange={(newCode) => setCode(newCode)}
                /> */}
                {/* <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="javascript">JavaScript</option>
                    <option value="python">Python</option>
                    <option value="java">Java</option>
                    <option value="cpp">C++</option>
                    <option value="html">HTML</option>
                    <option value="css">CSS</option>
                </select> */}
                {/* <AceEditor
                height="100px"
                value={code}
                mode="javascript"
                theme="monokai"
                fontSize="16px"
                highlightActiveLine={true}
                setOptions={{
                    enableLiveAutocompletion: true,
                    showLineNumbers: true,
                    tabSize: 2
                }}
                /> */}
            {/* </div> */}
                   <div className="d-flex flex-wrap gap-3 float-end">
                    {/* { (!code.length>0 || !outputDisplay) ? ("") : (
                      <button className="btn btn-primary me-2" onClick={handleRunCode} disabled={!code.length>0 || !outputDisplay}><i className='dripicons-media-play'></i> Run </button>
                    )} */}
                    <div className="btn-group" role="group" aria-label="mcq-buttons">
                        <button className="btn btn-primary" onClick={handlePrev} disabled={currentQuestionIndex === 0}>
                           <i className='mdi mdi-arrow-left-thin'></i> Prev
                        </button>
                        <button className="btn btn-primary" onClick={handleSkip}>
                            Skip
                        </button>
                        <button className="btn btn-primary" onClick={handleNext} disabled={!code}>
                            Next <i className='mdi mdi-arrow-right-thin'></i>
                        </button>
                    </div>
                    </div>
                  {/* <div className="mt-3 float-end">
                      <button className="btn btn-primary me-2" onClick={handlePrev} disabled={currentQuestionIndex === 0}>
                          Prev
                      </button>
                      <button className="btn btn-primary me-2" onClick={handleSkip}>
                          Skip
                      </button>
                      <button className="btn btn-primary" onClick={handleNext} disabled={!code}>
                          Next
                      </button>
                  </div> */}
          </Col>
        </Row>
        </div>
        </Modal>
        </div>
  )
}

export default CodeTestModal