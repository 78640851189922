import React,{useState, useEffect} from 'react'
import { Link, } from 'react-router-dom'
import { Col,Card,CardHeader,CardBody,Carousel,CarouselItem,CarouselIndicators } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getTimerData } from '../store/timerSlice'
import { getQuestionIndex } from '../store/timerSlice'
import { getRoleBasedJob } from '../store/informationSlice'

import CountdownTimer from './questionTimer/CountdownTimer'
import QuestionStatusCalenders from './calender/QuestionStatusCalenders'

import { time_since } from '../helpers/date'
import logo from "../assets/images/talentiq-logo-short.png";

const items = [
    {
        id: 1,
        icon: logo,
        title: "AI Spotlight",
        description: "Get Discovered for Your Worth: Forget chasing opportunities. TalentIQ' AI puts your skills under the spotlight and matches you with companies seeking your exact expertise. Stand out from the crowd, let your talent shine, and get discovered for who you truly are."
    },
    {
        id: 2,
        icon: logo,
        title: "Fast-Track Your Career",
        description: "TalentIQ connects you directly with hiring managers seeking your skillset. No cold emails, no endless applications. Just pre-qualified opportunities and a faster path to success."
    },
    {
        id: 3,
        icon: logo,
        title: "Stand Out from the Crowd",
        description: "TalentIQ connects you directly with hiring managers seeking your skillset. No cold emails, no endless applications. Just pre-qualified opportunities and a faster path to success."
    },
];

const Information = ({data}) => {
  const jobs = useSelector(getRoleBasedJob);
  let mcqQuesTimeLimit =useSelector(getTimerData);
  const currentQuestionData = useSelector(getQuestionIndex);

  const step = useLocation().pathname.split('-')[1];
  
    const [activeIndex, setActiveIndex] = useState(0);
      const [animating, setAnimating] = useState(false);
  
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="text-center p-2">
                    <i className={'mdi mdi-' + item.icon + ' widget-box-1-icon'}></i>
                    <div className="avatar-md m-auto">
                        <span className="avatar-title rounded-circle bg-light-subtle text-white font-size-24">
                          <img src={item.icon} alt="" className="img-thumbnail rounded-circle" />
                        </span>
                    </div>
                     {/* <div className="avatar-sm flex-shrink-0 me-3">
                        <img src={item.icon} alt="" className="img-thumbnail rounded-circle" />
                    </div> */}
                    <h4 className="mt-3 lh-base fw-normal text-white">
                        <b>{item["title"]}</b>
                    </h4>
                    <p className="text-white-50 font-size-13"> {item["description"]} </p>
                    {/* <Link to="/invoices-list" className="btn btn-light btn-sm" >
                        View details <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link> */}
                </div>
            </CarouselItem>
        );
    });

  return (
    <>
    <Card className="mb-2 ">
        <CardBody>
        {/* <h5 className="mb-3 card-title">Portfolio</h5>
        <div>
            <ul className="list-unstyled mb-0">
                <li>
                    <Link to="#" className="py-2 d-block text-muted"><i className="mdi mdi-web text-primary me-1"></i> Website</Link>
                </li>
                <li>
                    <Link to="#" className="py-2 d-block text-muted"><i className="mdi mdi-note-text-outline text-primary me-1"></i> Blog</Link>
                </li>
            </ul>
        </div> */}
         <Col lg={12} md={12} xs={12}>
          <div className="">
              {/* { (step === 'stepskilltest' || step === 'stepbehavioural') && (
                <div className='mb-4'>
                <div className='w-75'>
                    {mcqQuesTimeLimit > 0 && ( <><h5 class="font-size-14 text-primary">Time Limit</h5>
                     <CountdownTimer questionTime={mcqQuesTimeLimit} /></>)}
                </div>
                <div className='w-75'>
                {mcqQuesTimeLimit > 0 && (< QuestionStatusCalenders questionsCount={20} type='MCQ Questions' currentQuestionData={currentQuestionData}/>)}
                </div>
                </div>
                )} */}
              { (step === 'stepskilltest' || step === 'stepbehavioural') ? (
                <div className=''>
                    {mcqQuesTimeLimit > 0 && (
                      <div className='text-center mb-3'>
                        <h5 className='font-size-14 mb-0'>Time To Resolve</h5>
                          <div className=''>
                            <CountdownTimer questionTime={mcqQuesTimeLimit} />
                          </div>
                      </div>
                      )}
                  {currentQuestionData.questionType === "mcq" && (<div className='mb-3'>
                    <h5 className="font-size-14 text-center mb-3">Multiple Choice Questions</h5>
                      <div className='w-75 ms-5 d-flex justify-content-between align-items-center'>
                    <QuestionStatusCalenders questionsCount={20} type="mcq" currentQuestionData={currentQuestionData} />
                      </div>
                  </div>)}
                  {currentQuestionData.questionType === "coding" && (<div className='mt-2 w-75 ms-4 mb-3'>
                    <h5 className="font-size-14 text-center mb-3">Coding Questions</h5>
                      <div className='w-auto ms-5 d-flex justify-content-between align-items-center'>
                    <QuestionStatusCalenders questionsCount={2} type='coding' currentQuestionData={currentQuestionData} />
                    </div>
                  </div>)}
                  {currentQuestionData.questionType === "video" && (<div className='mt-2 w-75 ms-4 mb-3'>
                      <h5 className="font-size-14 text-center mb-3">Behavioural Questions</h5>
                      <div className='w-75 ms-5 d-flex justify-content-between align-items-center'>
                    <QuestionStatusCalenders questionsCount={2} type='video' currentQuestionData={currentQuestionData} />
                      </div>
                  </div>)}
                </div>
                ):(
                   jobs && jobs.length > 0 ? (
                    <>
                    <label htmlFor="choices-single-default" className="mb-2 card-title">Recommended Jobs</label>
                    <div className="list-group list-group-flush">
                        { jobs && jobs.length > 0 && (
                          jobs.slice(0,4).map((job, index) => (
                            <div key={index} className="list-group-item list-group-item-action bg-gradient p-2 rounded-2 shadow-md">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0 me-2">
                                    <img src={job.source_thumbnail_url} alt="" className="img-thumbnail rounded-circle" />
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="font-size-14 mb-1">{job.title}</h5>
                                    <p className="font-size-13 mb-0">{job.company}</p>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <p className="font-size-12 text-muted mb-0 ">{job.location}</p>
                                    <p className="font-size-12 mb-0">{time_since(job.list_date)} Ago</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          )}
                      </div>
                    </>
                    ):(
                      <Card className="bg-primary text-white shadow-primary card-h-100 mb-2">
                      <CardBody>
                          <Carousel activeIndex={activeIndex} next={next} previous={previous} className="pb-4">
                              {slides}
                          </Carousel>
                          <CarouselIndicators tag="button" items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className="carousel-indicators-rounded m-0" />
                      </CardBody>
                    </Card>
                    )
                )
                }
        
          </div>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default Information