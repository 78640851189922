import React, { useState, useEffect } from "react";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import makeAnimated from "react-select/animated";
import { Row, Col, Alert, Card, CardBody, CardHeader } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link,useParams } from "react-router-dom";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from 'react-redux';

import { ep,assessmentsession_steps } from '../../config/dev'
import { log_debug } from "../../helpers/logger";
import { formatDate, time_since } from "../../helpers/date";
import { api_get, api_post } from "../../services/service";
import { roles } from "../../config/roles";
import {
  resume_roles_tfidf,
  get_similar_role_options,
  round_nearest5,
} from "../../helpers/utils";
import { cloud_tech } from "../../config/technologies/cloud_tech";
import { databases } from "../../config/technologies/databases";
import { machine_learning } from "../../config/technologies/machine_learning";
import { programming_languages } from "../../config/technologies/programming_languages";
import { web_technologies } from "../../config/technologies/web_technologies";
import { java_technologies } from "../../config/technologies/java_technologies";
import { mobile_tech } from "../../config/technologies/mobile_tech";
import { analytics } from "../../config/technologies/analytics";
import { devops } from "../../config/technologies/devops";
import { ecommerce } from "../../config/technologies/ecommerce";
import { salesforce } from "../../config/technologies/salesforce";
import { sap } from "../../config/technologies/sap";

// import avatar from "../../assets/images/users/avatar-2.jpg";
import { getuserData } from "../../store/userSlice";
import { setRoleBasedJob } from '../../store/informationSlice';
import Permission from '../../components/modal/Permission';

import { setSelectedTab } from "../../store/snackbarSlice";

const BadgeColors = {
  Database: 'bg-secondary',
  'Programming Language': 'bg-success',
  'Platform / Tool': 'bg-warning',
  // Add more categories and colors as needed
};

const StepSkillMapping = ({ data, toggleCurrentStep }) => {
    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();

    const [roleFromUrl, setRoleFromUrl] = useState(false);
    const [resume, setResume] = useState({});
    const [isPermissionActive, setIsPermissionActive] = useState(false);
    const [roless, setRoless] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [primarySKill, setPrimarySkill] = useState(null)
    const [secondarySkill, setSecondarySkill] = useState(null)
    const [mapAllSkills, setMapAllSkills] = useState(null)
    const [assessmentMapData, setAssessmentMapData] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');


  const currentTab = "ⓘ Please choose your relevant skills from the list below. Your selections will help us tailor your experience and provide you with the most relevant content and opportunities.";
    
  useEffect(() => {
    dispatch(setSelectedTab(currentTab));
    if (window.location.href.split("?").length > 1) {
      let _data = {};
      let params_list = window.location.href.split("?")[1].split("&");
      params_list.forEach((_p) => {
        _data[_p.split("=")[0]] = _p.split("=")[1];
      });
      if(_data.role){
        setRoleFromUrl(true);
        const foundRole = custRoles.find((role) => {
          return role.title === decodeURIComponent(_data.role);
        });
        handleSelectRole(foundRole);
        
      }else{
        setRoleFromUrl(false);
      }
     }
  }, []);

  const [bindAssessmentMapData, setBindAssessmentMapData] = useState({
    assessmentsession: {
      candidate_guid: "",
      role: "",
      primary_skills: [],
      secondary_skills: [],
    },
  });

  const user_data = useSelector(getuserData);

  let bind_selected_skills = [{}];

  let bind_assessmentMapData = {
    assessmentsession: {
      candidate_guid: "",
      role: "",
      primary_skills: [],
      secondary_skills: [],
    },
  };

  let bind_all_skills = [];
  let allSkills = cloud_tech
    .concat(...databases)
    .concat(...machine_learning)
    .concat(...programming_languages)
    .concat(...java_technologies)
    .concat(...web_technologies)
    .concat(...mobile_tech)
    .concat(...analytics)
    .concat(...devops)
    .concat(...ecommerce)
    .concat(...salesforce)
    .concat(...sap);

  allSkills.forEach((skill) => {
    const isDuplicate = bind_all_skills.some(
      (existingSkill) => existingSkill.title === skill.title
    );
    if (!isDuplicate) {
      bind_all_skills.push({
        label: skill.title,
        value: skill.title,
        ...skill,
      });
    }
  });

  const custRoles = roles.map((role) => ({
    label: role.title,
    value: role.title,
    ...role,
  }));

    useEffect(() => {
        setRoless(custRoles);
        setResume(
            user_data &&
            user_data.candidate &&
            user_data.candidate.resumes &&
            user_data.candidate.resumes.length > 0
              ? user_data.candidate.resumes[user_data.candidate.resumes.length - 1]
              : null
          );
        //   const bind_resume = user_data?.candidate?.resumes ? user_data.candidate.resumes[user_data.candidate.resumes.length - 1] : '';
        //   const bind_recommended_roles = bind_resume ? resume_roles_tfidf(bind_resume) : '';
        // if (bind_recommended_roles && bind_recommended_roles[0]) {
        //     let predefined_role = {
        //       label: bind_recommended_roles[0].title,
        //       value: bind_recommended_roles[0].title,
        //       ...bind_recommended_roles[0]
        //     };
        //     handleSelectRole(predefined_role);
        // }

        // Fetch roles from API and set them in state
        // Replace this with your actual API call
        // const fetchRoles = async () => {
        //   try {
        //     const response = await yourApiCall();
        //     setRoles(response.data); // Adjust based on your API response structure
        //   } catch (error) {
        //     console.error('Error fetching roles:', error);
        //   }
        // };
    
        // fetchRoles();
    }, [user_data?.candidate?.resumes]);

    useEffect(() => {
        if(resume?.summary){
        const bind_recommended_roles = resume_roles_tfidf(resume);
        let predefined_role = {
            label: bind_recommended_roles[0].title,
            value: bind_recommended_roles[0].title,
            ...bind_recommended_roles[0]
          };
          if(!roleFromUrl){
            handleSelectRole(predefined_role);
          }
        }
        }, [resume])
    
    
    function fetchRoleBasedJob(role){
        dispatch(setRoleBasedJob({}));
        api_get(null, ep.crawler_service_ep + "/v1/joblisting/search/" + role.title, (_job_err, _job_res) => {
        if (!_job_err) {
            dispatch(setRoleBasedJob(_job_res.joblisting));
        }
        });
    }

    function handleSelectRole(role) {
        setSelectedSkill([]);
        setSelectedRole(role)
        fetchRoleBasedJob(role);
        const primary_skills = Object.values(role)
        .filter((value, index) => index >= 0 && index <= 10 && value !== "")
        .map((skillTitle) => {
            const foundSkill = allSkills.find((skill) => skill.title === skillTitle);
            return foundSkill ? { label: skillTitle,value:skillTitle, ...foundSkill,experience:'',expertise:'',status:true } : null; //status:true is to show this as a primary skill and it will be fixed
        })
        .filter(Boolean);
        setSelectedSkill(primary_skills);
        let primary_skill_data = primary_skills.map((skill) => ({
            title: skill.title,
            category: skill.category,
            experience: '',
            expertise: '', 
        }));
        setPrimarySkill(primary_skills);
        setMapAllSkills(primary_skill_data);
        bind_selected_skills = primary_skill_data;
        bind_assessmentMapData.assessmentsession.candidate_guid = data.candidate_guid;
        bind_assessmentMapData.assessmentsession.role = role.title;
        bind_assessmentMapData.assessmentsession.primary_skills = primary_skill_data;

        setBindAssessmentMapData((prevData) => ({
            ...prevData,
            assessmentsession: {
              ...prevData.assessmentsession,
              candidate_guid: data.candidate_guid,
              role: role.title, // Update other properties as needed
              primary_skills: primary_skill_data
              // Add other properties or update existing ones based on your requirements
            },
          }));

        setAssessmentMapData(bind_assessmentMapData);
      }
      
    function handleSelectSkill(skill) {
        let secondary_skill = skill.slice(primarySKill.length).map((sk)=>({
            title: sk.title,
            category: sk.category,
            experience: '',
            expertise: '', 
        }));
        // setSelectedSkill((prevskills)=>[...prevskills, ...skill]);
        setSelectedSkill(primarySKill.concat(skill));
        setSecondarySkill(skill)
        setMapAllSkills((prevSkills) => [...prevSkills, ...secondary_skill]);

        // bind_selected_skills.push(...bind_selected_skills,...secondary_skill);
        bind_assessmentMapData.assessmentsession.secondary_skills = secondary_skill;

        setBindAssessmentMapData((prevData) => ({
            ...prevData,
            assessmentsession: {
              ...prevData.assessmentsession,
             secondary_skills:secondary_skill
            },
          }));
        
      }
    
    const on_change_skill_experience = (index, e) => {
      e.preventDefault();
      const regex = /^(?:[0-9]|[1-3][0-9]|40)$/;
      const experienceValue = parseInt(e.target.value);
      if (regex.test(e.target.value)) {
        setSelectedSkill((prevSkills) => {
          const updatedSkills = [...prevSkills];
          updatedSkills[index] = {
            ...updatedSkills[index],
            experience: experienceValue,
          };
          return updatedSkills;
        });
    
        setMapAllSkills((prevSkills) => {
          const updatedSkills = [...prevSkills];
          updatedSkills[index] = {
            ...updatedSkills[index],
            experience: experienceValue,
          };
          return updatedSkills;
        });
      } else {
        setSelectedSkill((prevSkills) => {
          const updatedSkills = [...prevSkills];
          updatedSkills[index] = {
            ...updatedSkills[index],
            experience: '',
          };
          return updatedSkills;
        });
        // Handle the error state or show an error message
        const errorMessage = 'Please enter a valid number for years of experience. The experience should be between 0 and 40 years.';
        setErrorMessage(errorMessage);
    
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    };
    

    const on_change_skill_expertise = (index, e) => {
        e.preventDefault();
        setSelectedSkill((prevSkills) => {
            const updatedSkills = [...prevSkills];
            updatedSkills[index] = {
                ...updatedSkills[index],
                expertise: e.target.value,
            };
            return updatedSkills;
        });
        setMapAllSkills((prevSkills) => {
            const updatedSkills = [...prevSkills];
            updatedSkills[index] = {
                ...updatedSkills[index],
                expertise: e.target.value,
            };
            return updatedSkills;
        });
    };

    const handleSkillTest = () =>{
        toggleCurrentStep(assessmentsession_steps.stepskilltest.code,bindAssessmentMapData);
    }

    const handleAskPermission = (e)=>{
        // Check for incomplete fields
        for (let i = 0; i < selectedSkill.length; i++) {
          const skill = selectedSkill[i];
          if (skill.experience === '' || isNaN(skill.experience) || skill.expertise === '') {
            const errorMessage = 'Please fill all the relevant experience and expertise';
            setErrorMessage(errorMessage);
    
            // Clear the error message after 5 seconds
            setTimeout(() => {
              setErrorMessage('');
            }, 5000);
    
            // Prevent further action if any field is incomplete
            e.preventDefault();
            return;
          }
        }

        setBindAssessmentMapData((prevData) => ({
            ...prevData,
            assessmentsession: {
              ...prevData.assessmentsession,
             primary_skills:mapAllSkills.slice(0,primarySKill.length),
             secondary_skills:mapAllSkills.slice(primarySKill.length)
            },
          }));
        // if(!isPermissionActive){
        //     e.preventDefault();
        // }
        // if(isPermissionActive){
        // }
        setIsPermissionActive(!isPermissionActive);
    }

  const handleStepClick = (e) => {
    e.preventDefault();
        toggleCurrentStep(assessmentsession_steps.stepuserprofile.code);
  }

  // export default () => {
  //   const [value, setValue] = useState<readonly ColourOption[]>(
  //     orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]])
  //   );
  
  //   const onChange = (
  //     newValue: OnChangeValue<ColourOption, true>,
  //     actionMeta: ActionMeta<ColourOption>
  //   ) => {
  //     switch (actionMeta.action) {
  //       case 'remove-value':
  //       case 'pop-value':
  //         if (actionMeta.removedValue.isFixed) {
  //           return;
  //         }
  //         break;
  //       case 'clear':
  //         newValue = colourOptions.filter((v) => v.isFixed);
  //         break;
  //     }
  
  //     setValue(orderOptions(newValue));
  //   };

  const customStyles = { 
    multiValueRemove: (base, state) => {
      // return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  }

  const renderSelectSkllls  = () => {
    // const [value, setValue] = useState<readonly bind_all_skills[]>(
    //   orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]])
    // );
  
    // const onChange = (
    //   newValue: OnChangeValue<bind_all_skills, true>,
    //   actionMeta: ActionMeta<ColourOption>
    // ) => {
    //   switch (actionMeta.action) {
    //     case 'remove-value':
    //     case 'pop-value':
    //       if (actionMeta.removedValue.isFixed) {
    //         return;
    //       }
    //       break;
    //     case 'clear':
    //       newValue = colourOptions.filter((v) => v.isFixed);
    //       break;
    //   }
  
    //   setValue(orderOptions(newValue));
    // };
  
    const onChange = (newValue, actionMeta) => { 
  
    }

    return (
      <Select
      // defaultValue={[bind_all_skills[1]]}
      // styles={customStyles}
      backspaceRemovesValue={false}
      isMulti
      onChange={onChange}
      // onChange={(e) => {
      //     handleSelectSkill(e)
      // }}
      value={selectedSkill}
      options={bind_all_skills}
      // isClearable={selectedSkill.some((sk) => !sk.status)}
      isClearable={false}
      isOptionDisabled={() => secondarySkill?.length >= 5}
      className="basic-multi-select"
      closeMenuOnSelect={false}
      classNamePrefix="select2-selection"
      components={animatedComponents}
      />
      // <Select
      //   value={value}
      //   isMulti
      //   styles={styles}
      //   isClearable={value.some((v) => !v.isFixed)}
      //   name="colors"
      //   className="basic-multi-select"
      //   classNamePrefix="select"
      //   onChange={onChange}
      //   options={colourOptions}
      // />
    );
  };

  return (
    <>
      <Card className=" mt-2 border border-2">
        {/* <CardHeader> */}
        {/* </CardHeader> */}
        <CardBody>
          {/* <Alert color="info" className="alert-label-icon label-arrow rounded-2">
                        <i className="mdi mdi-alert-circle-outline label-icon"></i>Please choose your relevant skills from the list below. Your selections will help us tailor your experience and provide you with the most relevant content and opportunities.
                    </Alert> */}
          <div>
            {/* <h5 className="font-size-14 mb-3">Single select input Example</h5> */}

                    <Row>
                    <Col lg={12} md={12} xs={12}>
                        <div className="mb-3">
                        <label htmlFor="choices-single-default" className="form-label text-primary font-size-14">Select Role&nbsp;&nbsp;<span className="text-muted mb-0 font-size-10">Select your role. The assessment will be generated based on this.</span></label>
                        <Select
                            value={selectedRole}
                            onChange={(e) => {
                                handleSelectRole(e)
                            }}
                            options={roless}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            isDisabled={roleFromUrl}
                            components={animatedComponents}
                        />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="choices-multiple-default" className="control-label form-label text-primary font-size-14">Select Skills&nbsp;&nbsp;<span className="text-muted mb-0 font-size-10">Finetune your techinal skill stack.</span></label>
                            {/* {renderSelectSkllls()} */}
                            <Select
                                // defaultValue={[bind_all_skills[1]]}
                                // styles={customStyles}
                                // backspaceRemovesValue={false}
                                isMulti
                                onChange={(e) => {
                                    handleSelectSkill(e)
                                }}
                                value={secondarySkill}
                                options={bind_all_skills}
                                // isClearable={selectedSkill.some((sk) => !sk.status)}
                                isClearable={false}
                                isOptionDisabled={() => secondarySkill?.length >= 5}
                                className="basic-multi-select"
                                closeMenuOnSelect={false}
                                classNamePrefix="select2-selection"
                                components={animatedComponents}
                            />
                        </div>
                        <Card className='p-2 bg-light'>
                        {/* <CardBody> */}
                        {/* <h5 className="mb-3 card-title">Similar Profiles</h5> */}
                            <div className="d-flex border bg-light mb-2 flex_skill_head">
                                <div className="w-75 ms-5 p-2 border"><h5 className="font-size-13">Skills</h5>
                                <p className="text-muted mb-0 font-size-10">Share your skills</p>
                                </div>
                                <div className="w-25 p-2 ms-5 border"><h5 className="font-size-13">Experience<span className='text-danger'>*</span></h5>
                                <p className="text-muted mb-0 font-size-10">Experience in Years</p>
                                </div>
                                <div className="w-25 p-2 border"><h5 className="font-size-13">Expertise<span className='text-danger'>*</span></h5>
                                <p className="text-muted mb-0 font-size-10">Expertise in the Skill</p>
                                </div>
                            </div>
                            {/* <div className='d-flex bg-light p-2'>
                                <h6 className='font-size-18'>Skill</h6>
                                <h6 className='font-size-18'>Experience<span className='text-danger'>*</span></h6>
                                <h6 className='font-size-18'>Expertise<span className='text-danger'>*</span></h6>
                            </div> */}
                            <div className="list-group list-group-flush skill_w_exp">
                                {map(selectedSkill, (skill,index) => (
                                    <div key={index} className="list-group-item list-group-item-action mb-2 ">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm flex-shrink-0 me-3">
                                                <img src={skill.icon} alt="" className="img-thumbnail rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    <h5 className="font-size-14 mb-1">{skill?.label}</h5>
                                                    {primarySKill.some((ps)=> ps.title === skill.label) ? (
                                                      <span className="bg-primary badge me-1">Mandatory</span>
                                                    ) : 
                                                      <span className="badge bg-secondary me-1">Secondary</span>
                                                    }
                                                    <span className={`badge ${BadgeColors[skill?.category]} me-1`}>{skill?.category}</span>
                                                    {/* <span className="bg-success badge bg-secondary">Programming Language</span> */}
                                                    {/* <p className="font-size-13 text-muted mb-0">Full Stack Developer</p> */}
                                                </div>
                                            </div>
                                            <div className='me-2 w-15'>
                                                <input
                                                    id={`experience_${index}`}
                                                    placeholder="Exp."
                                                    min="0" 
                                                    max="50"
                                                    type="number"
                                                    value={skill.experience}
                                                    onChange={(e) => on_change_skill_experience(index, e)}
                                                    className="form-control form-control"
                                                />
                                            </div>
                                            <div>
                                                <select
                                                    id={`expertise_${index}`}
                                                    className="form-select"
                                                    onChange={(e) => on_change_skill_expertise(index, e)}
                                                    value={skill.expertise}
                                                >
                                                    <option value={''} >Select</option>
                                                    <option>Beginner</option>
                                                    <option>Intermediate</option>
                                                    <option>Advanced</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                  {/* </CardBody> */}
                </Card>
              </Col>
              {/* <Col lg={6} md={6} xs={12}>
                    <div className="mb-3">
                        <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted">Job Based Role</label>
                        <Card>
                            <CardHeader>

                            </CardHeader>
                        </Card>
                    </div>
                    </Col> */}
                        
                    </Row>
                    <Row>
                        {errorMessage && (
                            <div id="error-message" className='text-danger fs-6 lead'>
                            {errorMessage}
                            </div>
                        )}
                        <Col lg={12} sm={12}>
                       
                        <Link to="#" className="btn btn-primary btn-sm waves-effect waves-light float-start p-2 mt-5" onClick={(e) => handleStepClick(e)}><i className="fas fa-arrow-left align-middle me-1"></i> Back To Profile</Link>
                        <button className={`btn btn-primary btn-sm waves-effect waves-light float-end p-2 mt-5 ${primarySKill?.length>0 ? '' : 'disabled'}`} onClick={(e) => handleAskPermission(e)}> Grant Permissions <i className="fas fa-arrow-right align-middle ms-1"></i></button>
                        </Col>
                        {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-end p-2" onClick={() => handleStepClick(assessmentsession_steps.stepskilltest.code)}> Go To {assessmentsession_steps.stepskilltest.title} <i className="fas fa-arrow-right align-middle ms-1"></i></Link> */}
                        </Row>
                    </div>
                </CardBody>
            </Card>
            <Permission isModalActive={isPermissionActive} assessmentMapData={bindAssessmentMapData} onSkillTest={handleSkillTest} onToggleModal={handleAskPermission}></Permission>
        </>
  )
}

export default StepSkillMapping;
