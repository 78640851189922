import React,{useEffect, useState} from 'react'
import { Row, Col,Card,CardBody, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody,Collapse } from 'reactstrap'
import { api_get } from '../../services/service'
import {dateyyyymmdd_tohtml} from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { Link } from 'react-router-dom'
import { ep } from '../../config/dev'
import Doughnut from '../../common/charts/Doughnut'
import moment from 'moment'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

const LearnMoreVideo = ({isVideo, toggleLearnMore, data}) => {

  const[videoDetails,setVideoDetails] = useState(null);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [collapseStates, setCollapseStates] = useState({});

  const toggleCollapse = (questionIndex) => {
    setCollapseStates((prevStates) => ({
      ...prevStates,
      [questionIndex]: !prevStates[questionIndex],
    }));
  };

  function fetchData(){
      api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + data, (_video_err, _videoResponse) => {
        if (!_video_err) {
          setVideoDetails(_videoResponse.assessmentquestions);
        }
      });
  }

  const handleReadMore = (e,questionIndex) => {
    e.preventDefault();
    setCollapseStates((prevStates) => ({
      ...prevStates,
      [questionIndex]: !prevStates[questionIndex],
    }));
  };

  useEffect(() => {
    fetchData();
  }, [])

    

  const handleToggleCanvas = (e) => {
    e.preventDefault();
    toggleLearnMore();
}

  return (
    <>
            <Offcanvas
                isOpen={isVideo}
                direction="end"
                toggle={(e)=>{handleToggleCanvas(e)}}
                className='w-40'
                style={{zoom:'85%'}}
            >
                <OffcanvasHeader toggle={toggleLearnMore} className='pb-1'>
                  {/* Behavioural Details<span class="ms-1 text-muted mb-3 font-size-10">See Your Communication Skills</span> */}
                  <h5 className='text-primary font-size-16'>Behavioural Details<span className='ms-1 text-muted font-size-12'>See Your Communication Skills</span></h5>
                  {/* <h5 className='font-size-24'>5<span className='font-size-14 text-muted'>/10</span></h5> */}
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                      {videoDetails?.map((behavioural, index) => (
                                                      <div key={index} className="flex-grow-1 mb-2">
                                                      <div className="border border-2 px-2 py-2">
                                                        <div className="d-flex align-items-start">
                                                          <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="font-size-15 text-truncate">{behavioural.question}</h5>
                                                            <p className="font-size-13 text-muted mb-0">{moment(behavioural.createdAt).format("DD MMM , YYYY")}</p>
                                                          </div>
                                                          <div className="flex-shrink-0 ms-2">
                                                          <div className="" style={{ width: "50px", height: "40px" }}>
                                                                  <CircularProgressbarWithChildren
                                                                    styles={{
                                                                      root: {
                                                                      }, // Adjust these values
                                                                      path: {
                                                                        stroke: "#5156be",
                                                                        strokeLinecap: "round",
                                                                        transition: "stroke-dashoffset 0.5s ease 0s",
                                                                        transform: "rotate(0.25turn)",
                                                                        transformOrigin: "center center",
                                                                        pathColor: "#74788d",
                                                                      },
                                                                      trail: {
                                                                        stroke: "#d6d6d6",
                                                                        strokeLinecap: "round",
                                                                        transform: "rotate(0.25turn)",
                                                                        transformOrigin: "center center",
                                                                      },
                                                                    }}
                                                                    value={((behavioural.score/behavioural.max_score)*100).toFixed(0)}
                                                                  >
                                                                    <div style={{ fontSize: 11 }}>
                                                                      <strong>{((behavioural.score/behavioural.max_score)*100).toFixed(0)}%</strong>
                                                                    </div>
                                                                  </CircularProgressbarWithChildren>
                                                                </div>                        
                                                          </div>
                                                        </div>
                                                        <div className="pt-1">
                                                          <ul className="list-inline">
                                                            {behavioural.skills.map((skill, index)=>(
                                                              <li key={index} className="list-inline-item me-3">
                                                                <span className='text-muted'>
                                                                  <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}{skill}
                                                                </span>
                                                            </li>
                                                            ))}
                                                          </ul>
                                                          <p className='text-muted'>{behavioural && behavioural.video_transcript_result && behavioural.video_transcript_result.summary}</p>
                                                        <Collapse isOpen={collapseStates[index]}>
                                                        


                                                        <div className='d-flex align-content-start flex-wrap mb-2'>
                                                        {behavioural?.video_transcript_result?.evaluation.map((evals, index)=>(
                                                            <div key={index} className="mb-1 w-50">
                                                                    <div className="bg-light px-2 py-2 border rounded me-1 h-100">
                                                                    <div className="d-flex align-items-center">
                                                                      <div className="avatar-sm flex-shrink-0 me-3">
                                                                        <div style={{ width: "38px", height: "50px" }}>
                                                                          <CircularProgressbarWithChildren
                                                                            styles={{
                                                                              root: {
                                                                              }, // Adjust these values
                                                                              path: {
                                                                                stroke: "#5156be",
                                                                                strokeLinecap: "round",
                                                                                transition: "stroke-dashoffset 0.5s ease 0s",
                                                                                transform: "rotate(0.25turn)",
                                                                                transformOrigin: "center center",
                                                                                pathColor: "#74788d",
                                                                              },
                                                                              trail: {
                                                                                stroke: "#d6d6d6",
                                                                                strokeLinecap: "round",
                                                                                transform: "rotate(0.25turn)",
                                                                                transformOrigin: "center center",
                                                                              },
                                                                            }}
                                                                            value={(evals.score).toFixed(2)}
                                                                          >
                                                                            <div style={{ fontSize: 10 }}>
                                                                              <strong>{(evals.score).toFixed(0)}/10</strong>
                                                                            </div>
                                                                          </CircularProgressbarWithChildren>
                                                                        </div>
                                                                      </div>
                                                                      <div className="flex-grow-1">

                                                                          <h5 className="font-size-14 mb-1">{evals.criterion}</h5>
                                                                          <p className="font-size-13 text-muted mb-0">{evals.reason}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                            </div>
                                                            ))}
                                                            </div>
                                                          {/* <h5 className='font-size-14 mb-0'>Evaluation:</h5>
                                                          <ul className="list-inline">
                                                            {behavioural?.video_transcript_result?.evaluation.map((video_res, index)=>(
                                                              <li key={index} className="list-inline-item me-3 text-muted">{video_res.reason}</li>
                                                            ))}
                                                          </ul> */}
                                                          </Collapse>
                                                          <div>
                                                            <Link to="#" onClick={(e) => { handleReadMore(e,index)}} className="text-primary">{collapseStates[index] ? 'Read Less' : 'Read More'} <i className="mdi mdi-arrow-right" /></Link>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div> 
                                  // <div key={index} className="d-flex align-content-start flex-wrap bg-light border mb-1 w-50">
                                  //          <a href="#" className="bg-light px-2 py-2 list-group-item list-group-item-action border border-2 rounded">
                                  //         <div className="d-flex align-items-center">
                                  //           <div className="avatar-sm flex-shrink-0 me-3">
                                  //             <div className="" style={{ width: "38px", height: "50px" }}>
                                  //               <CircularProgressbarWithChildren
                                  //                 styles={{
                                  //                   root: {
                                  //                   }, // Adjust these values
                                  //                   path: {
                                  //                     stroke: "#5156be",
                                  //                     strokeLinecap: "round",
                                  //                     transition: "stroke-dashoffset 0.5s ease 0s",
                                  //                     transform: "rotate(0.25turn)",
                                  //                     transformOrigin: "center center",
                                  //                     pathColor: "#74788d",
                                  //                   },
                                  //                   trail: {
                                  //                     stroke: "#d6d6d6",
                                  //                     strokeLinecap: "round",
                                  //                     transform: "rotate(0.25turn)",
                                  //                     transformOrigin: "center center",
                                  //                   },
                                  //                 }}
                                  //                 value={(behavioural.score).toFixed(2)}
                                  //               >
                                  //                 <div style={{ fontSize: 10 }}>
                                  //                   <strong>{(behavioural.score).toFixed(0)}/10</strong>
                                  //                 </div>
                                  //               </CircularProgressbarWithChildren>
                                  //             </div>
                                  //           </div>
                                  //           <div className="flex-grow-1">
                                  //             <div>
                                  //               <h5 className="font-size-14 mb-1">{behavioural.criterion}</h5>
                                  //               <p className="font-size-13 text-muted mb-0">{behavioural.reason}</p>
                                  //             </div>
                                  //           </div>
                                  //         </div>
                                  //       </a>
                                         
                                  
                                  // </div>
                                    ))}
                    </Col>
                    </OffcanvasBody>
                    </Offcanvas>
                  </>
  )
}

export default LearnMoreVideo