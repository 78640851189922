import React, { useState } from "react";

import { Row, Col, Container } from "reactstrap";
// import { useParams,useLocation } from "react-router-dom";
import UserDetail from "../../common/UserDetail";
import Information from "../../common/Information";
import AssessmentSteps from "./AssessmentSteps";


const IndexAssessment = (props) => {
  let data = {};

  if (window.location.href.split("?").length > 1) {
    let params_list = window.location.href.split("?")[1].split("&");
    params_list.forEach((_p) => {
      data[_p.split("=")[0]] = _p.split("=")[1];
    });
   }
  // const searchParams = new URLSearchParams(location.search);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={2} lg={2}>
              <UserDetail data={{...data,...props}}/>
            </Col>
          <Col xl={7} lg={7}>
              <AssessmentSteps data={{...data,...props}}/>  
            </Col>
            <Col xl={3} lg={3}>
              <Information data={data}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IndexAssessment;
