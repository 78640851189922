import { createSlice } from '@reduxjs/toolkit';

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    // Your initial state here
    assessmentData: {},
  },
  reducers: {
    setAssessmentData: (state, action) => {
      state.assessmentData = action.payload;
    },
  },
}); 

export const { setAssessmentData } = assessmentSlice.actions;
export const getAssessmentData = (state) => state.assessment.assessmentData;

export default assessmentSlice.reducer;
