import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Label,Card,CardBody,Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { ep,assessmentsession_steps } from '../config/dev';
// import {ep, assessmentsession_steps } from '../../config/dev';
import { api_post } from '../services/service';
import { log_debug } from '../helpers/logger';

const Feedback = ({data,onTalentIQScore}) => {

    const feedback_questions = [
        {
            question: "How would you rate the user interface and ease of navigation of the assessment software?",
            rating: "Select"
        },
        {
            question: "Please rate the quality and relevance of the multiple-choice questions in assessing your knowledge.",
            rating: "Select"
        },
        {
            question: "How would you rate the coding question section in terms of its effectiveness in evaluating your programming skills?",
            rating: "Select"
        },
        {
            question: "Please rate the video behavioural questions segment in terms of its ability to assess your soft skills and behavioral competencies.",
            rating: "Select"
        },
        {
            question: "Overall, how satisfied are you with the assessment software's comprehensive evaluation of your skills and competencies?",
            rating: "Select"
        }
        ];
    
    const [validationErrors, setValidationErrors] = useState({});
    const [ratings, setRatings] = useState(Array(feedback_questions.length).fill("Select"));
    const [feedbackComment, setFeedbackComment] = useState("");

    const handleRatingChange = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index] = value;
    setRatings(newRatings);
    };
    
    function sendFeedbackToServer(requestBody) {
        api_post(null, ep.assessment + "/v1/assessmentsessionfeedback", requestBody, (error, responseData) => {
        if (error) {
            console.error("Failed to send feedback data to the server:", error);
            onTalentIQScore();
          } else {
              log_debug("FeedbackForm", "sendFeedbackToServer", "Response: " + JSON.stringify(responseData));
              onTalentIQScore();
          }
        });
      }

    const handleFeedbackCommentChange = (e) => {
        let errors = { ...validationErrors };
        if (!validator.isLength(e.target.value, { min: 0, max: 500 })) {
            errors['feedback'] = 'Feedback is Too large';
            setValidationErrors(errors);
        }
        else{
            setValidationErrors({});
            errors['feedback'] = '';
        }
    setFeedbackComment(e.target.value);
    };

    const isSubmitButtonDisabled = Object.keys(validationErrors).length > 0 || ratings.includes("Select");

    const handleStepClick = (e) => {
        e.preventDefault();
        if (isSubmitButtonDisabled) {
            // Optionally show a message to the user about completing all ratings
            console.error('Please rate all questions before submitting feedback');
            return;
        }
          // Create the request body
        const requestBody = {
            assessmentsessionfeedback: {
            candidate_guid: data.candidate_guid,
            assessment_session_guid: data.guid,
            feedback_questions: feedback_questions.map((question, index) => ({
                question: question.question,
                rating: ratings[index]
            })),
            feedback_comment: feedbackComment
            }
        };
        if (Object.keys(validationErrors).length === 0) {

            sendFeedbackToServer(requestBody);
        }
    }

    // function handleCloseModal() {
    //     onToggleModal(!isModalActive);
    //     removeBodyCss();
    // }

    // function removeBodyCss() {
    //     document.body.classList.add("no_padding");
    // }

return (
    <div>
        <Row>
            <h5 className='mb-3 font-size-14 text-primary'>Please Give Your Valuable Feedback:</h5>
            {feedback_questions.map((question, index) => (
                <div key={index} className="mb-2 d-flex justify-content-between">
                <label className="font-size-13 w-75"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i> {question.question}</label>
                <select
                    className="form-select w-15"
                    value={ratings[index]}
                    onChange={(e) => handleRatingChange(index, e.target.value)}
                >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    {/* Add more options as needed */}
                </select>
                </div>
            ))}
            <div className='mt-3'>
                <Label htmlFor="horizontal-feedback-input" className="col-sm-3 font-size-13">Feedback Comment:</Label>
                <textarea id="horizontal-feedback-input" name='summary' className="form-control" rows="4" placeholder="Enter Your Feedback (Optional)" value={feedbackComment} onChange={handleFeedbackCommentChange}></textarea>
                {validationErrors.feedback && <small className="text-danger text-capitalize">{validationErrors.feedback}</small>}
            </div>
            
        </Row>
        <div className='mt-3'>
        <Link to="#" className={`mt-5 btn btn-primary waves-effect waves-light float-end p-2 ${isSubmitButtonDisabled ? 'disabled' : ''}`} onClick={(e) => handleStepClick(e)} disabled={isSubmitButtonDisabled}>
            Submit Feedback <i className="fas fa-arrow-right align-middle ms-1"></i>
        </Link>
            {/* <Link to="#" className="btn btn-primary waves-effect waves-light float-end p-2" onClick={(e) => handleStepClick(e)}> Submit Feedback <i className="fas fa-arrow-right align-middle ms-1"></i></Link> */}
        </div>
    </div>
)
}

export default Feedback