export const ecommerce = [
 { title: "Magento", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/magento.png", status: "" },
 { title: "WooCommerce", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/woocommerce.png", status: "" },
 { title: "Shopify", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/shopify.png", status: "" },
 { title: "BigCommerce", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/bigcommerce.png", status: "" },
 { title: "Salesforce Commerce Cloud", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/salesforce-commerce-cloud.png", status: "" },
 { title: "PrestaShop", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/prestashop.png", status: "" },
 { title: "OpenCart", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/opencart.png", status: "" },
 { title: "Wix eCommerce", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/wix-ecommerce.png", status: "" },
 { title: "Volusion", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/volusion.png", status: "" },
 { title: "Squarespace eCommerce", category: "Platform / Tool", icon: "assets/icons/img/e-commerce/squarespace-ecommerce.png", status: "" },
];
