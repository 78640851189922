export const web_technologies = [
 { title: "MongoDB", category: "Database", icon: "assets/icons/img/web-technologies/mongodb.png", status: "" },
 { title: "ExpressJS", category: "Framework", icon: "assets/icons/img/web-technologies/expressjs.png", status: "" },
 { title: "AngularJS", category: "Framework", icon: "assets/icons/img/web-technologies/angularjs.png", status: "" },
 { title: "NodeJS", category: "Platform / Tool", icon: "assets/icons/img/web-technologies/nodejs.png", status: "" },
 { title: "ReactJS", category: "Framework", icon: "assets/icons/img/web-technologies/reactjs.png", status: "" },
 { title: "Laravel", category: "Framework", icon: "assets/icons/img/web-technologies/laravel.png", status: "" },
 { title: "Django", category: "Framework", icon: "assets/icons/img/web-technologies/django.png", status: "" },
 { title: "Flask", category: "Framework", icon: "assets/icons/img/web-technologies/flask.png", status: "" },
 { title: "FastAPI", category: "Framework", icon: "assets/icons/img/web-technologies/fastapi.png", status: "" },
 { title: "Redux", category: "Framework", icon: "assets/icons/img/web-technologies/redux.png", status: "" },
 { title: "GraphQL", category: "Programming Language", icon: "assets/icons/img/web-technologies/graphql.png", status: "" },
 { title: "Socket.io", category: "Framework", icon: "assets/icons/img/web-technologies/socket.io.png", status: "" },
 { title: "TypeScript", category: "Programming Language", icon: "assets/icons/img/web-technologies/typescript.png", status: "" },
 { title: ".NET Framework", category: "Framework", icon: "assets/icons/img/web-technologies/net-framework.png", status: "" },
 { title: "ASP.NET", category: "Framework", icon: "assets/icons/img/web-technologies/asp.net.png", status: "" },
 { title: "Dapper", category: "Platform / Tool", icon: "assets/icons/img/web-technologies/dapper.png", status: "" },
 { title: "Ruby", category: "Programming Language", icon: "assets/icons/img/web-technologies/ruby.png", status: "" },
 { title: "Rails", category: "Framework", icon: "assets/icons/img/web-technologies/rails.png", status: "" },
 { title: "JWT", category: "", icon: "assets/icons/img/web-technologies/jwt.png", status: "" },
 { title: "Resque", category: "Framework", icon: "assets/icons/img/web-technologies/resque.png", status: "" },
 { title: "Symfony", category: "Framework", icon: "assets/icons/img/web-technologies/symfony.png", status: "" },
 { title: "IIS", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Rocket", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Diesel", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Actix", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "HTML", category: "Programming Language", icon: "assets/icons/img/web-technologies/html.png", status: "" },
 { title: "CSS", category: "Programming Language", icon: "assets/icons/img/web-technologies/css.png", status: "" },
 { title: "VueJS", category: "Framework", icon: "assets/icons/img/web-technologies/vuejs.png", status: "" },
 { title: "EmberJS", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "VueX", category: "Framework", icon: "assets/icons/img/web-technologies/vuex.png", status: "" },
 { title: "Emscripten", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "AssemblyScript", category: "Programming Language", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Bootstrap", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Material-UI", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Bulma", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "JQuery", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "AMP HTML", category: "Framework", icon: "assets/icons/img/web-technologies/amp-html.png", status: "" },
];
