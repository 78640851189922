import React,{useState,useRef,useEffect} from 'react'
import { Col, Modal,Card,Spinner } from 'reactstrap';
import { useDispatch,useSelector } from 'react-redux';
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import { api_post } from '../../services/service';
import { ep } from '../../config/dev';
import { log_debug } from '../../helpers/logger';

import { getQuestionIndex,setQuestionIndex,getTimerData,setTimerData } from '../../store/timerSlice';

import QuestionStatusCalenders from '../../common/calender/QuestionStatusCalenders';
import CountdownTimer from '../../common/questionTimer/CountdownTimer';

const VideoTestModal = ({ data, videoQuestionTransition, isModalActive, onToggleModal }) => {

    const dispatch = useDispatch();
    const currentQuestionData = useSelector(getQuestionIndex);
    const videoQuestionTime = useSelector(getTimerData);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion,setCurrentQuestion] = useState(null);
    const [recording,setRecording] = useState(false);
    const [stream, setStream] = useState(null);
    const [blob, setBlob] = useState(null);
    const refVideo = useRef(null);
    const recorderRef = useRef(null);
  
    // const handleRecording = async () => {
    //     try {
    //       const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    //       setStream(mediaStream);
    //       recorderRef.current = new RecordRTC(mediaStream, { type: 'video' });
    //       recorderRef.current.startRecording();
    //     } catch (error) {
    //       console.error('Error accessing media devices:', error);
    //     }
    //   };
  
    // const handleRecording = async () => {
    //   const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    // //   const mediaStream = await navigator.mediaDevices.getDisplayMedia({
    // //     video: {
    // //       width: 1920,
    // //       height: 1080,
    // //       frameRate: 60,
    // //     },
    // //     audio: true,
    // //   });
  
    //   setStream(mediaStream);
    //   recorderRef.current = new RecordRTC(mediaStream, { type: 'video' });
    //   recorderRef.current.startRecording();
    // };
  
    // const handleStop = () => {
    //   recorderRef.current.stopRecording(() => {
    //     setBlob(recorderRef.current.getBlob());
    //   });
    // };

      const handleSave = () => {
        handleCloseModal();
        // const blobUrl = URL.createObjectURL(blob);
        // invokeSaveAsDialog(blob,'video.mp4');
      };

      const sendVideoToApi = async (videoBlob, questionId) => {
        // Replace with your actual API endpoint
        // const apiUrl = 'https://example.com/api/upload';
        // const mp4Blob = videoBlob.getBlob('video/mp4');
        // const tempblob = new Blob(videoBlob, { type: "video/mp4" });

        const formData = new FormData();
        formData.append('videos', videoBlob, `${questionId}.mp4`);
        // formData.append('questionId', questionId);
        try {
            await api_post(null, ep.files + "/v1/assessmentquestion/" + questionId + "/video", formData, (error, responseData) => {
             if (error) {
              console.error("API request error:", error);
             } else {
              log_debug("videoTest", "SendToServer", "Video uploaded successfully." + JSON.stringify(responseData));
             }
            });
           } catch (error) {
            console.error("Error sending video:", error);
        }
      };
    
      // const handleNextQuestion = () => {
      //     if (recorderRef.current) {
      //         recorderRef.current.stopRecording(() => {
      //           const videoBlob = recorderRef.current.getBlob();
      //           const questionId = data.questions[currentQuestionIndex].guid;
      //           sendVideoToApi(videoBlob, questionId);
      //       // Reset the stream and recorder for the next question
      //       // setStream(null);
      //       recorderRef.current.clearRecordedData();
      //       // For saving the video
      //       // invokeSaveAsDialog(videoBlob,`${questionId}.mp4`);
      //       if(currentQuestionIndex < data.questions.length-1){
      //           startRecording();
      //       }else{
      //         // if (stream) {
      //           const tracks = stream.getTracks();
      //           tracks.forEach(track => track.stop());
      //           setStream(null); // Clear the stream state variable
      //         // }
      //         if (refVideo.current) {
      //           refVideo.current.srcObject = null; // Clear the video source object
      //         }
      //           setTimeout(() => {
      //             handleCloseModal();
      //           }, 1000);
      //       }

      //       // Move to the next question
      //       // setTimeout(() => {
      //           setCurrentQuestionIndex((prevIndex) => prevIndex + 1);    
      //           videoQuestionTransition(currentQuestionIndex + 1);
      //       // }, 500);
      //     });
      //   }
      //   const currentQuestionDetails={
      //     questionIndex:currentQuestionIndex,
      //     questionStatus:'attempted'
      //     }    
      //   dispatch(setQuestionIndex(currentQuestionDetails));
      // };

      const handleNextQuestion = () => {
          if (recorderRef.current) {
            recorderRef.current.stopRecording(() => {
                const videoBlob = recorderRef.current.getBlob();
                const questionId = data.questions[currentQuestionIndex].guid;
                sendVideoToApi(videoBlob, questionId);
                
                // Reset the stream and recorder for the next question
                // setStream(null);
                recorderRef.current.clearRecordedData();
    
                if (currentQuestionIndex < data.questions.length - 1) {
                    startRecording();
                    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    videoQuestionTransition(currentQuestionIndex + 1);
                } else{
                  recorderRef.current.stopRecording();
                  recorderRef.current.clearRecordedData();
                    // Stop the stream when all questions are answered
                    let tracksStopped = false;
                    // setStream(null);
                    refVideo.current = null;
                    if (stream && stream.active && !tracksStopped) {
                        const tracks = stream.getTracks();
                        tracks.forEach(track => {
                            track.stop();
                            track.onended = null; // Clear onended event to avoid memory leaks
                        });
                        setStream(null); // Clear the stream state variable
                        tracksStopped = true;
                    }
        
                    if (refVideo.current && refVideo.current.srcObject) {
                        const videoTracks = refVideo.current.srcObject.getTracks();
                        videoTracks.forEach(track => {
                            track.stop();
                            track.onended = null; // Clear onended event to avoid memory leaks
                        });
                        refVideo.current.srcObject = null; // Clear the video source object
                    }
        
                    // startCamera();
        
                    // Optionally, you can add a delay before moving to the next step
                    // setTimeout(() => {
                        // Stop the stream again after starting the camera for the last question
                        // if (stream) {
                        //     const tracks = stream.getTracks();
                        //     tracks.forEach(track => track.stop());
                        //     setStream(null);
                        // }
                        // stopCamera();
                        // Perform any other actions needed after stopping the stream
                        handleCloseModal();
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                        videoQuestionTransition(currentQuestionIndex + 1);
                    // }, 1000); // You can adjust the delay as needed
                    // if (refVideo.current) {
                    //     refVideo.current.srcObject = null; // Clear the video source object
                    // }
        
                    // setTimeout(() => {
                    //     setRecording(false);
                    //     // handleCloseModal();
                    //     // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    //     // videoQuestionTransition(currentQuestionIndex + 1);
                    // }, 1000);
                }
    
                // Move to the next question
                // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                // videoQuestionTransition(currentQuestionIndex + 1);
            });
        }
      
    
        const currentQuestionDetails = {
            questionIndex: currentQuestionIndex,
            questionStatus: 'attempted'
        };
        dispatch(setQuestionIndex(currentQuestionDetails));
    };
    

    //   const handleNext = () => {
    //     setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
    //     videoQuestionTransition(currentQuestionIndex + 1);
    //  };      
    
      useEffect(() => {
        if (!refVideo.current) {
          return;
        }

        refVideo.current.srcObject = stream; 
      }, [stream, refVideo]);

      useEffect(() => {
        // Function to start accessing the camera when the modal is active
        // const startCamera = async () => {
        //   try {
        //     const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        //     setStream(mediaStream);
        //     if (refVideo.current) {
        //       refVideo.current.srcObject = mediaStream;
        //     }
        //   } catch (error) {
        //     console.error('Error accessing media devices:', error);
        //   }
        // };
    
        // Start accessing the camera when the modal is active
        if (isModalActive && !recording) {
          startCamera();
        }else{
            startRecording();
        }
        // stopCamera();
    
        // Clean up function to stop the camera when the modal is closed
        // return () => {
        //   if (stream) {
        //     stream.getTracks().forEach(track => track.stop());
        //     setStream(null);
        //   }
        // };
      }, [isModalActive]);

        const startCamera = async () => {
          try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setStream(mediaStream);
            if (refVideo.current) {
              refVideo.current.srcObject = mediaStream;
            }
          } catch (error) {
            console.error('Error accessing media devices:', error);
          }
        };

        const stopCamera = async () => {
          try {
            if (stream) {
              const tracks = stream.getTracks();
              tracks.forEach(track => track.stop());
              setStream(null); // Clear the stream state variable
            }
        
            if (refVideo.current) {
              refVideo.current.srcObject = null; // Clear the video source object
            }
          } catch (error) {
            console.error('Error stopping camera:', error);
          }
        };
    
      const startRecording = async () => {
          dispatch(setTimerData(data.questions.length * 2))
        if(currentQuestionIndex < data.questions.length-1){
            try {
              // const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
              // setStream(mediaStream);
              if (refVideo.current) {
                refVideo.current.srcObject = stream;
              }
    
              setRecording(true);
              recorderRef.current = new RecordRTC(stream, { type: 'video',mimeType: 'video/mp4', });
              recorderRef.current.startRecording();
            } catch (error) {
              console.error('Error accessing media devices:', error);
            }
        }
        //else{
        //     handleCloseModal();
        //     // stream.getTracks().forEach(track => track.stop());
        //     // setStream(null);
        // }
      };
    
    //   const startRecording = () => {
    //     if (stream) {
    //       setRecording(true);
    //       recorderRef.current = new RecordRTC(stream, { type: 'video' });
    //       recorderRef.current.startRecording();
    //     }
    //   };
    
      const stopRecording = () => {
        if (recorderRef.current) {
          recorderRef.current.stopRecording(() => {
            setBlob(recorderRef.current.getBlob());
          });
        }
      };

    const submitVideoResponse = () => {
        // Implement logic to submit the recorded video response, e.g., upload to server
      };

    function handleCloseModal() {
        // if(!recording){
            // stream.getTracks().forEach(track => track.stop());
            // setStream(null);
            // }
        //     if (stream) {
        //     stream.getTracks().forEach(track => {
        //         track.stop();
        //         track.onended = null; // Clear onended event to avoid memory leaks
        //     });
        //     setStream(null);
        // }
        stopCamera();
        onToggleModal(!isModalActive);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <div>
            <Modal
                // size="lg"
                style={{ maxWidth: '40%', width: 'auto' }}
                isOpen={isModalActive}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0 text-primary" id="myExtraLargeModalLabel">
                        Behavioural Assessment&nbsp;&nbsp;<span className="text-muted mb-3 font-size-10">Project confidence and articulate your responses clearly during the video interview</span>
                    </h5>
                    <button
                        onClick={() => {
                            handleCloseModal();
                        }}
                        type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body"  style={{zoom: '80%'}}>
                    <Col lg={12} md={12} xs={12}>

                    <div>
                      <div className="hstack justify-content-between p-0">
                        {/* <h5 className="text-primary">{currentQuestionIndex + 1} <span className='text-muted fs-6'>of {data.questions.length}</span></h5> */}
                        <div className='float-start ms-2'>
                          < QuestionStatusCalenders type='video' questionsCount={2} currentQuestionData={currentQuestionData}/>
                          {/* <QuestionStatusCalenders questionsCount={2}  /> */}
                        </div>
                        <div>
                          {/* <span className="badge bg-secondary-subtle p-2 text-muted rounded-1 font-size-14" ><Spinner type="grow" size="sm" className="mx-auto me-1" color="danger" /> REC</span> */}
                        </div>

                        {/* <CountdownTimer questionTime={4} /> */}
                        <div className='justify-content-end mb-2'>
                          <button className='btn btn-primary me-1' onClick={startRecording} disabled={recording}>Start Recording</button>
                          <button className='btn btn-primary me-1' onClick={handleNextQuestion} disabled={!recording}>Next <i className='mdi mdi-arrow-right-thin'></i></button>
                          {/* <button className='btn btn-primary me-1 float-end' onClick={handleSave}>Save</button> */}
                        </div>
                        </div>
                            {/* <div className="faq-count mt-1">
                              <h5 className="text-primary">{currentQuestionIndex + 1} <span className='text-muted fs-6'>of {data.questions.length}</span></h5>
                          </div> */}
                        <Card className='position-relative mb-2'>
                          {stream && (
                            <video className="w-100 card-img img-fluid " playsInline autoPlay muted ref={refVideo}
                            // src={URL.createObjectURL(blob)}
                            />
                          )}
                          { recording && (
                            
                            <div className="card-img-overlay p-0">
                              <span className="badge bg-light p-2 text-muted rounded-1 font-size-14 top-0 start-0 position-absolute" ><CountdownTimer questionTime={videoQuestionTime} /></span>
                              <span className="badge bg-light p-3 text-muted rounded-1 font-size-16 position-absolute top-0 end-0" ><Spinner type="grow" size="sm" className="mx-auto me-1" color="danger" /> REC</span>

                              {/* <h5 className="mt-2">{data.questions[currentQuestionIndex].question}</h5> */}
                              {/* <div className='d-flex flex-wrap'> */}
                              <span className="badge card-title text-white bg-dark-subtle p-4 position-absolute bottom-0 start-0 w-100 mb-0"><span className='text-wrap'>{data.questions[currentQuestionIndex].question}</span></span>
                              {/* </div> */}
                            </div>
                          )}
                        </Card>
                      </div>
                        {/* <div className="mt-3 float-end">
                            <button className='btn btn-primary me-1' onClick={startRecording} disabled={recording}>Start Recording</button>
                            <button className='btn btn-primary me-1' onClick={handleNextQuestion} disabled={!recording}>Next Question</button>
                        </div> */}
                        {/* <video className="w-100" ref={mediaRef} autoPlay playsInline muted /> */}
                    {/* <div className="mt-3 float-end"> */}
                    {/* <button className='btn btn-primary me-1' onClick={startRecording}>start</button> */}
                    {/* <button className='btn btn-primary me-1' onClick={stopRecording}>stop</button> */}
                    {/* <button className='btn btn-primary me-1' onClick={handleSave}>Save</button> */}
                      {/* <button className="btn btn-primary" onClick={handleNext} disabled={currentQuestionIndex === data.questions.length - 1}>
                          Next
                      </button> */}
                  {/* </div> */}
                    </Col>
                </div>
            </Modal>
        </div>
    )
}

export default VideoTestModal