import { common, language_options,stop_words } from "../config/dev";
import { roles } from "../config/roles";

export const is_json = function (str) {
 try {
  JSON.parse(str);
 } catch (e) {
  return false;
 }
 return true;
};

export const is_equivalent = function (a, b) {
 a = a || {};
 b = b || {};
 // Create arrays of property names
 var aProps = Object.getOwnPropertyNames(a);
 var bProps = Object.getOwnPropertyNames(b);
 // If number of properties is different,
 // objects are not equivalent
 if (aProps.length != bProps.length) {
  return false;
 }
 for (var i = 0; i < aProps.length; i++) {
  var propName = aProps[i];
  // If values of same property are not equal,
  // objects are not equivalent
  if (a[propName] !== b[propName]) {
   return false;
  }
 }
 // If we made it this far, objects
 // are considered equivalent
 return true;
};

export const or_get_ist = function () {
 var current_time = new Date();
 var current_offset = current_time.getTimezoneOffset();
 var ist_offset = 330;
 var ist_time = new Date(current_time.getTime() + (ist_offset + current_offset) * 60000);
 return ist_time.getHours().toString() + ":" + ist_time.getMinutes().toString();
};

export const or_guid = function () {
 return common.guid_format.replace(/[xy]/g, function (c) {
  var r = (Math.random() * 16) | 0,
   v = c == "x" ? r : (r & 0x3) | 0x8;
  return v.toString(16);
 });
};

export const or_guid_short = function () {
 return common.short_guid_format.replace(/[xy]/g, function (c) {
  var r = (Math.random() * 16) | 0,
   v = c == "x" ? r : (r & 0x3) | 0x8;
  return v.toString(16);
 });
};

export const or_unique = (arr) => {
 var u = {},
  a = [];
 for (var i = 0, l = arr.length; i < l; ++i) {
  if (!u.hasOwnProperty(arr[i])) {
   a.push(arr[i]);
   u[arr[i]] = 1;
  }
 }
 return a;
};

export const round_nearest5 = (num) => {
 return Math.round(num / 5) * 5;
};
export const random_number = (text) => {
 var h = 0,
  l = text.length,
  i = 0;
 if (l > 0) while (i < l) h = ((h << 5) - h + text.charCodeAt(i++)) | 0;

 return h.toString().slice(2, 4);
};

export const n_formatter = function (num, digits) {
 var si = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "K" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
 ];
 var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
 var i;
 for (i = si.length - 1; i > 0; i--) {
  if (num >= si[i].value) {
   break;
  }
 }
 return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const comma_formatter = (num) => {
 return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const get_url_parameter = function getUrlParameter(url, sParam) {
 var sPageURL = url.substring(1),
  sURLVariables = sPageURL.split("&"),
  sParameterName,
  i;

 for (i = 0; i < sURLVariables.length; i++) {
  sParameterName = sURLVariables[i].split("=");

  if (sParameterName[0] === sParam) {
   return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
  }
 }
};

// search_url = window.location.search
export const get_url_parameter_from_search_location = (search_url, param) => {
 const urlParams = new URLSearchParams(search_url);
 return urlParams.get(param);
};

export const arr_diff = function (a1, a2) {
 var a = [],
  diff = [];
 for (var i = 0; i < a1.length; i++) {
  a[a1[i]] = true;
 }
 for (var i = 0; i < a2.length; i++) {
  if (a[a2[i]]) {
   delete a[a2[i]];
  } else {
   a[a2[i]] = true;
  }
 }
 for (var k in a) {
  diff.push(k);
 }
 return diff;
};

// Insert or Update an array based on unique guid in the Object
export const list_upsert = function (array, element) {
 const i = array.findIndex((_element) => _element.guid === element.guid);
 if (i > -1) array[i] = element;
 else array.push(element);
};

export const csv_to_json = function (csv) {
 var lines = csv.split("\n");
 var result = [];

 // NOTE: If your columns contain commas in their values, you'll need
 // to deal with those before doing the next step
 // (you might convert them to &&& or something, then covert them back later)
 // jsfiddle showing the issue https://jsfiddle.net/
 var headers = lines[0].split(",");
 for (var i = 1; i < lines.length; i++) {
  var obj = {};
  var currentline = lines[i].split(",");
  for (var j = 0; j < headers.length; j++) {
   obj[headers[j]] = currentline[j];
  }
  result.push(obj);
 }
 return result; //JSON
};

export const get_similar_language_options_list = (_prog_languages, _limit) => {
 let similar_language_options_list = [];
 _prog_languages.forEach((_prog_language) => {
  similar_language_options_list.push(...get_similar_language_options(_prog_language, 3));
 });
 return similar_language_options_list
  .sort((a, b) => {
   return b.similarity - a.similarity;
  })
  .slice(0, 5);
};

// function to compute the similarity score between the input programming language and each available language option.
// The function then sorts the options based on similarity score in descending order and returns
// a limited number of similar language options.
export const get_similar_language_options = (_prog_language, _limit) => {
 let similar_lang_options = language_options.map((_lng) => {
  return { id: _lng.id, name: _lng.name, similarity: string_similarity(_prog_language.toLowerCase(), _lng.name.toLowerCase()) };
 });
 similar_lang_options = similar_lang_options
  .sort((a, b) => {
   return b.similarity - a.similarity;
  })
  .slice(0, _limit);
 return similar_lang_options;
};

export const get_similar_role_options = (_resume_summary, _limit) => {
 let similar_role_options = roles.map((_role) => {
  return { title: _role.title, category: _role.category, similarity: string_similarity(_resume_summary.toLowerCase(), _role.title.toLowerCase()) };
 });
 similar_role_options = similar_role_options
  .sort((a, b) => {
   return b.similarity - a.similarity;
  })
  .slice(0, _limit);
 return similar_role_options;
};

//function calculates the Levenshtein distance (also known as edit distance) between two input strings
// s1 and s2. This algorithm measures the minimum number of single-character edits
// (insertions, deletions, or substitutions) required to change one string into another.
export const calculate_levenshtein_distance = (s1, s2) => {
 s1 = s1.toLowerCase();
 s2 = s2.toLowerCase();
 let costs = new Array();
 for (let i = 0; i <= s1.length; i++) {
  let lastValue = i;
  for (let j = 0; j <= s2.length; j++) {
   if (i == 0) costs[j] = j;
   else {
    if (j > 0) {
     let newValue = costs[j - 1];
     if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
     costs[j - 1] = lastValue;
     lastValue = newValue;
    }
   }
  }
  if (i > 0) costs[s2.length] = lastValue;
 }
 return costs[s2.length];
};

// function computes the similarity score between two input strings s1 and s2. It calculates the
// similarity as a value between 0 and 1, where 1 represents identical strings and 0 represents
// completely dissimilar strings. This score is based on the normalized edit distance (Levenshtein distance)
// between the strings.
export const string_similarity = (s1, s2) => {
 let longer = s1;
 let shorter = s2;
 if (s1.length < s2.length) {
  longer = s2;
  shorter = s1;
 }
 let longerLength = longer.length;
 if (longerLength == 0) {
  return 1.0;
 }
 return (longerLength - calculate_levenshtein_distance(longer, shorter)) / parseFloat(longerLength);
};

// function to determine whether a given string contains any of the specified substrings. It iterates through an array of substrings and checks
// if any of them are present in the input string. If it finds a matching substring, it returns that substring. If none of the substrings are found
// in the input string, the function returns null.
export function contains_any(str, substrings) {
 for (var i = 0; i != substrings.length; i++) {
  var substring = substrings[i];
  if (str.indexOf(substring) != -1) {
   return substring;
  }
 }
 return null;
}


// This JavaScript function performs a TF-IDF (Term Frequency-Inverse Document Frequency) analysis to identify roles or job titles that are most relevant to a given resume. 
//This is achieved by the process of matching a resume to job roles by analyzing the frequency and importance of words in the resume text compared to predefined role descriptions. 
//It can be useful for automatically suggesting or categorizing job roles based on the content of a resume.
export function resume_roles_tfidf(_resume_json) {
  // const stop_words = ['the','a','in','is','I','to','as'] // TODO improve this list
  let _resume_words = ((_resume_json.programming_languages || []).join(' ') + (_resume_json.summary + '')).split(' ');
  _resume_words = _resume_words.filter(_w=>{return !stop_words.includes(_w)})
  let _roles = roles
  let _roles_summary = _roles.map(_r=>{return _r.title + ' ' + _r.category + ' ' + _r.primary_1 + ' ' + _r.primary_2 + ' ' + _r.primary_3 + ' ' + _r.primary_4})
  let _roles_with_resumewords = [
    ...new Set(
      _resume_words.map((a) => {
        return {word: a, roles: _roles_summary.filter((b) => b.toLocaleLowerCase().includes(a.toLowerCase())).length};
      })
    ),
  ];
  let tfidf = _roles_summary.map((a) => {
    const obj = {};
    const totalWordsInReview = a.split(" ").length;
    _resume_words.forEach((key) => {
        const _no_of_roles_with_resumewords = _roles_with_resumewords.find((b) => b.word == key);
        const tf = (a.includes(key) ? 1 : 0) / totalWordsInReview;

        // calculate IDF
        const idf = Math.log(_roles.length / _no_of_roles_with_resumewords.roles);
        // multiple both to get weight of word on score 
        obj[key] = tf * idf;
    });
    obj.output = a.score;
    return obj;
  });
  let tfidf_scores = []
  tfidf.forEach(_tfidf=>{
    let _tfidf_sum = 0
    Object.keys(_tfidf).map(_k=>{_tfidf_sum += isNaN(_tfidf[_k]) ? 0 : parseFloat(_tfidf[_k])})
    tfidf_scores.push(_tfidf_sum)
  })
  // let _matching_roles_score = find_top_two_elements(tfidf_scores)
  let _matching_roles_score = [...tfidf_scores].sort((a, b) => b - a);
  // tfidf_scores.sort((a, b) => b - a);
  let _first_matching_role_idx = tfidf_scores.indexOf(_matching_roles_score[0])
  let _second_matching_role_idx = tfidf_scores.indexOf(_matching_roles_score[1])
  let _third_matching_role_idx = tfidf_scores.indexOf(_matching_roles_score[2])

  // log_debug('Util','resume_roles_tfidf','_matching_roles_score :: ' + _matching_roles_score)
  // log_debug('Util','resume_roles_tfidf','_roles[_first_matching_role_idx] :: ' + _roles[_first_matching_role_idx])
  // log_debug('Util','resume_roles_tfidf','_roles[_second_matching_role_idx] :: ' + _roles[_second_matching_role_idx])
  return([_roles[_first_matching_role_idx],_roles[_second_matching_role_idx],_roles[_third_matching_role_idx]])
}

function find_top_two_elements(arr){
  let first = -1 , second = -1;
  for(let i = 0; i <= arr.length-1; i++){
      if(arr[i] > first){
          second = first;
          first = arr[i];
      }
      else if( arr[i] > second && arr[i] != first){
          second = arr[i];
      }
  }
  return [first, second]
}
