export const sap = [
 { title: "SAP ERP", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-erp.png", status: "" },
 { title: "SAP S/4HANA", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-s/4hana.png", status: "" },
 { title: "SAP Fiori", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-fiori.png", status: "" },
 { title: "SAP S/4HANA", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-s-4hana.png", status: "" },
 { title: "SAP HANA", category: "Database", icon: "assets/icons/img/sap/sap-hana.png", status: "" },
 { title: "SAP ABAP", category: "Programming Language", icon: "assets/icons/img/sap/sap-abap.png", status: "" },
 { title: "SAP SD (Sales and Distribution)", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-sd-sales-and-distribution.png", status: "" },
 { title: "SAP MM (Materials Management)", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-mm-materials-management.png", status: "" },
 { title: "SAP CRM (Customer Relationship Management)", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-crm-customer-relationship-management.png", status: "" },
 { title: "SAP SCM (Supply Chain Management)", category: "Platform / Tool", icon: "assets/icons/img/sap/sap-scm-supply-chain-management.png", status: "" },
 { title: "SAP Controlling (CO)", category: "", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP VIM", category: "", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP LSMW (Legacy System Migration Workbench)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP Business Intelligence (BI)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP PowerDesigner", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP Analytics Cloud (SAC)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP Business Explorer (BEx)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAPUI5", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP GRC", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP Solution Manager", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP MC (Management Console)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP HANA Cockpit", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP CVA (Code Vulnerability Analyzer)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "SAP ABAP Workbench", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
];
