export const machine_learning = [
 { title: "Caffe2", category: "Framework", icon: "assets/icons/img/machine-learning/caffe2.png", status: "" },
 { title: "Keras", category: "Framework", icon: "assets/icons/img/machine-learning/keras.png", status: "" },
 { title: "Python Deep Learning", category: "Programming Language", icon: "assets/icons/img/machine-learning/python-deep-learning.png", status: "" },
 { title: "KNIME", category: "Platform / Tool", icon: "assets/icons/img/machine-learning/knime.png", status: "" },
 { title: "TensorFlow", category: "Framework", icon: "assets/icons/img/machine-learning/tensorflow.png", status: "" },
 { title: "PyTorch", category: "Framework", icon: "assets/icons/img/machine-learning/pytorch.png", status: "" },
 { title: "Scikit-Learn", category: "Framework", icon: "assets/icons/img/machine-learning/scikit-learn.png", status: "" },
 { title: "Spark ML", category: "Framework", icon: "assets/icons/img/machine-learning/spark-ml.png", status: "" },
 { title: "Huggingface", category: "Framework", icon: "assets/icons/img/machine-learning/huggingface.png", status: "" },
 { title: "H2O", category: "Framework", icon: "assets/icons/img/machine-learning/h2o.png", status: "" },
 { title: "Apache MXNet", category: "Framework", icon: "assets/icons/img/machine-learning/apache-mxnet.png", status: "" },
 { title: "Pybrain", category: "Framework", icon: "assets/icons/img/machine-learning/pybrain.png", status: "" },
 { title: "Theano", category: "Framework", icon: "assets/icons/img/machine-learning/theano.png", status: "" },
 { title: "Microsoft Cognitive Toolkit (CNTK)", category: "Framework", icon: "assets/icons/img/machine-learning/microsoft-cognitive-toolkit-cntk.png", status: "" },
 { title: "XGBoost", category: "Framework", icon: "assets/icons/img/machine-learning/xgboost.png", status: "" },
 { title: "LightGBM", category: "Framework", icon: "assets/icons/img/machine-learning/lightgbm.png", status: "" },
 { title: "Orange", category: "Platform / Tool", icon: "assets/icons/img/machine-learning/orange.png", status: "" },
 { title: "Shogun", category: "Framework", icon: "assets/icons/img/machine-learning/shogun.png", status: "" },
 { title: "Python", category: "Programming Language", icon: "assets/icons/img/machine-learning/python.png", status: "" },
 { title: "OpenCV", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Natural Language Toolkit (NLTK)", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Jupyter Notebook", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
];
