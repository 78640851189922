import React, { useEffect, useState } from 'react'
import { Card, CardBody,Col,Row,Progress } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { setQuestionIndex } from '../../store/timerSlice';
import { api_post,api_get } from '../../services/service';
import { ep } from '../../config/dev';
import { questions_timer } from '../../config/dev';

import { setTimerData } from '../../store/timerSlice';

const MCQTest = ({ data, mcqquestiontransition }) => {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    
    useEffect(() => {
     setCurrentQuestion(data.questions[currentQuestionIndex]);
    }, [currentQuestionIndex])
    
    useEffect(()=>{
        dispatch(setTimerData(data.questions.length * questions_timer.mcq_question_timer));
        const currentQuestionDetails={
            questionIndex:'',
            questionStatus:'',
            questionType:'mcq'  
        }    
       dispatch(setQuestionIndex(currentQuestionDetails));
    })
   

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
      };


    function fetchQuestionDetail(){
        api_get(null, ep.assessment + "/v1/assessmentquestion/" + data.questions[currentQuestionIndex + 1].guid, (_ques_err, _queResponse) => {
            if (!_ques_err) {
             setSelectedOption(_queResponse.assessmentquestion.submitted_answer || null); 
            }
           });
    }

    const handlePrev = () => {
        api_get(null, ep.assessment + "/v1/assessmentquestion/" + data.questions[currentQuestionIndex - 1].guid, (_ques_err, _queResponse) => {
            if (!_ques_err) {
             setSelectedOption(_queResponse.assessmentquestion.submitted_answer); 
            }
           });
        setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        mcqquestiontransition(currentQuestionIndex - 1);
    };
    
    const handleSkip = () => {
        setSelectedOption(null);
        const requestBody = {
            assessmentquestion: {
             status: "skipped",
            },
        };
        api_post(null, ep.assessment + "/v1/assessmentquestion/" + currentQuestion.guid, requestBody, (error, responseData) => {
            if (error) {
             console.error("API request error:", error);
            } else {

            }
           });
        if (currentQuestionIndex < data.questions.length - 1) {
        fetchQuestionDetail();
        }

        //    if (currentQuestionIndex < data.questions.length - 1) {
        //         setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
        //         mcqquestiontransition(currentQuestionIndex + 1);

        //     // dispatch("mcqquestiontransition", {
        //     //  curr_mcq_idx: _curr_mcq_idx,
        //     //  action: "skip",
        //     // });
        //      }else{
        //         mcqquestiontransition(data.questions.length);
        //      } 

        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
        mcqquestiontransition(currentQuestionIndex + 1);
        const currentQuestionDetails={
            questionIndex:currentQuestionIndex,
            questionStatus:'skipped',
            questionType:'mcq'
           
        }    
       dispatch(setQuestionIndex(currentQuestionDetails));
    };

    const handleNext = () => {
        if (selectedOption !== null) {
            const requestBody = {
                assessmentquestion: {
                 submitted_answer: selectedOption,
                },
               };
               api_post(null, ep.assessment + "/v1/assessmentquestion/" + currentQuestion.guid, requestBody, (error, responseData) => {
                if (error) {
                 console.error("API request error:", error);
                } else {
                }
               });
               if (currentQuestionIndex < data.questions.length - 1) {
                 fetchQuestionDetail();
                }            
            // Optionally, you can reset the selected option for the next question
            setSelectedOption(null);
          }
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, data.questions.length - 1));
        mcqquestiontransition(currentQuestionIndex + 1);
        const currentQuestionDetails={
            questionIndex:currentQuestionIndex,
            questionStatus:'attempted',
            questionType:'mcq'
        }
       dispatch(setQuestionIndex(currentQuestionDetails));
    };

    return (
        <div>
            <Card>
                <CardBody className="overflow-hidden position-relative">
                    <div className="mb-4">
                        <Progress
                            value={((currentQuestionIndex+1)/data.questions.length)*100} color="primary" animated>
                        </Progress>
                    </div>
                    <div>
                        <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                    </div>
                    <Row>
                    <Col xl={1}>
                        <i className='mdi mdi-progress-question fs-1 text-primary p-0 mt-1'></i>
                    </Col>
                    <Col xl={11}>
                    <div className="faq-count mt-1">
                        {/* <h5 className="text-primary">{currentQuestionIndex + 1} <span className='text-muted fs-6'>of {data.questions.length}</span></h5> */}
                    </div>
                    <h5 className="font-size-20 mt-2">{data.questions[currentQuestionIndex].question.split('\n')[0]}</h5>
                    {data.questions[currentQuestionIndex].skills && (
                        <p className="text-muted font-size-12 mt-2 mb-0">This is to test your <b>{data.questions[currentQuestionIndex].skills[0]}</b> programming skills.</p>
                    )}
                    <Card className='mt-3 text-grey bg-light'>
                        <CardBody>
                            {data.questions[currentQuestionIndex].options.map((option, index) => (
                                <div className="form-check mb-3" key={index}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="formRadios"
                                    id={`formRadios${index + 1}`}
                                    checked={selectedOption === option}
                                    onChange={() => handleOptionSelect(option)}
                                />
                                <label className="form-check-label font-size-15" htmlFor={`formRadios${index + 1}`}>
                                    {option}
                                </label>
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                    </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-3 float-end">
                    <div className="btn-group" role="group" aria-label="mcq-buttons">
                        <button className="btn btn-primary" onClick={handlePrev} disabled={currentQuestionIndex === 0}>
                           <i className='mdi mdi-arrow-left-thin'></i> Prev
                        </button>
                        <button className="btn btn-primary" onClick={handleSkip}>
                            Skip
                        </button>
                        <button className="btn btn-primary" onClick={handleNext} disabled={selectedOption === null}>
                            Next <i className='mdi mdi-arrow-right-thin'></i>
                        </button>
                    </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default MCQTest