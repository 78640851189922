import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Card, CardBody } from "reactstrap";
import { ep, primaryShade2 } from "../config/dev";
import { api_get } from "../services/service";
import { log_debug } from "../helpers/logger";
import { useDispatch, useSelector } from "react-redux";
import { setAssessmentData } from "../store/assessmentSlice";
import { setuserData, geteditedData } from "../store/userSlice";
import { getRoleSkills } from "../store/userSlice";
import avatar from "../assets/images/users/user-dummy-img.jpg";
import "../assets/css/common.css";
import $ from "jquery";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { primaryShade1 } from "../config/dev";

import { Circle as ProgressBar } from "react-progressbar";

const UserDetail = ({ data }) => {
  const dispatch = useDispatch();
  const roleSkills = useSelector(getRoleSkills);
  const edited_data = useSelector(geteditedData);

  const [candidate, setCandidate] = useState(null);
  const [resume, setResume] = useState(null);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [assessmentStarted, setAssessmentStarted] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullSummary(true);
  };

  const handleSummaryClick = (e, summary) => {
    e.preventDefault();
    if (summary === "more") {
      setShowFullSummary(true);
    } else {
      setShowFullSummary(false);
    }
  };

  useEffect(() => {
    setAssessmentStarted(roleSkills);
  }, [roleSkills]);

  useEffect(() => {
    setCandidate(edited_data?.candidate || null);
    setResume(edited_data?.resume || null);
  }, [edited_data]);

  const handleReadLessClick = () => {
    setShowFullSummary(false);
  };
  
    const getInitials = (fullName) => {
      return fullName?.split(' ').map((name) => name[0]).join('').toUpperCase();
    };

  const fetchData = () => {
    if (data && data.candidate_guid && !candidate) {
      api_get(
        null,
        ep.assessment + "/v1/candidate/" + data.candidate_guid,
        (_candidate_err, _candidate_res) => {
          if (!_candidate_err) {
            // log_debug("UserDetail", "fetchData", "_candidate_res :: " + JSON.stringify(_candidate_res));
            setCandidate(_candidate_res.candidate);
            setResume(
              _candidate_res.candidate.resumes[
                _candidate_res.candidate.resumes.length - 1
              ]
            );

            dispatch(setuserData(_candidate_res));
          }
        }
      );
    }
  };

  useEffect(() => {
    fetchData();

    // for user profile
    $(".dripicons-user").on("click", OpenProfileSidebar);

    //
  }, []);
  useEffect(() => {
    $(".dripicons-arrow-thin-left").on("click", closeProfileSidebar);
  })

  const OpenProfileSidebar = () => {
    $(".profile_card").css("transform", "translateX(0%)");
    $(".dripicons-user").css("display", "none");
    $(".main-content").addClass("profile_open");
  };
  const closeProfileSidebar = () => {
    $(".profile_card").css("transform", "translateX(-100%)");
    $(".dripicons-user").css("display", "block");
    $(".main-content").removeClass("profile_open");
  };
  

  return (
    <>
      <i className="dripicons-user">&nbsp; User Profile</i>
      <Card className="mb-2 wrap border border-2 user_details_card">
        {candidate && (
          <CardBody className="profile_card">
            <i className="dripicons-arrow-thin-left"></i>
            {/* <Card>
                        <CardBody> */}
            {/* <Row> */}
            <div className="scrollable_profile">
              <div className="col-sm order-2 order-sm-1 mb-3">
                <div className="d-flex justify-content-center mt-3 mt-sm-0">
                  <div className="flex-shrink-0">
                    <CircularProgressbarWithChildren
                      styles={{
                        root: {
                          fontSize: "12px",
                          width: "80px",
                          height: "80px",
                        }, // Adjust these values
                        path: {
                          stroke: primaryShade2,
                          strokeLinecap: "round",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                          pathColor: primaryShade1,
                        },
                        trail: {
                          stroke: "#d6d6d6",
                          strokeLinecap: "round",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                      }}
                      value={data.value}
                    >
                      {/* <div className="avatar-xm mx-auto mb-0">
                        <span
                          className={
                            "avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle"
                          }
                        >
                          <i className="bx bxs-user-circle"></i>
                        </span>
                      </div> */}
                      <div className="avatar-xm mx-auto mb-0">
                        <span className="avatar-title bg-light text-primary font-size-24 p-3 display-3 m-0 rounded-circle" style={{ width: "65px", height: "65px" }}>
                          {getInitials(candidate.full_name)}
                        </span>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                <div className="flex-grow-1 mt-2">
                  <div>
                    {/* <p className="text-muted font-size-13">
                    Full Stack Developer
                  </p> */}
                    <h5 className="font-size-16 mb-1 align-middle text-center text-primary">
                      {candidate.full_name}
                    </h5>

                    <div className="flex-wrap text-muted font-size-11 text-center">
                      {/* <div>
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      Development
                    </div> */}

                      <span>
                        {/* <i className="mdi mdi-circle-medium text-success p-0"></i>{" "} */}
                        {candidate.email_id}
                      </span>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* </Row> */}
              {/* </CardBody>
                    </Card> */}
              { resume?.summary && (

                <div className="text-muted font-size-12">
                <p>
                  {showFullSummary
                    ? `${resume?.summary} `
                    : `${resume?.summary.substr(0, 50)}... `}
                  {!showFullSummary ? (
                    <Link
                      to="#"
                      className="text-primary"
                      onClick={(e) => {
                        handleSummaryClick(e, "more");
                      }}
                    >
                      more
                      {/* <i className="fas fa-arrow-down shadow"></i> */}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="text-primary"
                      onClick={(e) => {
                        handleSummaryClick(e, "less");
                      }}
                    >
                      less
                      {/* <i className="fas fa-arrow-up shadow"></i> */}
                    </Link>
                  )}
                </p>
              </div>
              )}
              {assessmentStarted && roleSkills.primary_skills ? (
                <>
                  <div className="mb-3">
                    <h5 className="mb-1 card-title font-size-12 ">Role</h5>
                    <p className="mb-4 font-size-11 text-primary">
                      {roleSkills?.role}
                    </p>
                    <h5 className="mb-1 card-title font-size-12">
                      Primary Skills
                    </h5>
                    <div className="d-flex flex-wrap gap-1">
                      {roleSkills?.primary_skills?.map((skill, index) => (
                        <span
                          key={index}
                          className="badge bg-secondary p-1 rounded-1"
                        >
                          {skill.title}
                        </span>
                      ))}
                    </div>
                  </div>
                  {roleSkills?.secondary_skills.length > 0 && (
                    <div className="mb-3">
                      <h5 className="mb-1 card-title font-size-12">
                        Secondary Skills
                      </h5>
                      <div className="d-flex flex-wrap gap-1">
                        {roleSkills.secondary_skills.map((skill, index) => (
                          <span
                            key={index}
                            className="badge bg-secondary p-1 rounded-1"
                          >
                            {skill.title}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="mb-3 mt-5">
                  <h5 className="card-title font-size-12">Skills</h5>
                  <p className="text-muted mb-3 font-size-10">
                    Technical Skills extracted from your resume
                  </p>
                  <div className="d-flex flex-wrap gap-1">
                    {resume?.programming_languages?.map((skill, index) => (
                      <span
                        key={index}
                        className="badge bg-secondary p-1 rounded-1"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        )}
      </Card>
      {/* <Card>
        <CardBody>
          <div className="col-sm order-2 order-sm-1 mb-3">
            <div className="d-flex align-items-start mt-3 mt-sm-0">
              <div className="flex-grow-1">
                <div>
                  <h5 className="font-size-16 mb-1">Primary Skills</h5>
                  <p className="text-muted font-size-13">Java , Python</p>

                  <h5 className="font-size-16 mb-1">Secondary Skills</h5>
                  <p className="text-muted font-size-13">-</p>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card> */}
    </>
  );
};

export default UserDetail;
