import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import validator from 'validator'

const AddCertification = ({ addCertification, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditWorkExperience', 'edit work data' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState(data);

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    const [newCertification, setNewCertification] = useState({
        certificate_name: '',
        certification_date: ''
    });
    const handleInputChange = (fieldName, value) => {

        // Validation checks
        let errors = { ...validationErrors };
        switch (fieldName) {
            case 'certificate_name':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            case 'certification_date':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            default:
                break;
        }

        setValidationErrors(errors);

        setNewCertification({
            ...newCertification,
            [fieldName]: value,
        });
    };



    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation before submission
        let errors = {};
        for (const key in newCertification) {
            if (newCertification.hasOwnProperty(key)) {
                const value = newCertification[key];
                // errors[key] = validator.isEmpty(value) ? `${key.split('_')} is required ` : '';
                errors[key] = validator.isEmpty(value) ? `Please fill this field` : '';
                if (!validator.isDate(dateyyyymmdd_tohtml(newCertification['certification_date']))) {
                    errors['certification_date'] = 'Please enter valid certification date';
                }

            }
        }
        setValidationErrors(errors);

        if (Object.values(errors).some((error) => error !== '')) {
            // If there are validation errors, do not submit
            return;
        }

        const updatedData = { ...editedData };

        if (!updatedData.resume.certifications) {
            // If certifications array doesn't exist, create an empty array
            updatedData.resume.certifications = [];
        }

        const updatedCertification = [...updatedData.resume.certifications, newCertification];
        updatedData.resume.certifications = updatedCertification;
        setEditedData(updatedData);

        // You can now use formData to send the data to your server or perform any other actions.
        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                certifications: editedData.resume.certifications,
            },
        };
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'add certification responseData::' + JSON.stringify(responseData));
                onSubmit(editedData);
                toggleRightCanvas();
            }
        });
    };


    return (
        <>
            <Offcanvas
                isOpen={addCertification}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Add Certification
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                <div>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Certificate Name <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input type="text" name='certificate_name' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" onChange={(e) => handleInputChange('certificate_name', e.target.value)} />
                                            {validationErrors.certificate_name && <small className="text-danger">{validationErrors.certificate_name}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Certificate Date <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input className="form-control" name='certification_date' type="date" id="horizontal-dob-input" onChange={(e) => handleInputChange('certification_date', e.target.value)} />
                                            {validationErrors.certification_date && <small className="text-danger">{validationErrors.certification_date}</small>}
                                        </Col>
                                    </Row>
                                </div>

                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default AddCertification
