import React,{useEffect, useState} from 'react'
import { Row, Col,CardText, Offcanvas, OffcanvasHeader, OffcanvasBody,Collapse } from 'reactstrap'
import classNames from 'classnames'
import { api_get } from '../../services/service'
import {dateyyyymmdd_tohtml} from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'

const Accordion = ({ q_no, record }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classNames("accordion-button", "fw-medium", {
              collapsed: !isOpen,
            })}
            type="button"
            onClick={toggleAccordion}
            style={{ cursor: "pointer" }}
          >
            <div className="justify-content-between d-flex align-items-center card-header">
              <h5 className='font-size-11'>
                Q{q_no + 1}. {record.question}{" "}
              </h5>

              <span className="badge badge-secondary">{record.expertise}</span>
              <span
                className={`bg-success rounded-1 badge ${
                  record.result === -1
                    ? "bg-warning"
                    : record.result === 0
                      ? "bg-danger"
                      : "bg-success"
                }`}
              >
                {record.result === 0
                  ? "Incorrect"
                  : record.result === -1
                    ? "Not Answered"
                    : "Correct"}
              </span>
            </div>
          </button>
        </h2>

        <Collapse isOpen={isOpen} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
              <p>
                <b style={{color:'#015493'}}>Submitted Answer: </b>
                <span className='font-size-12' style={{color:'#567A0D'}}>{record.submitted_answer || "-"}</span>
              </p>
              <p>
                <b style={{color:'#015493'}}>Expected Answer:</b> {record.answers}
              </p>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const LearnMoreMcq = ({isMcq, toggleLearnMore, data}) => {
  const[mcqDetails,setMcqDetails] = useState(null);

  const handleToggleCanvas = (e) => {
    e.preventDefault();
    toggleLearnMore();
}


function fetchData(){
    api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + data, (_mcq_err, _mcqResponse) => {
      if (!_mcq_err) {
        setMcqDetails(_mcqResponse.assessmentquestions);
      }
    });
}

useEffect(() => {
  fetchData();
}, [])


  return (
    <>
            <Offcanvas
                isOpen={isMcq}
                direction="end"
                toggle={(e)=>{handleToggleCanvas(e)}}
                className='w-40'
                style={{zoom:'85%'}}
            >
                <OffcanvasHeader toggle={toggleLearnMore} className='pb-1'>
                    <h5 className="font-size-16 text-primary">Mcq Details<span className='ms-1 text-muted font-size-12'>See Your Mcq Test</span></h5>
                </OffcanvasHeader>
                <OffcanvasBody>
                    {/* <Col lg={12} className="ms-lg-auto"> */}

                        <div className="mt-4 mt-lg-0">
                        <CardText className="mb-0">
                        {mcqDetails?.map((item, idx) => (
                          <div key={idx} className="border border-2 py-2 px-3 mb-2">
                               <Row className='h-stack'>
                              <div className='col-lg-9'>
                             <div className="flex-grow-1 mb-1">
                               <h5 className="font-size-14 mb-1">
                                 {item.question}
                               </h5>
                               {/* <p className="text-muted"> */}
                                 <p className="font-size-12 text-muted mb-0"><em>Submitted Answer:</em>&nbsp;&nbsp;<span className="font-size-14">{item.submitted_answer}</span></p>
                                 <p className="font-size-12 text-muted"><em>Expected Answer:</em>&nbsp;&nbsp;<span className="font-size-14">{item.answers}</span></p>
                              </div>
                              
                               {/* </p> */}
                               {/* <div>
                               <a href="javascript: void(0);" className="text-primary mb-2">
                                 Read more <i className="mdi mdi-arrow-right" />
                               </a>
                              </div> */}
                             </div>
                             <div className='col-lg-3'>
                              <span className={`badge bg-${item.result === -1 ? "warning" : item.result === 0 ? "danger" : "success"}-subtle text-muted float-end m-1`}> {item.result === 0
                                                                                                 ? "Incorrect"
                                                                                                 : item.result === -1
                                                                                                   ? "Not Answered"
                                                                                                   : "Correct"}</span>
                                 <span className="badge bg-primary-subtle text-primary float-end m-1">{item.expertise}</span>&nbsp;
                              </div>
                          </Row>
                           </div>
                            ))}
                            </CardText>
                        </div>
                    {/* </Col> */}
                    </OffcanvasBody>
                    </Offcanvas>
                  </>
  )
}

export default LearnMoreMcq