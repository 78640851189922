import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody } from 'reactstrap'
import CodeTestModal from '../modal/CodeTestModal'
import { useDispatch } from 'react-redux'
import { questions_timer } from '../../config/dev'
import { setQuestionIndex,setTimerData } from '../../store/timerSlice'

const CodingTest = ({data, codingQuestionTransition }) => {
    const dispatch = useDispatch();
    const [isCodeModalActive, setIsCodeModalActive] = useState(false);

    useEffect(()=>{
        const currentQuestionDetails={
            questionIndex:'',
            questionStatus:'',
            questionType:'coding'
       
        }
       dispatch(setQuestionIndex(currentQuestionDetails));
    })
    
    const handleCodeTestModal = (e)=>{
        if(!isCodeModalActive){
            e.preventDefault();
        }
        dispatch(setTimerData(data.questions.length * questions_timer.code_question_timer))
        setIsCodeModalActive(!isCodeModalActive);
    }

    const handleCodingTransition = (questionIndex) =>{
        codingQuestionTransition(questionIndex);
      }

    return (
        <>
            <Card>
                <CardBody className="overflow-hidden position-relative">
                    <Row>
                        <Col xl={1}>
                            <i className='fas fa-laptop-code text-primary fs-1 p-0 mt-1'></i>
                        </Col>
                        <Col xl={11}>
                             <div className="faq-count">
                                <h5 className="text-primary">Online Coding Test</h5>
                            </div>
                            {/* <p className="mt-2 mb-0">Only <span className="text-black">15%</span> of candidates successfully pass this round, emphasizing its significance in candidate selection.</p> */}
                            <p className="text-muted font-size-12 mt-1 mb-0">Remember, this coding test is designed to assess your coding abilities and problem-solving skills. <br/>Relax, focus, and do your best!</p>
                            <div>
                            <h5 className='mt-3 font-size-14'>Guidelines: </h5>
                            <ul className="list-unstyled mb-3 font-size-13">
                            {/* <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Do Not Switch Tabs between Tests.</li> */}
                            <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Test Duration:</b> You have a total of 10 minutes to complete each coding question.</li>
                            <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Question Navigation:</b> Each question will be presented one by one. Click the "Next" button to move to the next question once you have completed your code for the current one.</li>
                            <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                            <b>Hints and Recommendations:</b> Read the hints and recommendations provided for each question carefully. They will help you understand the requirements and provide useful insights to assist you
                            in writing your code.
                            </li>
                            <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                            <b>Coding Environment:</b> You will need to write and execute your code in the terminal provided alongside the question. Make sure to familiarize yourself with the terminal and its features before
                            you start.
                            </li>
                            <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                            <b>Saving Your Code:</b> After writing your code, click the "Save" or "Next" button to save your progress and move on to the next question. Your code will be automatically saved, so you can continue
                            where you left off.
                            </li>
                            {/* <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Focus on Logic:</b> Focus on writing efficient and correct code that fulfills the given requirements. Pay attention to details, edge cases, and input/output formats.</li> */}
                            {/* <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Test Integrity:</b> Ensure that you are not using external resources or assistance during the test. This is an opportunity for you to showcase your coding skills independently.</li> */}
                            {/* <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Time Management:</b> Keep an eye on the timer to manage your time effectively. Use your allocated 20 minutes wisely for each question.</li> */}
                            {/* <li className="py-1"><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i><b>Final Submission:</b> Once you have completed all the questions, review your code and answers. When you are satisfied, click the "Submit" button to finalize your test.</li> */}
                            </ul>
                            <Link type='button' to="#" className="btn btn-primary btn-sm waves-effect waves-light float-end p-2 mt-5" onClick={(e) => handleCodeTestModal(e)}> Start Coding Challenge <i className="fas fa-arrow-right align-middle ms-1"></i></Link>
                        </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <CodeTestModal isModalActive={isCodeModalActive} data={data} codingQuestionTransition={handleCodingTransition} onToggleModal={handleCodeTestModal}></CodeTestModal>

        </>

    )
}

export default CodingTest