export const roles = [
   {title:'Java Backend Developer',category:'Backend Dev',status:'',primary_1:'Java',primary_2:'Servlets',primary_3:'Hibernate',primary_4:'MYSQL',primary_5:''},
   {title:'Spring Framework Developer',category:'Backend Dev',status:'',primary_1:'Java',primary_2:'Spring',primary_3:'Spring Boot',primary_4:'Apache Kafka',primary_5:''},
   {title:'Java Microservices Developer',category:'Backend Dev',status:'',primary_1:'Java',primary_2:'Spring Boot',primary_3:'',primary_4:'',primary_5:''},
   {title:'Java API Developer',category:'Backend Dev',status:'',primary_1:'Java',primary_2:'Spring Boot',primary_3:'XML',primary_4:'Docker',primary_5:''},
   {title:'Java DevOps Engineer',category:'Backend Dev',status:'',primary_1:'Java',primary_2:'Jenkins',primary_3:'GitLab',primary_4:'Docker',primary_5:''},
   {title:'Python Backend Developer',category:'Backend Dev',status:'',primary_1:'Python',primary_2:'Flask',primary_3:'Django',primary_4:'',primary_5:''},
   {title:'Django Developer',category:'Backend Dev',status:'',primary_1:'Python',primary_2:'Django',primary_3:'SQLAlchemy',primary_4:'Django ORM',primary_5:''},
   {title:'Flask Developer',category:'Backend Dev',status:'',primary_1:'Python',primary_2:'Flask',primary_3:'FastAPI',primary_4:'',primary_5:''},
   {title:'Python API Developer',category:'Backend Dev',status:'',primary_1:'Python',primary_2:'Django',primary_3:'XML',primary_4:'Docker',primary_5:''},
   {title:'Python Data Engineer',category:'Backend Dev',status:'',primary_1:'Python',primary_2:'Flask',primary_3:'Matplotlib',primary_4:'',primary_5:''},
   {title:'Node.js Backend Developer',category:'Backend Dev',status:'',primary_1:'NodeJS',primary_2:'MongoDB',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'Express.js Developer',category:'Backend Dev',status:'',primary_1:'NodeJS',primary_2:'ExpressJS',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'Node.js API Developer',category:'Backend Dev',status:'',primary_1:'NodeJS',primary_2:'ExpressJS',primary_3:'JWT',primary_4:'Postman',primary_5:''},
   {title:'Node.js Microservices Developer',category:'Backend Dev',status:'',primary_1:'NodeJS',primary_2:'ExpressJS',primary_3:'JWT',primary_4:'Docker',primary_5:''},
   {title:'Node.js DevOps Engineer',category:'Backend Dev',status:'',primary_1:'NodeJS',primary_2:'Jenkins',primary_3:'JavaScript',primary_4:'Puppet',primary_5:'Docker'},
   {title:'Ruby Backend Developer',category:'Backend Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'Redis',primary_4:'Chef',primary_5:''},
   {title:'Ruby on Rails Developer',category:'Backend Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'PostgreSQL',primary_4:'Resque',primary_5:''},
   {title:'Ruby API Developer',category:'Backend Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'Swagger',primary_4:'Docker',primary_5:''},
   {title:'Ruby Microservices Developer',category:'Backend Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'Docker',primary_4:'',primary_5:''},
   {title:'Ruby DevOps Engineer',category:'Backend Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'Docker',primary_4:'',primary_5:''},
   {title:'PHP Backend Developer',category:'Backend Dev',status:'',primary_1:'PHP',primary_2:'Symfony',primary_3:'MYSQL',primary_4:'',primary_5:''},
   {title:'Laravel Developer',category:'Backend Dev',status:'',primary_1:'Laravel',primary_2:'PHP',primary_3:'MySQL',primary_4:'',primary_5:''},
   {title:'Symfony Developer',category:'Backend Dev',status:'',primary_1:'Symfony',primary_2:'PHP',primary_3:'MySQL',primary_4:'',primary_5:''},
   {title:'PHP API Developer',category:'Backend Dev',status:'',primary_1:'PHP',primary_2:'Laravel',primary_3:'',primary_4:'Docker',primary_5:''},
   {title:'PHP DevOps Engineer',category:'Backend Dev',status:'',primary_1:'PHP',primary_2:'Docker',primary_3:'Jenkins',primary_4:'',primary_5:''},
   {title:'C# Backend Developer',category:'Backend Dev',status:'',primary_1:'C#',primary_2:'.NET Framework',primary_3:'Dapper',primary_4:'',primary_5:''},
   {title:'ASP.NET Developer',category:'Backend Dev',status:'',primary_1:'C#',primary_2:'.NET Framework',primary_3:'SQL Server',primary_4:'',primary_5:''},
   {title:'.NET Core Developer',category:'Backend Dev',status:'',primary_1:'C#',primary_2:'.NET Framework',primary_3:'Docker',primary_4:'',primary_5:''},
   {title:'C# API Developer',category:'Backend Dev',status:'',primary_1:'C#',primary_2:'.NET Framework',primary_3:'',primary_4:'Docker',primary_5:''},
   {title:'C# Microservices Developer',category:'Backend Dev',status:'',primary_1:'C#',primary_2:'.NET Framework',primary_3:'IIS',primary_4:'',primary_5:''},
   {title:'Go Backend Developer',category:'Backend Dev',status:'',primary_1:'Go',primary_2:'Revel',primary_3:'MYSQL',primary_4:'Godep',primary_5:''},
   {title:'Go Microservices Developer',category:'Backend Dev',status:'',primary_1:'Go',primary_2:'Revel',primary_3:'Gin',primary_4:'Docker',primary_5:''},
   {title:'Go API Developer',category:'Backend Dev',status:'',primary_1:'Go',primary_2:'Revel',primary_3:'Swagger',primary_4:'',primary_5:''},
   {title:'Go DevOps Engineer',category:'Backend Dev',status:'',primary_1:'Go',primary_2:'Revel',primary_3:'Jenkins',primary_4:'Docker',primary_5:''},
   {title:'Go Cloud Engineer',category:'Backend Dev',status:'',primary_1:'Go',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Scala Backend Developer',category:'Backend Dev',status:'',primary_1:'Scala',primary_2:'Play',primary_3:'Slick',primary_4:'',primary_5:''},
   {title:'Akka Developer',category:'Backend Dev',status:'',primary_1:'Akka',primary_2:'Scala',primary_3:'Slick',primary_4:'',primary_5:''},
   {title:'Play Framework Developer',category:'Backend Dev',status:'',primary_1:'Play',primary_2:'Scala',primary_3:'Slick',primary_4:'',primary_5:''},
   {title:'Scala API Developer',category:'Backend Dev',status:'',primary_1:'Scala',primary_2:'Play',primary_3:'',primary_4:'Docker',primary_5:''},
   {title:'Scala Microservices Developer',category:'Backend Dev',status:'',primary_1:'Scala',primary_2:'Play',primary_3:'',primary_4:'',primary_5:''},
   {title:'Rust Backend Developer',category:'Backend Dev',status:'',primary_1:'Rust',primary_2:'Rocket',primary_3:'Diesel',primary_4:'',primary_5:''},
   {title:'Rust API Developer',category:'Backend Dev',status:'',primary_1:'Rust',primary_2:'Actix',primary_3:'Diesel',primary_4:'Docker',primary_5:''},
   {title:'Rust Systems Developer',category:'Backend Dev',status:'',primary_1:'Rust',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Rust Network Developer',category:'Backend Dev',status:'',primary_1:'Rust',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Rust Blockchain Developer',category:'Backend Dev',status:'',primary_1:'Rust',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Kotlin Backend Developer',category:'Backend Dev',status:'',primary_1:'Kotlin',primary_2:'Android',primary_3:'Java',primary_4:'XML',primary_5:''},
   {title:'Spring Boot Developer (Kotlin)',category:'Backend Dev',status:'',primary_1:'Kotlin',primary_2:'Spring Boot',primary_3:'Java',primary_4:'',primary_5:''},
   {title:'Ktor Developer',category:'Backend Dev',status:'',primary_1:'Ktor',primary_2:'Kotlin',primary_3:'Java',primary_4:'',primary_5:''},
   {title:'Kotlin API Developer',category:'Backend Dev',status:'',primary_1:'Kotlin',primary_2:'Ktor',primary_3:'',primary_4:'Docker',primary_5:''},
   {title:'Kotlin Microservices Developer',category:'Backend Dev',status:'',primary_1:'Kotlin',primary_2:'Ktor',primary_3:'',primary_4:'',primary_5:''},
   {title:'JavaScript Frontend Developer',category:'Frontend Dev',status:'',primary_1:'JavaScript',primary_2:'HTML',primary_3:'CSS',primary_4:'',primary_5:''},
   {title:'React.js Developer',category:'Frontend Dev',status:'',primary_1:'ReactJS',primary_2:'JavaScript',primary_3:'HTML',primary_4:'CSS',primary_5:'Redux'},
   {title:'Angular Developer',category:'Frontend Dev',status:'',primary_1:'AngularJS',primary_2:'TypeScript',primary_3:'JavaScript',primary_4:'HTML',primary_5:'CSS'},
   {title:'Vue.js Developer',category:'Frontend Dev',status:'',primary_1:'VueJS',primary_2:'JavaScript',primary_3:'VueX',primary_4:'TypeScript',primary_5:''},
   {title:'Ember.js Developer',category:'Frontend Dev',status:'',primary_1:'EmberJS',primary_2:'JavaScript',primary_3:'HTML',primary_4:'CSS',primary_5:''},
   {title:'TypeScript Frontend Developer',category:'Frontend Dev',status:'',primary_1:'TypeScript',primary_2:'HTML',primary_3:'CSS',primary_4:'AngularJS',primary_5:''},
   {title:'React.js Developer (TypeScript)',category:'Frontend Dev',status:'',primary_1:'ReactJS',primary_2:'TypeScript',primary_3:'HTML',primary_4:'CSS',primary_5:'Redux'},
   {title:'Angular Developer (TypeScript)',category:'Frontend Dev',status:'',primary_1:'AngularJS',primary_2:'TypeScript',primary_3:'JavaScript',primary_4:'HTML',primary_5:'CSS'},
   {title:'Vue.js Developer (TypeScript)',category:'Frontend Dev',status:'',primary_1:'VueJS',primary_2:'TypeScript',primary_3:'VueX',primary_4:'JavaScript',primary_5:''},
   {title:'Ember.js Developer (TypeScript)',category:'Frontend Dev',status:'',primary_1:'EmberJS',primary_2:'TypeScript',primary_3:'HTML',primary_4:'CSS',primary_5:''},
   {title:'HTML/CSS Frontend Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'JavaScript',primary_4:'Bootstrap',primary_5:''},
   {title:'UI/UX Designer',category:'Frontend Dev',status:'',primary_1:'Adobe XD',primary_2:'Figma',primary_3:'Lucidchart',primary_4:'Adobe Photoshop',primary_5:''},
   {title:'Frontend Web Designer',category:'Frontend Dev',status:'',primary_1:'Adobe Illustrator',primary_2:'Figma',primary_3:'Adobe Photoshop',primary_4:'HTML',primary_5:'CSS'},
   {title:'HTML Email Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'Litmus',primary_4:'Adobe Photoshop',primary_5:'Velocity Scripting'},
   {title:'Responsive Web Designer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'Media Query',primary_4:'Bootstrap',primary_5:'Axe'},
   {title:'WebAssembly Frontend Developer',category:'Frontend Dev',status:'',primary_1:'WebAssembly',primary_2:'Emscripten',primary_3:'Rust',primary_4:'JavaScript',primary_5:''},
   {title:'Rust Web Developer (WebAssembly)',category:'Frontend Dev',status:'',primary_1:'WebAssembly',primary_2:'Rust',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'C++ Web Developer (WebAssembly)',category:'Frontend Dev',status:'',primary_1:'WebAssembly',primary_2:'C++',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'AssemblyScript Developer',category:'Frontend Dev',status:'',primary_1:'AssemblyScript',primary_2:'WebAssembly',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'WebAssembly Graphics Developer',category:'Frontend Dev',status:'',primary_1:'WebAssembly',primary_2:'OpenGL',primary_3:'Vulkan',primary_4:'DirectX',primary_5:''},
   {title:'Frontend Framework Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'ReactJS',primary_3:'AngularJS',primary_4:'JavaScript',primary_5:''},
   {title:'Material-UI Developer',category:'Frontend Dev',status:'',primary_1:'Material-UI',primary_2:'HTML',primary_3:'CSS',primary_4:'JavaScript',primary_5:''},
   {title:'Bootstrap Developer',category:'Frontend Dev',status:'',primary_1:'Bootstrap',primary_2:'HTML',primary_3:'CSS',primary_4:'JavaScript',primary_5:''},
   {title:'Foundation Developer',category:'Frontend Dev',status:'',primary_1:'Foundation',primary_2:'HTML',primary_3:'CSS',primary_4:'JavaScript',primary_5:''},
   {title:'Bulma Developer',category:'Frontend Dev',status:'',primary_1:'Bulma',primary_2:'HTML',primary_3:'CSS',primary_4:'JavaScript',primary_5:''},
   {title:'Mobile Web Frontend Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'Bootstrap',primary_4:'',primary_5:''},
   {title:'Progressive Web App (PWA) Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'AMP Developer (Accelerated Mobile Pages)',category:'Frontend Dev',status:'',primary_1:'AMP HTML',primary_2:'HTML',primary_3:'CSS',primary_4:'',primary_5:''},
   {title:'Mobile-First Web Developer',category:'Frontend Dev',status:'',primary_1:'HTML',primary_2:'CSS',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'Hybrid App Developer',category:'Frontend Dev',status:'',primary_1:'React Native',primary_2:'Ionic',primary_3:'HTML',primary_4:'CSS',primary_5:''},
   {title:'DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Linux',primary_2:'Jenkins',primary_3:'Chef',primary_4:'Docker',primary_5:''},
   {title:'Infrastructure Engineer',category:'Dev-ops',status:'',primary_1:'Terraform',primary_2:'Azure DevOps',primary_3:'Chef',primary_4:'Docker',primary_5:''},
   {title:'Site Reliability Engineer (SRE)',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'AWS Management Console',primary_3:'MongoDB',primary_4:'Docker',primary_5:''},
   {title:'Cloud DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'Docker',primary_3:'',primary_4:'',primary_5:''},
   {title:'Automation Engineer',category:'Dev-ops',status:'',primary_1:'Ansible',primary_2:'Git',primary_3:'',primary_4:'',primary_5:''},
   {title:'Kubernetes Engineer',category:'Dev-ops',status:'',primary_1:'Kubernetes',primary_2:'Kubeadm',primary_3:'Prometheus',primary_4:'Nginx',primary_5:''},
   {title:'Docker Engineer',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Docker Hub',primary_3:'Prometheus',primary_4:'',primary_5:''},
   {title:'Containerization Specialist',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Kubernetes',primary_3:'Prometheus',primary_4:'',primary_5:''},
   {title:'DevOps Container Engineer',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Kubernetes',primary_3:'GitLab CI/CD',primary_4:'Jenkins',primary_5:''},
   {title:'Container Orchestration Engineer',category:'Dev-ops',status:'',primary_1:'Kubernetes',primary_2:'Helm',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS DevOps Engineer',category:'Dev-ops',status:'',primary_1:'AWS Cloudformation',primary_2:'AWS CodeBuild',primary_3:'Amazon Lambda',primary_4:'Jenkins',primary_5:'Docker'},
   {title:'Azure DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Azure DevOps',primary_2:'Azure Resource Manager',primary_3:'Azure SQL',primary_4:'Jenkins',primary_5:'Docker'},
   {title:'Google Cloud DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Google Cloud Deployment Manager',primary_2:'Google Cloud Firestore',primary_3:'Google Kubernetes Engine (GKE)',primary_4:'Jenkins',primary_5:'Docker'},
   {title:'Cloud Automation Specialist',category:'Dev-ops',status:'',primary_1:'Terraform',primary_2:'Docker',primary_3:'Puppet',primary_4:'',primary_5:''},
   {title:'Multi-Cloud DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Kubernetes',primary_3:'',primary_4:'',primary_5:''},
   {title:'CI/CD Engineer',category:'Dev-ops',status:'',primary_1:'GitLab CI/CD',primary_2:'GitHub',primary_3:'Docker',primary_4:'JFrog',primary_5:''},
   {title:'Build and Release Engineer',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'GitLab CI/CD',primary_3:'Git',primary_4:'Python',primary_5:'Docker'},
   {title:'DevOps CI/CD Specialist',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'GitLab CI/CD',primary_3:'Docker',primary_4:'Terraform',primary_5:'Git'},
   {title:'Jenkins Engineer',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'Git',primary_3:'Maven',primary_4:'',primary_5:''},
   {title:'GitLab CI/CD Engineer',category:'Dev-ops',status:'',primary_1:'GitLab CI/CD',primary_2:'Git',primary_3:'Terraform',primary_4:'Docker',primary_5:''},
   {title:'Configuration Management Engineer',category:'Dev-ops',status:'',primary_1:'Puppet',primary_2:'Terraform',primary_3:'Python',primary_4:'Git',primary_5:''},
   {title:'Ansible Engineer',category:'Dev-ops',status:'',primary_1:'Ansible',primary_2:'Python',primary_3:'',primary_4:'',primary_5:''},
   {title:'Puppet Engineer',category:'Dev-ops',status:'',primary_1:'Puppet',primary_2:'Python',primary_3:'',primary_4:'',primary_5:''},
   {title:'Chef Engineer',category:'Dev-ops',status:'',primary_1:'Chef',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'SaltStack Engineer',category:'Dev-ops',status:'',primary_1:'SaltStack',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'DevOps Monitoring Engineer',category:'Dev-ops',status:'',primary_1:'Prometheus',primary_2:'Tableau',primary_3:'',primary_4:'',primary_5:''},
   {title:'Observability Engineer',category:'Dev-ops',status:'',primary_1:'Elasticsearch',primary_2:'Logstash',primary_3:'Kibana',primary_4:'Tableau',primary_5:''},
   {title:'Logging and Monitoring Specialist',category:'Dev-ops',status:'',primary_1:'Splunk',primary_2:'Elasticsearch',primary_3:'Logstash',primary_4:'Kibana',primary_5:'Tableau'},
   {title:'Splunk Engineer',category:'Dev-ops',status:'',primary_1:'Splunk',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ELK Stack Engineer',category:'Dev-ops',status:'',primary_1:'Elasticsearch',primary_2:'Logstash',primary_3:'Kibana',primary_4:'JavaScript',primary_5:'Git'},
   {title:'DevSecOps Engineer',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'GitLab CI/CD',primary_3:'Kubernetes',primary_4:'Git',primary_5:''},
   {title:'Security Automation Engineer',category:'Dev-ops',status:'',primary_1:'Ansible',primary_2:'PowerShell',primary_3:'Git',primary_4:'',primary_5:''},
   {title:'DevOps Security Specialist',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Jenkins',primary_3:'Terraform',primary_4:'GitLab CI/CD',primary_5:'Git'},
   {title:'Security Infrastructure Engineer',category:'Dev-ops',status:'',primary_1:'',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Secure Deployment Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Docker',primary_3:'Jenkins',primary_4:'',primary_5:''},
   {title:'MEAN Stack Developer',category:'Full Stack Dev',status:'',primary_1:'MongoDB',primary_2:'ExpressJS',primary_3:'AngularJS',primary_4:'NodeJS',primary_5:''},
   {title:'MERN Stack Developer',category:'Full Stack Dev',status:'',primary_1:'MongoDB',primary_2:'ExpressJS',primary_3:'ReactJS',primary_4:'NodeJS',primary_5:''},
   {title:'Java Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'Java',primary_2:'Spring Boot',primary_3:'SQL',primary_4:'AngularJS',primary_5:''},
   {title:'Python Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'Python',primary_2:'Django',primary_3:'SQLite',primary_4:'JQuery',primary_5:''},
   {title:'.NET Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'C#',primary_2:'ASP.NET',primary_3:'SQL Server',primary_4:'',primary_5:''},
   {title:'Ruby on Rails Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'Ruby',primary_2:'Rails',primary_3:'Redis',primary_4:'',primary_5:''},
   {title:'PHP Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'PHP',primary_2:'MySQL',primary_3:'Laravel',primary_4:'',primary_5:''},
   {title:'JavaScript Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'JavaScript',primary_2:'NodeJS',primary_3:'SQL',primary_4:'',primary_5:''},
   {title:'Angular Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'AngularJS',primary_2:'TypeScript',primary_3:'NodeJS',primary_4:'',primary_5:''},
   {title:'React Fullstack Developer',category:'Full Stack Dev',status:'',primary_1:'ReactJS',primary_2:'NodeJS',primary_3:'JavaScript',primary_4:'',primary_5:''},
   {title:'Salesforce Administrator',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Data Loader',primary_3:'AppExchange',primary_4:'Salesforce Reports and Dashboards',primary_5:''},
   {title:'Salesforce Developer',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Apex',primary_3:'Lightning Web Components',primary_4:'Aura Framework',primary_5:'SOQL '},
   {title:'Salesforce Consultant',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Sales Cloud',primary_3:'Service Cloud',primary_4:'',primary_5:''},
   {title:'Salesforce Architect',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Sales Cloud',primary_3:'Service Cloud',primary_4:'Lightning Web Components',primary_5:''},
   {title:'Salesforce Business Analyst',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'AppExchange',primary_3:'Data Loader',primary_4:'LMA',primary_5:''},
   {title:'Salesforce Lightning Developer',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Lightning Web Components',primary_3:'Apex',primary_4:'JavaScript',primary_5:'Aura Framework'},
   {title:'Salesforce Integration Specialist',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Apex',primary_3:'Data Loader',primary_4:'',primary_5:''},
   {title:'Salesforce Marketing Cloud Specialist',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Marketing Cloud',primary_3:'Journey Builder',primary_4:'Email Template Builder',primary_5:'Salesforce Reports and Dashboards'},
   {title:'Salesforce CPQ Specialist',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Apex',primary_3:'Integration',primary_4:'Salesforce CPQ',primary_5:''},
   {title:'Salesforce Data Analyst',category:'',status:'',primary_1:'Salesforce Admin',primary_2:'Tableau',primary_3:'Salesforce Reports and Dashboards',primary_4:'',primary_5:''},
   {title:'ServiceNow Developer',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'JavaScript',primary_3:'JQuery',primary_4:'',primary_5:''},
   {title:'ServiceNow Administrator',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'JavaScript',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Consultant',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Architect',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Implementation Specialist',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow ITOM Developer',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow ITSM Consultant',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Integration Developer',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Platform Developer',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'ServiceNow Technical Lead',category:'',status:'',primary_1:'ServiceNow Admin',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS Cloud Architect',category:'Dev-ops',status:'',primary_1:'Amazon S3 (Simple Storage Service)',primary_2:'AWS CloudFormation',primary_3:'Apache Hadoop',primary_4:'',primary_5:''},
   {title:'AWS Solutions Architect',category:'Dev-ops',status:'',primary_1:'AWS CloudFormation',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS DevOps Engineer',category:'Dev-ops',status:'',primary_1:'AWS CodePipeline',primary_2:'Jenkins',primary_3:'AWS CloudFormation',primary_4:'Amazon Lambda',primary_5:'Docker'},
   {title:'AWS SysOps Administrator',category:'Dev-ops',status:'',primary_1:'AWS CloudFormation',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS Developer',category:'Dev-ops',status:'',primary_1:'AWS CloudFormation',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS Security Engineer',category:'Dev-ops',status:'',primary_1:'AWS CloudFormation',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AWS Data Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Pandas',primary_3:'Numpy',primary_4:'',primary_5:''},
   {title:'AWS Machine Learning Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Python Deep Learning',primary_3:'PyTorch',primary_4:'Pandas',primary_5:''},
   {title:'AWS Big Data Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Pandas',primary_3:'Numpy',primary_4:'',primary_5:''},
   {title:'AWS Network Engineer',category:'Dev-ops',status:'',primary_1:'Docker',primary_2:'Bash',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Cloud Architect',category:'Dev-ops',status:'',primary_1:'Linux',primary_2:'PowerShell',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Solutions Architect',category:'Dev-ops',status:'',primary_1:'Azure DevOps',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure DevOps Engineer',category:'Dev-ops',status:'',primary_1:'Jenkins',primary_2:'Docker',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Administrator',category:'Dev-ops',status:'',primary_1:'Azure DevOps',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Developer',category:'Dev-ops',status:'',primary_1:'Azure DevOps',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Security Engineer',category:'Dev-ops',status:'',primary_1:'',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Data Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Pandas',primary_3:'Numpy',primary_4:'',primary_5:''},
   {title:'Azure AI Engineer',category:'Dev-ops',status:'',primary_1:'Python',primary_2:'Python Deep Learning',primary_3:'PyTorch',primary_4:'',primary_5:''},
   {title:'Azure IoT Developer',category:'Dev-ops',status:'',primary_1:'PyTorch',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'Azure Networking Engineer',category:'Dev-ops',status:'',primary_1:'Azure CLI',primary_2:'Terraform',primary_3:'',primary_4:'',primary_5:''},
   {title:'Machine Learning Engineer',category:'ML',status:'',primary_1:'Python',primary_2:'Pandas',primary_3:'TensorFlow',primary_4:'Matplotlib',primary_5:'Git'},
   {title:'Data Scientist',category:'ML',status:'',primary_1:'Python',primary_2:'Matplotlib',primary_3:'Pandas',primary_4:'TensorFlow',primary_5:'Jupyter Notebook'},
   {title:'AI Research Scientist',category:'ML',status:'',primary_1:'Python',primary_2:'Numpy',primary_3:'TensorFlow',primary_4:'PyTorch',primary_5:''},
   {title:'Natural Language Processing (NLP) Engineer',category:'ML',status:'',primary_1:'Python',primary_2:'Natural Language Toolkit (NLTK)',primary_3:'Jupyter Notebook',primary_4:'Matplotlib',primary_5:''},
   {title:'Computer Vision Engineer',category:'ML',status:'',primary_1:'Python',primary_2:'TensorFlow',primary_3:'OpenCV',primary_4:'Matplotlib',primary_5:''},
   {title:'Deep Learning Engineer',category:'ML',status:'',primary_1:'Python Deep Learning',primary_2:'Matplotlib',primary_3:'Numpy',primary_4:'Pandas',primary_5:'TensorFlow'},
   {title:'Data Analyst (with ML specialization)',category:'ML',status:'',primary_1:'Python',primary_2:'Matplotlib',primary_3:'Pandas',primary_4:'Scikit-Learn',primary_5:'SQL'},
   {title:'Algorithm Engineer',category:'ML',status:'',primary_1:'Python',primary_2:'MATLAB',primary_3:'Numpy',primary_4:'Scipy',primary_5:'Scikit-Learn'},
   {title:'Machine Learning Operations (MLOps) Engineer',category:'ML',status:'',primary_1:'Docker',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'AI/ML Product Manager',category:'ML',status:'',primary_1:'Jira',primary_2:'Tableau',primary_3:'TensorFlow',primary_4:'',primary_5:''},
   {title:'Data Engineer',category:'Data Engineering',status:'',primary_1:'Apache NiFi',primary_2:'Apache Hadoop',primary_3:'MYSQL',primary_4:'SQL',primary_5:''},
   {title:'Data Warehouse Engineer',category:'Data Engineering',status:'',primary_1:'SQL Server',primary_2:'PL/SQL',primary_3:'Oracle Database',primary_4:'',primary_5:''},
   {title:'ETL Developer',category:'Data Engineering',status:'',primary_1:'Apache NiFi',primary_2:'SQL',primary_3:'Python',primary_4:'',primary_5:''},
   {title:'Big Data Engineer',category:'Data Engineering',status:'',primary_1:'Python',primary_2:'SQL',primary_3:'Talend',primary_4:'Hadoop',primary_5:'Apache Spark'},
   {title:'Cloud Data Engineer',category:'Data Engineering',status:'',primary_1:'SQL',primary_2:'Scala',primary_3:'Apache Hadoop',primary_4:'Amazon Redshift',primary_5:'AWS Glue'},
   {title:'Data Integration Engineer',category:'Data Engineering',status:'',primary_1:'SQL',primary_2:'Apache Hadoop',primary_3:'Tableau',primary_4:'MYSQL',primary_5:''},
   {title:'Data Pipeline Engineer',category:'Data Engineering',status:'',primary_1:'SQL',primary_2:'Apache NiFi',primary_3:'Apache Hadoop',primary_4:'Apache Kafka',primary_5:'Amazon Redshift'},
   {title:'Data Architect',category:'Data Engineering',status:'',primary_1:'SQL',primary_2:'MYSQL',primary_3:'erwin Data Modeler',primary_4:'Amazon Redshift',primary_5:''},
   {title:'Streaming Data Engineer',category:'Data Engineering',status:'',primary_1:'Apache Kafka',primary_2:'Java',primary_3:'Apache Hadoop',primary_4:'',primary_5:''},
   {title:'Database Engineer',category:'Data Engineering',status:'',primary_1:'SQL',primary_2:'MYSQL',primary_3:'Lucidchart',primary_4:'Apache NiFi',primary_5:''},
   {title:'SAP Consultant',category:'',status:'',primary_1:'SAP ERP',primary_2:'SAP CRM (Customer Relationship Management)',primary_3:'SAP ABAP',primary_4:'SAP Business Intelligence (BI)',primary_5:'SAP S/4HANA'},
   {title:'SAP ABAP Developer',category:'',status:'',primary_1:'SAP ABAP',primary_2:'SAPUI5',primary_3:'SAP ABAP Workbench',primary_4:'',primary_5:''},
   {title:'SAP Functional Analyst',category:'',status:'',primary_1:'SAP Controlling (CO)',primary_2:'',primary_3:'',primary_4:'',primary_5:''},
   {title:'SAP Basis Administrator',category:'',status:'',primary_1:'SAP Solution Manager',primary_2:'SAP MC (Management Console)',primary_3:'SAP HANA Cockpit',primary_4:'',primary_5:''},
   {title:'SAP Security Analyst',category:'',status:'',primary_1:'SAP GRC',primary_2:'SAP CVA (Code Vulnerability Analyzer)',primary_3:'',primary_4:'',primary_5:''},
   {title:'SAP Fiori Developer',category:'',status:'',primary_1:'SAP Fiori',primary_2:'SAPUI5',primary_3:'HTML',primary_4:'JavaScript',primary_5:'Git'},
   {title:'SAP BI/BW Consultant',category:'',status:'',primary_1:'SAP Business Intelligence (BI)',primary_2:'SAP Business Warehouse (BW)',primary_3:'SAP Business Explorer (BEx)',primary_4:'Git',primary_5:''},
   {title:'SAP HANA Developer',category:'',status:'',primary_1:'SAP HANA',primary_2:'SQL',primary_3:'SAP Analytics Cloud (SAC)',primary_4:'Git',primary_5:''},
   {title:'SAP SD Consultant',category:'',status:'',primary_1:'SAP ERP',primary_2:'SAP CRM (Customer Relationship Management)',primary_3:'SAP Business Intelligence (BI)',primary_4:'SAP LSMW (Legacy System Migration Workbench)',primary_5:''},
   {title:'SAP MM Consultant',category:'',status:'',primary_1:'SAP MM (Materials Management)',primary_2:'SAP ERP',primary_3:'SAP LSMW (Legacy System Migration Workbench)',primary_4:'SAP Business Intelligence (BI)',primary_5:''},
   {title:'Android Developer',category:'',status:'',primary_1:'Java',primary_2:'Android',primary_3:'Android Studio',primary_4:'',primary_5:''},
   {title:'Oracle Support Engineer',category:'',status:'',primary_1:'.NET Fullstack',primary_2:'SQL',primary_3:'Oracle Database',primary_4:'',primary_5:''},
   {title:'Sharepoint Online Developer',category:'',status:'',primary_1:'C#',primary_2:'PowerShell',primary_3:'.NET Fullstack',primary_4:'JavaScript',primary_5:''},
   {title:'Power Bi Developer',category:'',status:'',primary_1:'Microsoft Power BI',primary_2:'SQL',primary_3:'MYSQL',primary_4:'MDX',primary_5:''},
//  { title: "Other", category: "Other", status: ""},
];
