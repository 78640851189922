export const cloud_tech = [
 { title: "Amazon EC2 (Elastic Cloud computing)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-ec2-elastic-cloud-computing.png", status: "Cloud" },
 { title: "Amazon RDS (Relational Database Services)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-rds-relational-database-services.png", status: "Cloud" },
 { title: "Bonus Service: Amazon Connect", category: "AWS", icon: "assets/icons/img/cloud-tech/bonus-service-amazon-connect.png", status: "" },
 { title: "Amazon S3 (Simple Storage Service)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-s3-simple-storage-service.png", status: "" },
 { title: "Amazon Lambda", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-lambda.png", status: "" },
 { title: "Amazon Cognito", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-cognito.png", status: "" },
 { title: "Amazon Glacier", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-glacier.png", status: "" },
 { title: "Amazon SNS (Simple Notification Service)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-sns-simple-notification-service.png", status: "" },
 { title: "Amazon Lex", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-lex.png", status: "" },
 { title: "Amazon Lightsail", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-lightsail.png", status: "" },
 { title: "Amazon VPC (Virtual Private Cloud)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-vpc-virtual-private-cloud.png", status: "" },
 { title: "Amazon Kinesis", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-kinesis.png", status: "" },
 { title: "Amazon Inspector", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-inspector.png", status: "" },
 { title: "Amazon Auto-scaling", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-auto-scaling.png", status: "" },
 { title: "Amazon IAM (Identity and Access Management)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-iam-identity-and-access-management.png", status: "" },
 { title: "Dynamo DB", category: "AWS", icon: "assets/icons/img/cloud-tech/dynamo-db.png", status: "Database" },
 { title: "Amazon SQS (Simple Queue Service)", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-sqs-simple-queue-service.png", status: "" },
 { title: "Amazon ElastiCache", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-elasticache.png", status: "" },
 { title: "Amazon Chime", category: "AWS", icon: "assets/icons/img/cloud-tech/amazon-chime.png", status: "" },
 { title: "AWS Athena", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-athena.png", status: "" },
 { title: "CodeCatalyst", category: "AWS", icon: "assets/icons/img/cloud-tech/codecatalyst.png", status: "" },
 { title: "Web Application Firewall", category: "AWS", icon: "assets/icons/img/cloud-tech/web-application-firewall.png", status: "" },
 { title: "AWS Amplify", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-amplify.png", status: "" },
 { title: "AWS Rekognition", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-rekognition.png", status: "" },
 { title: "AWS QuickSight", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-quicksight.png", status: "" },
 { title: "AWS Cloudformation", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-cloudformation.png", status: "" },
 { title: "AWS Management Console", category: "AWS", icon: "assets/icons/img/cloud-tech/aws-management-console.png", status: "" },
 { title: "Azure Active Directory", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-active-directory.png", status: "" },
 { title: "Azure CDN", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-cdn.png", status: "" },
 { title: "Azure Data Factory", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-data-factory.png", status: "" },
 { title: "Azure SQL", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-sql.png", status: "Database" },
 { title: "Azure Function", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-function.png", status: "" },
 { title: "CosmosDB", category: "Azure", icon: "assets/icons/img/cloud-tech/cosmosdb.png", status: "Database" },
 { title: "DevOps", category: "Azure", icon: "assets/icons/img/cloud-tech/devops.png", status: "" },
 { title: "Azure Backup", category: "Azure", icon: "assets/icons/img/cloud-tech/azure-backup.png", status: "" },
 { title: "Logic Apps", category: "Azure", icon: "assets/icons/img/cloud-tech/logic-apps.png", status: "" },
 { title: "Virtual Machine", category: "Azure", icon: "assets/icons/img/cloud-tech/virtual-machine.png", status: "" },
 { title: "Google Compute Engine", category: "GCP", icon: "assets/icons/img/cloud-tech/google-compute-engine.png", status: "" },
 { title: "Google Cloud Deployment Manager", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-deployment-manager.png", status: "" },
 { title: "Google Kubernetes Engine (GKE)", category: "GCP", icon: "assets/icons/img/cloud-tech/google-kubernetes-engine-gke.png", status: "" },
 { title: "Google Cloud Storage", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-storage.png", status: "" },
 { title: "Google BigQuery", category: "GCP", icon: "assets/icons/img/cloud-tech/google-bigquery.png", status: "" },
 { title: "Google Cloud Pub/Sub", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-pub/sub.png", status: "" },
 { title: "Google Cloud Firestore", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-firestore.png", status: "" },
 { title: "Google Cloud Functions", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-functions.png", status: "" },
 { title: "Google Cloud Dataflow", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-dataflow.png", status: "" },
 { title: "Google Cloud Machine Learning Engine", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-machine-learning-engine.png", status: "" },
 { title: "Google Cloud AI Platform", category: "GCP", icon: "assets/icons/img/cloud-tech/google-cloud-ai-platform.png", status: "" },
 { title: "Oracle Compute", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-compute.png", status: "" },
 { title: "Oracle Container Engine for Kubernetes", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-container-engine-for-kubernetes.png", status: "" },
 { title: "Oracle Cloud Storage", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-cloud-storage.png", status: "" },
 { title: "Oracle Autonomous Database", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-autonomous-database.png", status: "" },
 { title: "Oracle Integration", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-integration.png", status: "" },
 { title: "Oracle Cloud Functions", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-cloud-functions.png", status: "" },
 { title: "Oracle Data Science", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-data-science.png", status: "" },
 { title: "Oracle Analytics Cloud", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-analytics-cloud.png", status: "" },
 { title: "Oracle Cloud Infrastructure Networking", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-cloud-infrastructure-networking.png", status: "" },
 { title: "Oracle Cloud Identity and Access Management", category: "Oracle Cloud", icon: "assets/icons/img/cloud-tech/oracle-cloud-identity-and-access-management.png", status: "" },
 { title: "AWS CodePipeline", category: "AWS", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "AWS CodeBuild", category: "AWS", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "AWS CloudFormation", category: "AWS", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Azure Resource Manager", category: "Azure", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "AWS Glue", category: "AWS", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Azure CLI", category: "Azure", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
];
