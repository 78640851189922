import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import validator from 'validator'
import { validateStartEndDate } from '../../helpers/date'


const AddEducation = ({ addEducation, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditWorkExperience', 'edit work data' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState(data);

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    const [newEducation, setNewEducation] = useState({
        college_or_university_name: '',
        degree_name: '',
        from_date: '',
        to_date: '',
    });
    const handleInputChange = (fieldName, value) => {

        // Validation checks
        let errors = { ...validationErrors };
        switch (fieldName) {
            case 'college_or_university_name':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            case 'degree_name':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            case 'from_date':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            case 'to_date':
                errors[fieldName] = validator.isEmpty(value) ? 'Please fill this field' : '';
                break;
            default:
                break;
        }

        setValidationErrors(errors);


        setNewEducation({
            ...newEducation,
            [fieldName]: value,
        });
    };



    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        toggleRightCanvas();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation before submission
        let errors = {};
        for (const key in newEducation) {
            if (newEducation.hasOwnProperty(key)) {
                const value = newEducation[key];
                errors[key] = validator.isEmpty(value) ? `Please fill this field` : '';

                if (!validator.isDate(dateyyyymmdd_tohtml(newEducation['from_date']))) {
                    errors['to_date'] = 'Please enter valid start date';
                }
                if (!validator.isDate(dateyyyymmdd_tohtml(newEducation['to_date']))) {
                    errors['to_date'] = 'Please enter valid end date';
                }
                if (!validateStartEndDate(newEducation['from_date'], newEducation['to_date'])) {
                    errors['from_date'] = 'Start Date can not be Greater than End Date';
                }
            }
        }
        setValidationErrors(errors);

        if (Object.values(errors).some((error) => error !== '')) {
            // If there are validation errors, do not submit
            return;
        }

        const updatedData = { ...editedData };
        if (!updatedData.resume.educational_qualifications) {
            // If educational_qualifications array doesn't exist, create an empty array
            updatedData.resume.educational_qualifications = [];
        }

        const updatedEducation = [...updatedData?.resume?.educational_qualifications, newEducation];
        updatedData.resume.educational_qualifications = updatedEducation;
        setEditedData(updatedData);

        // You can now use formData to send the data to your server or perform any other actions.
        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                educational_qualifications: editedData.resume.educational_qualifications,
            },
        };
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'responseData::' + JSON.stringify(responseData));
                onSubmit(editedData);
                toggleRightCanvas();
            }
        });
    };

    return (
        <>
            <Offcanvas
                isOpen={addEducation}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Add Education
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>


                                <div>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Degree <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input type="text" name='degree_name' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" onChange={(e) => handleInputChange('degree_name', e.target.value)} />
                                            {validationErrors.degree_name && <small className="text-danger">{validationErrors.degree_name}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">University Name <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input type="text" name='college_or_university_name' className="form-control" id="horizontal-firstname-input" placeholder="Enter Company Name" onChange={(e) => handleInputChange('college_or_university_name', e.target.value)} />
                                            {validationErrors.college_or_university_name && <small className="text-danger">{validationErrors.college_or_university_name}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Start Date <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input className="form-control" name='from_date' type="date" id="horizontal-dob-input" onChange={(e) => handleInputChange('from_date', e.target.value)} />
                                            {validationErrors.from_date && <small className="text-danger">{validationErrors.from_date}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">End Date <span className='text-danger'>*</span></Label>
                                        <Col sm={9}>
                                            <Input className="form-control" name='to_date' type="date" id="horizontal-dob-input" onChange={(e) => handleInputChange('to_date', e.target.value)} />
                                            {validationErrors.to_date && <small className="text-danger">{validationErrors.to_date}</small>}
                                        </Col>
                                    </Row>
                                    {/* <hr /> */}

                                </div>

                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default AddEducation
