import React from 'react'
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { primaryShade1,secondaryShade5 } from '../../config/dev';


const DoughnutSmooth = ({value,total}) => {
  return (
    <CircularProgressbarWithChildren
    styles={{
      root: {
        fontSize: "12px",
        width: "155px",
        height: "105px",
        position:"absolue",
        marginTop: "30px"
      }, // Adjust these values
      path: {
        stroke: primaryShade1,
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s ease 0s",
        transform: "rotate(0.25turn)",
        transformOrigin: "center center",
        pathColor: secondaryShade5,
      },
      trail: {
        stroke: secondaryShade5,
        strokeLinecap: "round",
        transform: "rotate(0.25turn)",
        transformOrigin: "center center",
      },
    }}
    value={value || 0}
    maxValue={total || 100}
  > 
    <div style={{ fontSize: 16, marginTop: '100px'  }}>
        <p className='text-muted'>{value} / {total}</p>
  </div>
   {/* <div className='mx-auto'>
        <p>2 Out Of 10</p> */}
        {/* <h5>5%</h5> */}
    {/* </div> */}
  </CircularProgressbarWithChildren>
  )
}

export default DoughnutSmooth