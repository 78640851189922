import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody, } from 'reactstrap'
import { api_post } from '../../services/service'
import { dateyyyymmdd_tohtml } from '../../helpers/date'
import { log_debug } from '../../helpers/logger'
import { ep } from '../../config/dev'
import { map } from "lodash";
import { validateStartEndDate } from '../../helpers/date'
import validator from 'validator'

const EditEducation = ({ isEducation, toggleRightCanvas, data, onSubmit }) => {

    log_debug(null, 'EditEducation', 'edit education data:: ' + JSON.stringify(data));

    const [validationErrors, setValidationErrors] = useState({});
    const [editedData, setEditedData] = useState({
        resume: {
            educational_qualifications: [],
        },
    });

    // Effect to update the edited data when the provided data changes
    useEffect(() => {
        setEditedData({
            resume: { ...data?.resume }
        });
    }, [data?.resume]);

    // Handler for input changes in the form
    const handleInputChange = (index, field, value) => {

        // Validation checks
        let updatedErrors = { ...validationErrors };
        let currentErrors = updatedErrors[index] || {};

        switch (field) {
            case 'degree_name':
                currentErrors[field] = validator.isEmpty(value) ? 'Degree name is required' : '';
                break;
            case 'college_or_university_name':
                currentErrors[field] = validator.isEmpty(value) ? 'University name is required' : '';
                break;
            case 'from_date':
                currentErrors[field] = validator.isEmpty(value) ? 'Start date is required' : '';
                break;
            case 'to_date':
                currentErrors[field] = validator.isEmpty(value) ? 'End date is required' : '';
                break;
            default:
                break;
        }

        updatedErrors[index] = currentErrors;
        setValidationErrors(updatedErrors);

        setEditedData((prevData) => {
            const updatedEducation = [...prevData.resume.educational_qualifications];
            if (updatedEducation[index]) {
                updatedEducation[index] = {
                    ...updatedEducation[index],
                    [field]: value,
                };
            }
            return {
                ...prevData,
                resume: {
                    ...prevData.resume,
                    educational_qualifications: updatedEducation,
                },
            };
        });
    };

    // Handler for toggling the Offcanvas
    const handleToggleCanvas = (e) => {
        e.preventDefault();
        // Clear validation errors when the component is closed
        setValidationErrors({});
    }

    // Handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        editedData.resume.educational_qualifications.forEach((edu, index) => {
            // Initialize errors for the current work experience
            let experienceErrors = {};
            let degree_name = edu.degree_name || '';
            let college_or_university_name = edu.college_or_university_name || '';
            let from_date = edu.from_date || '';
            let to_date = edu.to_date || '';
            // Set errors for the current field in the current work experience

            experienceErrors['degree_name'] = validator.isEmpty(degree_name) ? 'Degree name is required' : '';
            experienceErrors['college_or_university_name'] = validator.isEmpty(college_or_university_name) ? 'University name is required' : '';
            experienceErrors['from_date'] = validator.isEmpty(from_date) ? 'Start date is required' : '';
            experienceErrors['to_date'] = validator.isEmpty(to_date) ? 'End date is required' : '';

            if (from_date) {
                if (!validator.isDate(dateyyyymmdd_tohtml(from_date))) {
                    experienceErrors['from_date'] = 'Please enter valid start date';
                }
            }

            if (to_date) {
                if (!validator.isDate(dateyyyymmdd_tohtml(to_date))) {
                    experienceErrors['to_date'] = 'Please enter valid end date';
                }
            }

            if (!validateStartEndDate(from_date, to_date)) {
                experienceErrors['from_date'] = 'Start Date can not be greater than End Date';
            }

            // Set errors for the current work experience in the main errors object
            errors[index] = experienceErrors;
        });

        setValidationErrors(errors);

        if (Object.values(errors).some((experienceErrors) => Object.values(experienceErrors).some((error) => error !== ''))) {
            // If there are validation errors, do not submit
            return;
        }

        const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;

        //request body with the updated work experiences
        const requestBodyResume = {
            resume: {
                guid: editedData.resume.guid,
                work_experiences: editedData.resume.work_experiences,
            },
        };
        // API call to update the resume
        api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
            if (error) {

            } else {
                log_debug(null, 'handleSubmit', 'responseData::' + JSON.stringify(responseData));
                // Invoke the onSubmit callback with the updated data
                onSubmit(editedData);
                // Close the Offcanvas
                toggleRightCanvas();
            }
        });
    };

    return (
        <>
            <Offcanvas
                isOpen={isEducation}
                direction="end"
                toggle={(e) => { handleToggleCanvas(e) }}
                className='w-40'
                style={{ zoom: '80%' }}
            >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Edit Education
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Col lg={12} className="ms-lg-auto">
                        <div className="mt-4 mt-lg-0">

                            <Form>
                                {map(editedData?.resume.educational_qualifications, (edu, index) => (
                                    <div key={edu._id}>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-designation-input" className="col-sm-3 col-form-label">Degree Name <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input type="text" name='degree_name' className="form-control" id="horizontal-designation-input" placeholder="Enter Designation" value={edu?.degree_name || ''} onChange={(e) => handleInputChange(index, 'degree_name', e.target.value)} />
                                                {validationErrors[index]?.degree_name && <small className="text-danger">{validationErrors[index]?.degree_name}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">University Name <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input type="text" name='college_or_university_name' className="form-control" id="horizontal-firstname-input" placeholder="Enter University Name" value={edu?.college_or_university_name || ''} onChange={(e) => handleInputChange(index, 'college_or_university_name', e.target.value)} />
                                                {validationErrors[index]?.college_or_university_name && <small className="text-danger">{validationErrors[index]?.college_or_university_name}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">Start Date <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input className="form-control" name='from_date' type="date" id="horizontal-dob-input" value={edu?.from_date ? dateyyyymmdd_tohtml(edu.from_date) : ''} onChange={(e) => handleInputChange(index, 'from_date', e.target.value)} />
                                                {validationErrors[index]?.from_date && <small className="text-danger">{validationErrors[index]?.from_date}</small>}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Label htmlFor="horizontal-dob-input" className="col-sm-3 col-form-label">End Date <span className='text-danger'>*</span></Label>
                                            <Col sm={9}>
                                                <Input className="form-control" name='to_date' type="date" id="horizontal-dob-input" value={edu?.to_date ? dateyyyymmdd_tohtml(edu.to_date) : ''} onChange={(e) => handleInputChange(index, 'to_date', e.target.value)} />
                                                {validationErrors[index]?.to_date && <small className="text-danger">{validationErrors[index]?.to_date}</small>}
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                ))}

                                <Row className="justify-content-end">
                                    <Col sm={9}>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => { handleSubmit(e) }}>
                                                Submit
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EditEducation