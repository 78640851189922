export const java_technologies = [
 { title: "Java", category: "Programming Language", icon: "assets/icons/img/java-technologies/java.png", status: "" },
 { title: "Servlets", category: "Framework", icon: "assets/icons/img/java-technologies/servlets.png", status: "" },
 { title: "Java EE", category: "Framework", icon: "assets/icons/img/java-technologies/java-ee.png", status: "" },
 { title: "Spring", category: "Framework", icon: "assets/icons/img/java-technologies/spring.png", status: "" },
 { title: "Spring Boot", category: "Framework", icon: "assets/icons/img/java-technologies/spring-boot.png", status: "" },
 { title: "Hibernate", category: "Framework", icon: "assets/icons/img/java-technologies/hibernate.png", status: "" },
 { title: "Apache Kafka", category: "Platform / Tool", icon: "assets/icons/img/java-technologies/apache-kafka.png", status: "" },
 { title: "Scala", category: "Programming Language", icon: "assets/icons/img/java-technologies/scala.png", status: "" },
 { title: "Postman", category: "Platform / Tool", icon: "assets/icons/img/java-technologies/postman.png", status: "" },
 { title: "Swagger", category: "Platform / Tool", icon: "assets/icons/img/java-technologies/swagger.png", status: "" },
 { title: "Revel", category: "Framework", icon: "assets/icons/img/java-technologies/revel.png", status: "" },
 { title: "Godep", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Gin", category: "Framework", icon: "assets/icons/img/java-technologies/gin.png", status: "" },
 { title: "Scala", category: "Programming Language", icon: "assets/icons/img/java-technologies/scala.png", status: "" },
 { title: "Slick", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Play", category: "Framework", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Akka", category: "Platform / Tool", icon: "assets/icons/img/4075935_alarm_alert_report_warning_icon.svg", status: "" },
 { title: "Android Studio", category: "Platform / Tool", icon: "assets/icons/img/java-technologies/android-studio.png", status: "" },
 { title: "Ktor", category: "Framework", icon: "assets/icons/img/java-technologies/ktor.png", status: "" },
];
