import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import StepUserProfile from "./StepUserProfile";
import StepSkillMapping from "./StepSkillMapping";
import StepSkillTest from "./StepSkillTest";
import StepBehaviouralTest from "./StepBehaviouralTest";
import StepTalentIQScore from "./StepTalentIQScore";

import { ep, assessmentsession_steps } from "../../config/dev";
import { api_get, api_post } from "../../services/service";
import { log_debug } from "../../helpers/logger";
import { setAssessmentData } from "../../store/assessmentSlice";
import { setRoleSkills } from "../../store/userSlice";
import "../../assets/css/common.css";

//Import Icons
import FeatherIcon from "feather-icons-react";

const AssessmentSteps = ({ data }) => {
  const [roleFromUrl,setRoleFromUrl ] = useState('');

  useEffect(() => {
    if (window.location.href.split("?").length > 1) {
      let _data = {};
      let params_list = window.location.href.split("?")[1].split("&");
      params_list.forEach((_p) => {
        _data[_p.split("=")[0]] = _p.split("=")[1];
      });
      if(_data.role){
        setRoleFromUrl(_data.role);
      }else{
      }
     }
  }, []);


  const [currAssessmentSession, setCurrAssessmentSession] = useState({});
  const [guid, setGuid] = useState({
    mcq_guid: '',
    coding_guid: '',
    behavioural_guid: '',
  });

  const dispatch = useDispatch();
 
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const candidate_guid = data.candidate_guid
  
  const [selectedTab, setSelectedTab] = useState(data.step);
  const [assessmentSessionData, setAssessmentSessionData] = useState({});

  useEffect(() => {
    if (data?.guid && currAssessmentSession!=null && (data.step === 'stepskilltest' || data.step === 'stepbehavioural' || data.step === 'steptalentiqscore')) {
      let assessment_session_data = {}
      api_get(null, ep.assessment + "/v1/assessmentsession/" + data.guid, (_assessmentsession_err, _assessmentsession_res) => {
        if (_assessmentsession_err) {
          console.error('_assessmentsession_err : ',_assessmentsession_err);
        } else {
          assessment_session_data =  {
            guid:_assessmentsession_res.assessmentsession.guid,
            role: _assessmentsession_res.assessmentsession.role,
            primary_skills:_assessmentsession_res.assessmentsession.primary_skills,
            secondary_skills:_assessmentsession_res.assessmentsession.secondary_skills,
            id: _assessmentsession_res.assessmentsession._id,
          }
          dispatch(setRoleSkills({ role:assessment_session_data.role,
                                   primary_skills:assessment_session_data.primary_skills,
                                   secondary_skills:assessment_session_data.secondary_skills
          }));
          if(data.step === 'steptalentiqscore'){
            return;
          }
          // setCurrAssessmentSession((prev) => ({
          //   ...prev,
          //   guid:_assessmentsession_res.assessmentsession.guid,
          //   role: _assessmentsession_res.assessmentsession.role,
          //   primary_skills:_assessmentsession_res.assessmentsession.primary_skills,
          //   secondary_skills:_assessmentsession_res.assessmentsession.secondary_skills,
          //   id: _assessmentsession_res.assessmentsession._id,

          // }));
          api_get(null, ep.assessment + "/v1/assessment/assessmentsession/" + data.guid, (_assessmentsesserror, _assessmentsessdata) => {
            if (_assessmentsesserror) {
              console.error("API request error:", _assessmentsesserror);
            } else {
              const mcqAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "mcq")[0];
              const codingAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "coding")[0];
              const videoAssessment = _assessmentsessdata.assessments.filter((assessment) => assessment.type === "behavioural")[0];

              assessment_session_data =  {
                ...assessment_session_data,
                mcq_assessment_guid : mcqAssessment.guid,
                coding_assessment_guid : codingAssessment.guid,
                video_assessment_guid : videoAssessment.guid,
                mcq:{status : mcqAssessment.status},
                coding:{status : codingAssessment.status},
                video:{status : videoAssessment.status}
              }
              // const { mcq_guid, coding_guid, behavioural_guid } = assessment_session_data;
              // setGuid({
              //   mcq_guid,
              //   coding_guid,
              //   behavioural_guid,
              // });
              
              
              api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + mcqAssessment.guid, (_mcq_err, _mcqResponse) => {
                if (_mcq_err) {
                  console.error("API request error:", _mcq_err);
                } else {
                  // setCurrAssessmentSession((prev) => ({
                  //   ...prev,
                  //   mcq: {
                  //     questions: _mcqResponse.assessmentquestions || [],
                  //   },
                  // }));
                  assessment_session_data =  {
                    ...assessment_session_data,
                   mcq : {...assessment_session_data.mcq, questions : _mcqResponse.assessmentquestions}
                  }
    
                  // api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + codingAssessment.guid, (_coding_err, _codingResponse) => {
                  //   // setCurrAssessmentSession((prev) => ({
                  //   //   ...prev,
                  //   //   coding: {
                  //   //     questions: _codingResponse.assessmentquestions || [],
                  //   //   },
                  //   // }));
                  //   assessment_session_data =  {
                  //     ...assessment_session_data,
                  //     coding : {questions : _codingResponse.assessmentquestions}
                  //    }
                  // })
    
                  // api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + videoAssessment.guid, (_video_err, _videoResponse) => {
                  //   if (_video_err) {
                  //     console.error("API request error:", _video_err);
                  //   } else {
                  //     // setCurrAssessmentSession((prev) => ({
                  //     //   ...prev,
                  //     //   video: {
                  //     //     questions: _videoResponse.assessmentquestions || [],
                  //     //   },
                  //     // }));
                  //     assessment_session_data =  {
                  //       ...assessment_session_data,
                  //       video : {questions : _videoResponse.assessmentquestions}
                  //      }
                  //     setAssessmentSessionData(assessment_session_data);
                  //     dispatch(setAssessmentData(assessment_session_data));
                  //   }
                  // })
                }
              });
              api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + codingAssessment.guid, (_coding_err, _codingResponse) => {
                // setCurrAssessmentSession((prev) => ({
                //   ...prev,
                //   coding: {
                //     questions: _codingResponse.assessmentquestions || [],
                //   },
                // }));
                assessment_session_data =  {
                  ...assessment_session_data,
                  coding : {...assessment_session_data.coding,questions : _codingResponse.assessmentquestions}
                 }
              })

              api_get(null, ep.assessment + "/v1/assessmentquestion/assessment/" + videoAssessment.guid, (_video_err, _videoResponse) => {
                if (_video_err) {
                  console.error("API request error:", _video_err);
                } else {
                  // setCurrAssessmentSession((prev) => ({
                  //   ...prev,
                  //   video: {
                  //     questions: _videoResponse.assessmentquestions || [],
                  //   },
                  // }));
                  assessment_session_data =  {
                    ...assessment_session_data,
                    video : {...assessment_session_data.video, questions : _videoResponse.assessmentquestions}
                   }
                   setTimeout(() => {
                     setAssessmentSessionData(assessment_session_data);
                     dispatch(setAssessmentData(assessment_session_data));
                   }, 1000);
                }
              })
            }
          });
        }
      });
    }
    return () => {
      // renderTabContent();
    };
  }, []);

  const handleTabClick = (tab,assessmentMapData) => {
    if(tab==='stepskilltest'){
      api_post(null, ep.assessment + "/v2/assessmentsession", assessmentMapData, (error, responseData) => {
        if (error) {
         console.error("API request error:", error);
        } else {
          log_debug("AssessmentStepsNavigation", "assessmentsession", "responseData : ", responseData);
          const assessment_sess_data = {
            guid: responseData.assessmentsession.guid,
            candidate_guid: responseData.assessmentsession.candidate_guid,
            primary_skills: responseData.assessmentsession.primary_skills,
            secondary_skills: responseData.assessmentsession.secondary_skills,
            role: responseData.assessmentsession.role,
            id: responseData.assessmentsession._id,
            mcq:responseData.mcq,
            coding:responseData.coding,
            video:responseData.behavioural,
            mcq_assessment_guid : responseData.mcq.assessment.guid,
            coding_assessment_guid : responseData.coding.assessment.guid,
            video_assessment_guid : responseData.behavioural.assessment.guid
          }
          setCurrAssessmentSession(assessment_sess_data);
          setAssessmentSessionData(assessment_sess_data);
          dispatch(setAssessmentData(assessment_sess_data));
          navigate(`/assessmentsession-${tab}?candidate_guid=${data.candidate_guid}&guid=${responseData.assessmentsession.guid}`);
          setSelectedTab(tab);
        } 
       });

    }else if(tab==='stepbehavioural'){
      navigate(`/assessmentsession-${tab}?candidate_guid=${data.candidate_guid}&guid=${data.guid}`);
      setSelectedTab(tab);
    } else if (tab === "steptalentiqscore") {
      navigate(
        `/assessmentsession-${tab}?candidate_guid=${data.candidate_guid}&guid=${data.guid}`
      );
      setSelectedTab(tab);
    } else {
      navigate(`/assessmentsession-${tab}?candidate_guid=${data.candidate_guid}`+(roleFromUrl.length > 0 ? `&role=${roleFromUrl}` : ''));
      setSelectedTab(tab);
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case `${assessmentsession_steps.stepuserprofile.code}`:
        return (
        <StepUserProfile data={data} toggleCurrentStep={(tab) => handleTabClick(tab)}/>
        );
      // return <StepUserProfile toggleCurrentStep={handleTabClick}/>

      case `${assessmentsession_steps.stepskillmapping.code}`:
        return (
          <StepSkillMapping data={data} toggleCurrentStep={(tab, assessmentMapData) => handleTabClick(tab, assessmentMapData)}/>
        );

      case `${assessmentsession_steps.stepskilltest.code}`:
        return (
          <StepSkillTest
            data={{
              ...data,
              ...{ assessmentSessionData: assessmentSessionData },
            }}
            toggleCurrentStep={(tab) => handleTabClick(tab)}
          />
        );

      case `${assessmentsession_steps.stepbehavioural.code}`:
        return (
          <StepBehaviouralTest
            data={{
              ...data,
              ...{ assessmentSessionData: assessmentSessionData },
            }}
            toggleCurrentStep={(tab) => handleTabClick(tab)}
          />
        );

      case `${assessmentsession_steps.steptalentiqscore.code}`:
        return <StepTalentIQScore data={{...data,...{assessmentSessionData : guid}}} toggleCurrentStep={(tab)=>handleTabClick(tab)}/>

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <nav
        className="topnav navbar navbar-light navbar-expand-lg topnav-menu bg-white border border-2"
        id="navigation"
      >
        <ul className="navbar-nav flex-wrap gap-2 align-items-start">
          <li className="nav-item dropdown">
            <button
              className={`nav-link dropdown-toggle arrow-none pe-0 ${
                selectedTab === assessmentsession_steps.stepuserprofile.code
                  ? "active"
                  : "disabled"
              }`}
              onClick={() =>
                handleTabClick(assessmentsession_steps.stepuserprofile.code)
              }
            >
              {/* <FeatherIcon icon="home" className="icon-lg" /> */}
              {/* <span className="fs-6">Complete Your Profile</span> */}
              <div>
              <h5 className="font-size-14 mb-1"><FeatherIcon icon="user" className="icon-sm" /> Profile</h5>
              <p className="text-muted mb-0 font-size-10">Share your skills and experience</p>
              </div>
            </button>
          </li>
          <li className="nav-item dropdown">
            <button
              className={`nav-link dropdown-toggle arrow-none pe-0 ${
                selectedTab === assessmentsession_steps.stepskillmapping.code
                  ? "active"
                  : "disabled"
              }`}
              onClick={() =>
                handleTabClick(assessmentsession_steps.stepskillmapping.code)
              }
            >
              {/* <FeatherIcon icon="briefcase" className="icon-lg" />
              <span className="fs-6">Select Your Skills</span> */}
              <div>
              <h5 className="font-size-14 mb-1"><FeatherIcon icon="check-circle" className="icon-sm" /> Skills</h5>
              <p className="text-muted mb-0 font-size-10">Complete your Tech Stack</p>
              </div>
            </button>
          </li>

          <li className="nav-item dropdown">
            <button
              className={`nav-link dropdown-toggle arrow-none pe-0 ${
                selectedTab === assessmentsession_steps.stepskilltest.code
                  ? "active"
                  : "disabled"
              }`}
              onClick={() =>
                handleTabClick(assessmentsession_steps.stepskilltest.code)
              }
            >
              {/* <FeatherIcon icon="grid" className="icon-lg" /> */}
              {/* <span className="fs-6">Skill Test</span> */}
              <div>
              <h5 className="font-size-14 mb-1"><FeatherIcon icon="help-circle" className="icon-sm" /> Assessment</h5>
              <p className="text-muted mb-0 font-size-10">Pass Your Quiz</p>
              </div>
            </button>
          </li>

          <li className="nav-item dropdown">
            <button
              className={`nav-link dropdown-toggle arrow-none pe-0 ${
                selectedTab === assessmentsession_steps.stepbehavioural.code
                  ? "active"
                  : "disabled"
              }`}
              onClick={() =>
                handleTabClick(assessmentsession_steps.stepbehavioural.code)
              }
            >
              {/* <FeatherIcon icon="box" />
              <span className="fs-6">Video Interview</span> */}
              <div>
              <h5 className="font-size-14 mb-1"><FeatherIcon icon="camera" className="icon-sm" /> Video Interview</h5>
              <p className="text-muted mb-0 font-size-10">Showcase Behavioural Skills</p>
              </div>
            </button>
          </li>

          <li className="nav-item dropdown">
            <button
              className={`nav-link dropdown-toggle arrow-none pe-0 ps-3 ${
                selectedTab === assessmentsession_steps.steptalentiqscore.code
                  ? "active"
                  : "disabled"
              }`}
              onClick={() =>
                handleTabClick(assessmentsession_steps.steptalentiqscore.code)
              }
            >
              {/* <FeatherIcon icon="home" className="icon-lg" />
              <span className="fs-6">TalentIQ Score</span> */}
              <div>
              <h5 className="font-size-14 mb-1"><FeatherIcon icon="award" className="icon-sm" /> TalentIQ Score</h5>
              <p className="text-muted mb-0 font-size-10">Your Success Unlocked</p>
              </div>
            </button>
          </li>
        </ul>
      </nav>

      {/* Render the content of the selected tab */}
      {renderTabContent()}
    </React.Fragment>
  );
};

export default AssessmentSteps;
