import React,{useState,useEffect} from 'react'
import { Row,Col,Card,CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import VideoTestModal from '../modal/VideoTestModal'
import { setQuestionIndex } from '../../store/timerSlice';

const VideoTest = ({data,videoQuestionTransition}) => {

    const dispatch = useDispatch();
    const [isVideoModalActive, setIsVideoModalActive] = useState(false);

    useEffect(() => {
        const currentQuestionDetails={
            questionIndex:'',
            questionStatus:'',
            questionType:'video'
       
        }
        dispatch(setQuestionIndex(currentQuestionDetails));
       
    }, [])
   

    const handleVideoTestModal = (e)=>{
        if(!isVideoModalActive){
            e.preventDefault();
        }
        setIsVideoModalActive(!isVideoModalActive);
    }

    const handleVideoTransition = (questionIndex) =>{
        videoQuestionTransition(questionIndex);
      }

  return (
    <>
      {/* <Card>
                <CardBody className="overflow-hidden position-relative"> */}
                    <Row>
                        <Col xl={1}>
                            <i className='fas fa-video text-primary fs-1 p-0 mt-1'></i>
                        </Col>
                        <Col xl={11}>
                             <div className="faq-count">
                                <h5 className="text-primary">Video Interview Guidelines</h5>
                            </div>
                            {/* <p className="mt-2 mb-0">Only <span className="text-black">18%</span> of candidates successfully pass this round, emphasizing its significance in candidate selection.</p> */}
                            <p className="text-muted font-size-12 mt-1 mb-0">Remember, you will be judged on <b>Clarity</b> and <b>Pronunciation</b>, <b>Fluency</b>, <b>Tone</b> and <b>Intonation</b>, as well as <b>Confidence</b> and <b>Delivery</b> during the interview.</p>
                            <div>
                            <h5 className='mt-3 font-size-14'>Hints & Recommendations </h5>
                            <ul className="list-unstyled mb-3 font-size-13">
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Please ensure you are in a well-lit and quiet environment to minimize distractions.</li>
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Sit upright and maintain good posture throughout the interview.</li>
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Speak clearly and pronounce words accurately to demonstrate effective communication.</li>
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Focus on fluency while expressing your thoughts, avoiding long pauses or hesitations.</li>
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Pay attention to your tone and intonation, as it can convey enthusiasm and confidence.</li>
                            <li className=""><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Show confidence in your responses and maintain a positive and professional delivery.</li>
                            </ul>
                            <Link type='button' to="#" className="mt-5 btn btn-primary btn-sm waves-effect waves-light float-end p-2" onClick={(e) => handleVideoTestModal(e)}> Start Video Interview <i className="fas fa-arrow-right align-middle ms-1"></i></Link>
                        </div>
                        </Col>
                    </Row>
                {/* </CardBody>
            // </Card> */}
    { isVideoModalActive && (
        <VideoTestModal isModalActive={isVideoModalActive} data={data} videoQuestionTransition={handleVideoTransition} onToggleModal={handleVideoTestModal}></VideoTestModal>
    )}
    </>
  )
}

export default VideoTest